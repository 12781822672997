@import 'variables';



@mixin font-size($size-name) {
  $output: $size-name;
  @if map-has-key($font-sizes, $size-name) {
    $output: map-get($font-sizes, $size-name);
  }

  font-size: $output;
}

@mixin font-weight($weight) {
  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }

  font-weight: $output;
}

@mixin generate-text-sizes($sizes-map) {
  @each $size-name, $size-value in $sizes-map {
    .text-#{$size-name} {
      font-size: $size-value;
    }
  }
}

@mixin generate-font-weights($weights-map) {
  @each $weight-name, $weight-value in $weights-map {
    .font-#{$weight-name} {
      font-weight: $weight-value;
    }
  }
}


@mixin ts-header-larger {
  @include font-size('4xl');
  @include font-weight(bold);
  line-height: 2.5rem;
}

@mixin ts-header {
  @include font-size('2xl');
  @include font-weight(bold);
  line-height: 2rem;
}

@mixin ts-header-small {
  @include font-size('xl');
  @include font-weight(bold);
  line-height: 1.5rem;
}

@mixin ts-subtitle-strong {
  @include font-size('lg');
  @include font-weight(semi-bold);
  line-height: 20px;
}

@mixin ts-subtitle {
  @include font-size(normal);
  @include font-weight(semi-bold);
  line-height: 18px;
}

@mixin ts-subtitle-small {
  @include font-size('sm');
  @include font-weight(semi-bold);
  line-height: 1rem;
}

@mixin ts-caption-strong-bold {
  font-size: 13px;
  @include font-weight(medium);
  line-height: 0.875rem;
}

@mixin ts-caption-strong {
  font-size: 13px;
  @include font-weight(normal);
  line-height: 0.875rem;
}

@mixin ts-caption {
  @include font-size('xs');
  @include font-weight(normal);
  line-height: 0.75rem;
}

@mixin ts-caption-small {
  @include font-size('2xs');
  @include font-weight(normal);
  line-height: 0.75rem;
}

@mixin ts-body-strong {
  @include font-size('lg');
  @include font-weight(normal);
  line-height: 1.375rem;
}

@mixin ts-body {
  @include font-size(normal);
  @include font-weight(normal);
  line-height: 1.25rem;
}

@mixin ts-body-small {
  @include font-size('sm');
  @include font-weight(normal);
  line-height: 1.125rem;
}

@mixin ts-label-large {
  @include font-size('sm');
  @include font-weight(normal);
  line-height: 2rem;
}

@mixin ts-label-medium {
  @include font-size('sm');
  @include font-weight(normal);
  line-height: 1rem;
}

@mixin ts-label-small {
  font-size: 13px;
  @include font-weight(normal);
  line-height: 1.25rem;
}

@mixin ts-control-large {
  @include font-size(normal);
  @include font-weight(semi-bold);
  line-height: 1.25rem;
}

@mixin ts-control-medium {
  font-size: 13px;
  @include font-weight(medium);
  line-height: 1.25rem;
}

@mixin ts-control-small {
  @include font-size('xs');
  @include font-weight(normal);
  line-height: 1rem;
}
