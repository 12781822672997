.hint {
  padding: 10px 15px;
  border: 0.5px solid #e3ebf6;
  box-shadow: 0px 15px 15px -15px rgba(30, 30, 30, 0.15);
  border-radius: 20px;
  background-color: #fff;

  display: flex;
  flex-direction: column;

  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 100;

  &-cross {
    position: absolute;
    top: 10px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    width: 85%;
  }

  span {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #45b193;
  }
}

.hint-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  z-index: 10;
}
