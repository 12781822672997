@import '../core/colors/styles';
@import '../core/typography/styles';
@import "mixins";

* {
  --primary-btn-color: $primary2;
}

$primary-btn-color: #48b798; //#08956e;
$secondary-btn-color: #dce1ec;
$btn-secondary-background: #f2f2f2;
$btn-secondary-color: #4f524f;


.btn {
  --bs-btn-border-radius: 6px;
  --bs-btn-padding-x: 13px;
  --bs-btn-padding-y: 10px;
  --bs-btn-font-size: 13px;
  --bs-btn-font-weight: 500;
  height: 36px;

  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  gap: 4px;
  transition: 0.3s ease;

  svg {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:focus-visible {
    box-shadow: none;
  }

  &.btn-icon {
    --bs-btn-padding-x: 0;
    width: 36px;
  }
}

.btn-lg {
  --bs-btn-border-radius: 8px;
  --bs-btn-padding-x: 19px;
  --bs-btn-padding-y: 12px;
  --bs-btn-font-size: 16px;
  --bs-btn-font-weight: 600;
  height: 44px;

  &.btn-icon {
    --bs-btn-padding-x: 0;
    width: 44px;
  }
}

.btn-sm {
  --bs-btn-border-radius: 4px;
  --bs-btn-padding-x: 11px;
  --bs-btn-padding-y: 5px;
  --bs-btn-font-size: 12px;
  --bs-btn-font-weight: 400;
  height: 28px;
  gap: 2px;

  svg {
    height: 16px;
    width: 16px;
  }

  &.btn-icon {
    --bs-btn-padding-x: 0px;
    width: 28px;
  }
}

// FILLED BUTTONS: START
.btn-primary {
  --bs-btn-bg: var(--control-colors-primary-primary, #009F7A);
  --bs-btn-hover-bg: var(--control-colors-primary-primary-hovered, #00795D);
  --bs-btn-active-bg: var(--control-colors-primary-primary-pressed, #00664E);
  --bs-btn-border-color: var(--control-colors-primary-primary, #009F7A);
  --bs-btn-hover-border-color: var(--control-colors-primary-primary-hovered, #00795D);
  --bs-btn-active-border-color: var(--control-colors-primary-primary-pressed, #00664E);
}

.btn-secondary {
  --bs-btn-bg: var(--control-colors-secondary-secondary, #2AAB9C);
  --bs-btn-hover-bg: var(--control-colors-secondary-secondary-hovered, #208377);
  --bs-btn-active-bg: var(--control-colors-secondary-secondary-pressed, #186259);
  --bs-btn-border-color: var(--control-colors-secondary-secondary, #2AAB9C);
  --bs-btn-hover-border-color: var(--control-colors-secondary-secondary-hovered, #208377);
  --bs-btn-active-border-color: var(--control-colors-secondary-secondary-pressed, #186259);
}

.btn-danger {
  --bs-btn-bg: var(--control-colors-error-error, #DE4646);
  --bs-btn-hover-bg: var(--control-colors-error-error-hovered, #A93535);
  --bs-btn-active-bg: var(--control-colors-error-error-pressed, #8E2D2D);
  --bs-btn-border-color: var(--control-colors-error-error, #DE4646);
  --bs-btn-hover-border-color: var(--control-colors-error-error-hovered, #A93535);
  --bs-btn-active-border-color: var(--control-colors-error-error-pressed, #8E2D2D);
}

.btn-warning {
  --bs-btn-bg: var(--control-colors-warning-warning, #ED751E);
  --bs-btn-hover-bg: var(--control-colors-warning-warning-hovered, #C76219);
  --bs-btn-active-bg: var(--control-colors-warning-warning-pressed, #B45917);
  --bs-btn-border-color: var(--control-colors-warning-warning, #ED751E);
  --bs-btn-hover-border-color: var(--control-colors-warning-warning-hovered, #C76219);
  --bs-btn-active-border-color: var(--control-colors-warning-warning-pressed, #B45917);
}

.btn-success {
  --bs-btn-bg: var(--control-colors-success-success, #1CB554);
  --bs-btn-hover-bg: var(--control-colors-success-success-hovered, #189847);
  --bs-btn-active-bg: var(--control-colors-success-success-pressed, #158A40);
  --bs-btn-border-color: var(--control-colors-success-success, #1CB554);
  --bs-btn-hover-border-color: var(--control-colors-success-success-hovered, #189847);
  --bs-btn-active-border-color: var(--control-colors-success-success-pressed, #158A40);
}

.btn-info {
  --bs-btn-bg: var(--control-colors-info-info, #287EFF);
  --bs-btn-hover-bg: var(--control-colors-info-info-hovered, #1E60C2);
  --bs-btn-active-bg: var(--control-colors-info-info-pressed, #1A51A3);
  --bs-btn-border-color: var(--control-colors-info-info, #287EFF);
  --bs-btn-hover-border-color: var(--control-colors-info-info-hovered, #1E60C2);
  --bs-btn-active-border-color: var(--control-colors-info-info-pressed, #1A51A3);
}

.btn-primary,
.btn-secondary,
.btn-danger,
.btn-warning,
.btn-success,
.btn-info {
  --bs-btn-color: var(--control-colors-text-inverse, #FFF);
  --bs-btn-hover-color: var(--control-colors-text-inverse, #FFF);
  --bs-btn-active-color: var(--control-colors-text-inverse, #FFF);
  --bs-btn-disabled-bg: var(--control-colors-other-control-disable, #D0D0D0);
  --bs-btn-disabled-color: var(--control-colors-text-disable, #A3A3A3);
  --bs-btn-disabled-border-color: var(--control-colors-other-control-disable, #D0D0D0);

}

// FILLED BUTTONS: END
// HALF-FILLED BUTTONS: START

.btn-outline-light {
  --bs-btn-border-color: var(--control-colors-surface-secondary, #F5F5F5);
  --bs-btn-hover-border-color: var(--control-colors-surface-secondary, #F5F5F5);
  --bs-btn-active-border-color: var(--control-colors-surface-secondary, #F5F5F5);
  --bs-btn-bg: var(--control-colors-surface-secondary, #F5F5F5);
  --bs-btn-hover-bg: var(--control-colors-surface-secondary, #F5F5F5);
  --bs-btn-active-bg: var(--control-colors-surface-secondary, #F5F5F5);
  --bs-btn-color: var(--control-colors-text-secondary, #4D4D4D);
  --bs-btn-hover-color: var(--control-colors-primary-primary-hovered, #00795D);
  --bs-btn-active-color: var(--control-colors-primary-primary-pressed, #00664E);
}

.btn-outline-primary {
  --bs-btn-border-color: var(--control-colors-surface-accent-primary, #D6F0EA);
  --bs-btn-hover-border-color: var(--control-colors-surface-accent-primary, #D6F0EA);
  --bs-btn-active-border-color: var(--control-colors-surface-accent-primary, #D6F0EA);
  --bs-btn-bg: var(--control-colors-surface-accent-primary, #D6F0EA);
  --bs-btn-hover-bg: var(--control-colors-surface-accent-primary, #D6F0EA);
  --bs-btn-active-bg: var(--control-colors-surface-accent-primary, #D6F0EA);
  --bs-btn-color: var(--control-colors-primary-primary, #009F7A);
  --bs-btn-hover-color: var(--control-colors-primary-primary-hovered, #00795D);
  --bs-btn-active-color: var(--control-colors-primary-primary-pressed, #00664E);
}

.btn-outline-secondary {
  --bs-btn-border-color: var(--control-colors-surface-accent-secondary, #C6F1EC);
  --bs-btn-hover-border-color: var(--control-colors-surface-accent-secondary, #C6F1EC);
  --bs-btn-active-border-color: var(--control-colors-surface-accent-secondary, #C6F1EC);
  --bs-btn-bg: var(--control-colors-surface-accent-secondary, #C6F1EC);
  --bs-btn-hover-bg: var(--control-colors-surface-accent-secondary, #C6F1EC);
  --bs-btn-active-bg: var(--control-colors-surface-accent-secondary, #C6F1EC);
  --bs-btn-color: var(--control-colors-secondary-secondary, #2AAB9C);
  --bs-btn-hover-color: var(--control-colors-secondary-secondary-hovered, #208377);
  --bs-btn-active-color: var(--control-colors-secondary-secondary-pressed, #186259);
}

.btn-outline-danger {
  --bs-btn-border-color: var(--control-colors-surface-error, #FCF0F0);
  --bs-btn-hover-border-color: var(--control-colors-surface-error, #FCF0F0);
  --bs-btn-active-border-color: var(--control-colors-surface-error, #FCF0F0);
  --bs-btn-bg: var(--control-colors-surface-error, #FCF0F0);
  --bs-btn-hover-bg: var(--control-colors-surface-error, #FCF0F0);
  --bs-btn-active-bg: var(--control-colors-surface-error, #FCF0F0);
  --bs-btn-color: var(--control-colors-error-error, #DE4646);
  --bs-btn-hover-color: var(--control-colors-error-error-hovered, #A93535);
  --bs-btn-active-color: var(--control-colors-error-error-pressed, #8E2D2D);
}

.btn-outline-success {
  --bs-btn-border-color: var(--control-colors-surface-success, #EBF7F4);
  --bs-btn-hover-border-color: var(--control-colors-surface-success, #EBF7F4);
  --bs-btn-active-border-color: var(--control-colors-surface-success, #EBF7F4);
  --bs-btn-bg: var(--control-colors-surface-success, #EBF7F4);
  --bs-btn-hover-bg: var(--control-colors-surface-success, #EBF7F4);
  --bs-btn-active-bg: var(--control-colors-surface-success, #EBF7F4);
  --bs-btn-color: var(--control-colors-success-success, #1CB554);
  --bs-btn-hover-color: var(--control-colors-success-success-hovered, #189847);
  --bs-btn-active-color: var(--control-colors-success-success-pressed, #158A40);
}

.btn-outline-warning {
  --bs-btn-border-color: var(--control-colors-surface-warning, #FEF4ED);
  --bs-btn-hover-border-color: var(--control-colors-surface-warning, #FEF4ED);
  --bs-btn-active-border-color: var(--control-colors-surface-warning, #FEF4ED);
  --bs-btn-bg: var(--control-colors-surface-warning, #FEF4ED);
  --bs-btn-hover-bg: var(--control-colors-surface-warning, #FEF4ED);
  --bs-btn-active-bg: var(--control-colors-surface-warning, #FEF4ED);
  --bs-btn-color: var(--control-colors-warning-warning, #ED751E);
  --bs-btn-hover-color: var(--control-colors-warning-warning-hovered, #C76219);
  --bs-btn-active-color: var(--control-colors-warning-warning-pressed, #B45917);
}

.btn-outline-info {
  --bs-btn-border-color: var(--control-colors-surface-info, #EEF5FF);
  --bs-btn-hover-border-color: var(--control-colors-surface-info, #EEF5FF);
  --bs-btn-active-border-color: var(--control-colors-surface-info, #EEF5FF);
  --bs-btn-bg: var(--control-colors-surface-info, #EEF5FF);
  --bs-btn-hover-bg: var(--control-colors-surface-info, #EEF5FF);
  --bs-btn-active-bg: var(--control-colors-surface-info, #EEF5FF);
  --bs-btn-color: var(--control-colors-info-info, #287EFF);
  --bs-btn-hover-color: var(--control-colors-info-info-hovered, #1E60C2);
  --bs-btn-active-color: var(--control-colors-info-info-pressed, #1A51A3);
}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-danger,
.btn-outline-warning,
.btn-outline-success,
.btn-outline-info,
.btn-outline-light {
  --bs-btn-disabled-bg: none;
  --bs-btn-disabled-color: var(--control-colors-text-disable, #A3A3A3);
  --bs-btn-disabled-border-color: var(--control-colors-text-disable, #A3A3A3);

}

// HALF-FILLED BUTTONS: END

.btn-link {
  --bs-link-color: var(--control-colors-primary-primary, #009F7A);
  --bs-btn-hover-color: var(--control-colors-primary-primary-hovered, #00795D);
  --bs-btn-active-color: var(--control-colors-primary-primary-pressed, #00664E);
  --bs-btn-disabled-color: var(--light-text-icons-quartenly, #A3A3A3);
  --bs-btn-padding-x: 0px;
  text-decoration: none;
}


.ts-btn, a.ts-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  justify-content: center;
  @each $type in primary, secondary, error, warning, success, info, btn-grey, btn-white {
    @include button-type($type);
  }

  & span{
    white-space: nowrap;
  }

  &-sm {
    & .ts-icon {
      font-size: 1rem;
    }

    @include ts-control-small;
    padding: 0.5rem 0.875rem;

    &.ts-btn-icon {
      padding: 0.5rem;
    }
  }

  &-md {
    & .ts-icon {
      font-size: 20px;
    }

    @include ts-control-medium;
    padding: 0.5rem 0.875rem;

    &.ts-btn-icon {
      padding: 0.5rem;
    }
  }

  &-lg {
    & .ts-icon {
      font-size: 20px;
    }

    @include ts-control-large;
    padding: 0.75rem 1.25rem;

    &.ts-btn-icon {
      padding: 0.75rem;
    }
  }

  &.disabled, &[disabled]{
    color: $text-disabled-color;
    background-color: $controls-disabled-color;
    pointer-events: none;
  }

  &.disabled, &[disabled]{
    color: $text-disabled-color;
    background-color: $controls-disabled-color;
    pointer-events: none;
  }
}
