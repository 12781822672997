.row {
  --bs-gutter-x: 1.5rem;
}

html {
  font-size: $font-size-root;
}

body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  --bs-body-font-family: 'Manrope', sans-serif;
  --bs-body-font-size: 0.875rem;
}

.wrapper {
  &.wrapper-full-page {
    height: auto;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.content-wrapper {
  padding: 0 30px;
}

.serif-font {
  font-family: $font-family-sans-serif;
}

a {
  text-decoration: none;
  background-color: transparent;

  &:focus {
    color: darken($primary, 5%);
    text-decoration: none;
  }

  &.text-info {
    &:hover,
    &:focus {
      color: darken($info, 5%);
    }
  }
}

p {
  letter-spacing: 0.3px;
}

@media (max-width: 576px) {
  .main-panel {
    .main-content {
      .content-wrapper {
        padding: 0 15px;
      }
    }
  }
}

.tooltip {
  opacity: 1;
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 300px;
  --bs-tooltip-padding-x: 0.4rem;
  --bs-tooltip-padding-y: 0.4rem;
  --bs-tooltip-margin: 0.5rem;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #48b798;

  &.wide-tooltip {
    opacity: 1;
    min-width: 300px;
  }
}
.flex-1{
  flex: 1;
}
