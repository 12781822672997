@import "../core/mediaQueries/styles";
@import "../core/colors/styles";

:root {
  --page-table-top-offset: 220px; //расстояние от верха страницы до начала таблицы.
}

.wrapper {
  top: 10px;
  padding-top: 0;
  margin: 0;
}

.card {
  border: 1px solid #e3ebf6;
  border-radius: 30px;

  &-title {
    font-size: 16px;
    font-weight: 600;
  }
}

.content-wrapper {
  padding: 0 0;
}

@media screen and (min-width: 1920px) {
  .wrapper {
    max-width: 1920px;
    margin: 0 auto;
  }
}

.row > * {
  padding-left: 8px;
  padding-right: 8px;
}

.row > *:not(:first-child) {
  padding-left: 0;
}

.ts-card {
  border-radius: 0.5rem;
  background-color: $surface-primary-color;
  border: 1px solid $separator-primary-color;
}

//специфичный радиус применяемый только на элементах управления в полосках
$list-control-border-radius: 6px;
.ts-controls-bar-item {
  border-radius: $list-control-border-radius !important;
}

.ts-list-page-controls {
  background-color: $surface-primary-color;
  padding: 0.5rem;
}

.page-table-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - var(--page-table-top-offset, 220px) - var(--page-table-bottom-offset, 0px));

  .page-table {
    overflow: auto;
  }
}

@include breakpoint-md {
  .page-table-container {
    --page-table-top-offset: 175px;
  }
}

@include breakpoint-lg {
  .page-table-container {
    --page-table-top-offset: 134px;
  }
}

.edit-page-controls {
  width: 100%;

  background-color: $surface-primary-color;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  @include breakpoint-sm {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }

  & .buttons-group {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    &__right {

      @include border-style('top');
      @include breakpoint-sm {
        justify-self: end;
        border: none;
      }

    }
  }
}

/*
* грид с 6,4,3,2,1 колонками
*/
.ts-general-controls-grid{
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @include breakpoint-sm{
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint-md{
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint-lg{
    grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoint-lg{
    grid-template-columns: repeat(6, 1fr);
  }
}

.item-edit-header-container {
  background-color: $surface-primary-color;
  padding: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  @include border-style('bottom', $separator-secondary-color);
}

.page-table-container.page-table-with-sub-menu {
  --page-table-top-offset: 257px;
}
@include breakpoint-md {
  .page-table-container.page-table-with-sub-menu {
    --page-table-top-offset: 212px;
  }
}

@include breakpoint-lg {
  .page-table-container.page-table-with-sub-menu {
    --page-table-top-offset: 171px;
  }
}

.page-table-container.page-table-with-tabs {
  --page-table-top-offset: 310px;
}
@include breakpoint-md {
  .page-table-container.page-table-with-tabs {
    --page-table-top-offset: 265px;
  }
}

@include breakpoint-lg {
  .page-table-container.page-table-with-tabs {
    --page-table-top-offset: 206px;
  }
}

.rounded-lg{
  border-radius: 0.5rem;
}

.min-w-0{
  min-width: 0;
}
