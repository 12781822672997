@import '/src/assets/sass/core/colors/_palette-variables.scss';

.hub {
  .bordered-light {
    border: 1px solid $border-grey;
    background-color: #fff;
    color: $grey-dark-l;
    height: 100%;
    border-radius: 25px;
  }

  tr {
    display: flex;
    position: relative;

    &.head {
      background-color: $grey;
    }

    th,
    td {
      width: 100%;
      font-size: 13px;
      font-weight: 400;
      height: 34px;
      display: flex;
      align-items: center;
      padding: 6px 0px;

      .icons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
        padding-right: 18px;

        svg {
          cursor: pointer;
          transition: 0.15s;

          use {
            fill: $grey-dark;
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    &:not(th) {
      cursor: pointer;
    }
  }

  .employee-list,
  .source-list,
  .counterparties-list,
  .items-list,
  .department-list,
  .storage-list,
  .item-list {
    display: flex;
    width: 100%;
    height: calc(100vh - 150px);
    padding: 0 10px;

    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      gap: 20px;
    }

    &__labels {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 0 20px;
    }

    &__table {
      width: 100%;
      height: 100%;
      min-height: 0;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      overflow-x: auto;
      position: relative;

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        border: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $border-grey;
        border-radius: 99px;
      }

      &::-webkit-scrollbar-track {
        background-color: $grey;
        border-radius: 99px;
      }

      .columns {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;

        tr {
          &:nth-child(odd) {
            td {
              background-color: $grey;
            }
          }
        }
      }
    }

    .search-container {
      width: 100%;
    }

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 20px;
      padding-bottom: 0;

      > .settings {
        height: 40px;
        min-width: 40px;
        border-radius: 999px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          transform: scale(0.9);
          transition: 0.15s all;

          use {
            stroke: $grey-dark;
          }
        }

        &:hover {
          svg {
            transform: scale(1);
          }
        }
      }
    }
  }

  .empty-art {
    position: sticky;
    top: 35px;
    left: 0px;
    width: 100%;
    height: calc(100% - 35px);
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &__info {
      position: absolute;
      top: 0px;
      left: 10%;
    }

    > img:first-child {
      transform: scale(0.9);
    }
  }
}
