/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.yandex-auth {
  .yaPreloadingSuggestBlock {
    opacity: 0.4;
    background-color: transparent;
  }
}
