//input file styles

.input-file {
  position: relative;
  display: inline-flex;
  height: 40px;

  &-text {
    padding: 0 10px;
    line-height: 40px;
    text-align: left;
    display: block;
    float: left;
    box-sizing: border-box;
    width: 100%;
    border-radius: 30px 0px 0 30px;
    background-color: $grey;
    color: $grey-dark;
    padding-left: 20px;
    border: $grey-light 1px solid;
  }
  &-btn {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 200px;
    padding: 0 20px 0 20px;

    cursor: pointer;
    outline: none;
    border-radius: 0 30px 30px 0;
    color: $green;
    line-height: 22px;
    box-sizing: border-box;
    margin: 0;
    transition: background-color 0.2s;
    background-color: $grey;
    border: $grey-light 1px solid;
    border-left: none;
    white-space: nowrap;
  }
  & input[type='file'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }

  &:hover .input-file-btn {
    background-color: $grey-light;
  }
}

//html example
//add with to parent label el
// <label class="input-file">
//     <span class="input-file-text" type="text">{{ filename || placeholder }}</span>
//     <input type="file" name="file" id="file" />
//     <label class="input-file-btn" for="file">Выберите файл</label>
// </label>
.input-cell{
  --input-height-flat: 46px;
  --input-border: none;
  --input-border-radius: 0;
}
