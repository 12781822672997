// Modals
@import "../core/colors/styles";
@import "../core/mediaQueries/styles";
// modal content
.modal-content {
  overflow: hidden;
  // modal header
  .modal-header {
    border-radius: 0;

    &[class*='bg-'] > * {
      color: $white;
    }
  }

  // modal footer
  .modal-footer {
    .btn {
      margin: 0;

      & + .btn {
        margin-left: 8px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .modal-open .modal {
    padding: 0 0.5rem !important;
  }
}

.ts-modal-backdrop {
  background-color: $background-overlay-color;
  opacity: 0.3 !important;
}


.ts-modal-panel {
  --mdc-dialog-subhead-color: $text-primary-color;
  --mdc-dialog-subhead-font: 'Manrope', sans-serif;
  --mdc-dialog-supporting-text-font: 'Manrope', sans-serif;


  height: 100dvh;
  width: 100vw;


  & .mdc-dialog .mdc-dialog__content {
    padding: 0.5rem !important;
    @include breakpoint-md {
      padding: 1rem !important;
    }
  }

  & .mdc-dialog__container {
    margin: auto;
  }

  .mdc-dialog__surface {
    background-color: $background-modal-color !important;
    color: $text-primary-color !important;
    border-radius: 0 !important;
    @include breakpoint-md {
      border-radius: 0.5rem !important;
    }
  }

  & .mat-mdc-dialog-container .mdc-dialog__title {
    color: $text-primary-color;
  }

  .mdc-dialog__title::before {
    height: 0.5rem;
    display: none;
  }

  .mdc-dialog__title {
    padding: 0.5rem 1rem;
    margin: 0;
    @include border-style('bottom', $separator-secondary-color);
  }


  @include breakpoint-md {
    height: fit-content;
    min-height: 100px;
    //max-height: 80dvh;
    padding: 1rem;
  }

  &-lg {
    & .mdc-dialog__container {
      max-width: 800px !important;
    }
  }

  &-full {
    & .mdc-dialog__container {
      max-width: 800px !important;

      @include breakpoint-md {
        max-width: 98vw !important;
      }
    }
  }
}

.ts-modal-panel-full-height {
  height: 100dvh;
}

.ts-modal-layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  & .mat-mdc-dialog-content {
    max-height: 100%;

    @include breakpoint-md {
      max-height: 80dvh;
    }
  }

  .mdc-dialog__actions {
    padding: 1rem !important;
  }

  & .mat-mdc-dialog-actions .mdc-dialog__actions {
    margin-top: auto;
    padding: 1rem !important;
  }
}

.ts-modal-actions-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  & button {
    flex: 1;
  }
}

.ngb-modal-layout {
  .modal-content {
    border-radius: 16px;
    background-color: var(--ts-modal-background-color);
    color: var(--ts-text-primary-color);
    padding: 16px;
  }
}
