$separator-primary-color:var(--ts-separator-primary-color);
$separator-secondary-color:var(--ts-separator-secondary-color);
$separator-primary-accent-color:var(--ts-separator-primary-accent-color);
$separator-secondary-accent-color:var(--ts-separator-secondary-accent-color);

$separators: (
  "primary": $separator-primary-color,
  "secondary": $separator-secondary-color,
);

@mixin generate-border-classes {
  @each $name, $color in $separators {
    .border-#{$name} {
      border: 1px solid $color;
    }
    .border-top-#{$name} {
      border-top: 1px solid $color;
    }
    .border-right-#{$name} {
      border-right: 1px solid $color;
    }
    .border-bottom-#{$name} {
      border-bottom: 1px solid $color;
    }
    .border-left-#{$name} {
      border-left: 1px solid $color;
    }
  }
}

@mixin border-style($position: "all", $color: $separator-primary-color) {
  @if $position == "all" {
    border: 1px solid $color;
  } @else if $position == "top" {
    border-top: 1px solid $color;
  } @else if $position == "right" {
    border-right: 1px solid $color;
  } @else if $position == "bottom" {
    border-bottom: 1px solid $color;
  } @else if $position == "left" {
    border-left: 1px solid $color;
  }
}

@mixin outline-style($position: "all", $color: $separator-primary-color) {
  @if $position == "all" {
    outline: 1px solid $color;
  } @else if $position == "top" {
    outline-top: 1px solid $color;
  } @else if $position == "right" {
    outline-right: 1px solid $color;
  } @else if $position == "bottom" {
    outline-bottom: 1px solid $color;
  } @else if $position == "left" {
    outline-left: 1px solid $color;
  }
}

@include generate-border-classes;
