@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?dpq5r1');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?dpq5r1#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?dpq5r1') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?dpq5r1') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?dpq5r1##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ts-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ts-icon-media-pause {
  &:before {
    content: $ts-icon-media-pause;

  }
}
.ts-icon-media-volume-mute {
  &:before {
    content: $ts-icon-media-volume-mute;

  }
}
.ts-icon-media-volume-high {
  &:before {
    content: $ts-icon-media-volume-high;

  }
}
.ts-icon-media-pause-circle {
  &:before {
    content: $ts-icon-media-pause-circle;

  }
}
.ts-icon-media-volume-up {
  &:before {
    content: $ts-icon-media-volume-up;

  }
}
.ts-icon-media-play-circle {
  &:before {
    content: $ts-icon-media-play-circle;

  }
}
.ts-icon-media-volume-slash {
  &:before {
    content: $ts-icon-media-volume-slash;

  }
}
.ts-icon-media-stop-circle {
  &:before {
    content: $ts-icon-media-stop-circle;

  }
}
.ts-icon-media-image {
  &:before {
    content: $ts-icon-media-image;

  }
}
.ts-icon-media-gallery-edit {
  &:before {
    content: $ts-icon-media-gallery-edit;

  }
}
.ts-icon-media-volume-low {
  &:before {
    content: $ts-icon-media-volume-low;

  }
}
.ts-icon-media-gallery-remove {
  &:before {
    content: $ts-icon-media-gallery-remove;

  }
}
.ts-icon-media-record {
  &:before {
    content: $ts-icon-media-record;

  }
}
.ts-icon-media-play {
  &:before {
    content: $ts-icon-media-play;

  }
}
.ts-icon-media-screenmirroring {
  &:before {
    content: $ts-icon-media-screenmirroring;

  }
}
.ts-icon-media-musicnote {
  &:before {
    content: $ts-icon-media-musicnote;

  }
}
.ts-icon-media-video {
  &:before {
    content: $ts-icon-media-video;

  }
}
.ts-icon-media-gallery-add {
  &:before {
    content: $ts-icon-media-gallery-add;

  }
}
.ts-icon-media-gallery {
  &:before {
    content: $ts-icon-media-gallery;

  }
}
.ts-icon-media-gallery-tick {
  &:before {
    content: $ts-icon-media-gallery-tick;

  }
}
.ts-icon-media-video-circle {
  &:before {
    content: $ts-icon-media-video-circle;

  }
}
.ts-icon-media-video-square {
  &:before {
    content: $ts-icon-media-video-square;

  }
}
.ts-icon-media-microphone-slash {
  &:before {
    content: $ts-icon-media-microphone-slash;

  }
}
.ts-icon-media-subtitle {
  &:before {
    content: $ts-icon-media-subtitle;

  }
}
.ts-icon-media-microphone {
  &:before {
    content: $ts-icon-media-microphone;

  }
}
.ts-icon-media-video-play {
  &:before {
    content: $ts-icon-media-video-play;

  }
}
.ts-icon-media-microphone-slash2 {
  &:before {
    content: $ts-icon-media-microphone-slash2;

  }
}
.ts-icon-media-video-vertical {
  &:before {
    content: $ts-icon-media-video-vertical;

  }
}
.ts-icon-media-video-horizontal {
  &:before {
    content: $ts-icon-media-video-horizontal;

  }
}
.ts-icon-media-microphone-2 {
  &:before {
    content: $ts-icon-media-microphone-2;

  }
}
.ts-icon-media-gallery-export {
  &:before {
    content: $ts-icon-media-gallery-export;

  }
}
.ts-icon-media-volume-down {
  &:before {
    content: $ts-icon-media-volume-down;

  }
}
.ts-icon-media-gallery-import {
  &:before {
    content: $ts-icon-media-gallery-import;

  }
}
.ts-icon-media-record-circle {
  &:before {
    content: $ts-icon-media-record-circle;

  }
}
.ts-icon-media-gallery-slash {
  &:before {
    content: $ts-icon-media-gallery-slash;

  }
}
.ts-icon-media-gallery-favorite {
  &:before {
    content: $ts-icon-media-gallery-favorite;

  }
}
.ts-icon-media-camera {
  &:before {
    content: $ts-icon-media-camera;

  }
}
.ts-icon-media-volume-cross {
  &:before {
    content: $ts-icon-media-volume-cross;

  }
}
.ts-icon-media-music {
  &:before {
    content: $ts-icon-media-music;

  }
}
.ts-icon-media-camera-slash {
  &:before {
    content: $ts-icon-media-camera-slash;

  }
}
.ts-icon-media-video-slash {
  &:before {
    content: $ts-icon-media-video-slash;

  }
}
.ts-icon-media-stop {
  &:before {
    content: $ts-icon-media-stop;

  }
}
.ts-icon-media-maximize-circle {
  &:before {
    content: $ts-icon-media-maximize-circle;

  }
}
.ts-icon-media-oice-cricle {
  &:before {
    content: $ts-icon-media-oice-cricle;

  }
}
.ts-icon-media-voice-square {
  &:before {
    content: $ts-icon-media-voice-square;

  }
}
.ts-icon-media-video-octagon {
  &:before {
    content: $ts-icon-media-video-octagon;

  }
}
.ts-icon-media-video-time {
  &:before {
    content: $ts-icon-media-video-time;

  }
}
.ts-icon-media-video-tick {
  &:before {
    content: $ts-icon-media-video-tick;

  }
}
.ts-icon-media-video-remove {
  &:before {
    content: $ts-icon-media-video-remove;

  }
}
.ts-icon-media-video-add {
  &:before {
    content: $ts-icon-media-video-add;

  }
}
.ts-icon-media-mini-music-sqaure {
  &:before {
    content: $ts-icon-media-mini-music-sqaure;

  }
}
.ts-icon-media-audio-square {
  &:before {
    content: $ts-icon-media-audio-square;

  }
}
.ts-icon-media-note-square {
  &:before {
    content: $ts-icon-media-note-square;

  }
}
.ts-icon-media-music-square {
  &:before {
    content: $ts-icon-media-music-square;

  }
}
.ts-icon-media-music-library-2 {
  &:before {
    content: $ts-icon-media-music-library-2;

  }
}
.ts-icon-media-group {
  &:before {
    content: $ts-icon-media-group;

  }
}
.ts-icon-media-devices {
  &:before {
    content: $ts-icon-media-devices;

  }
}
.ts-icon-media-forward-10-seconds {
  &:before {
    content: $ts-icon-media-forward-10-seconds;

  }
}
.ts-icon-media-repeate-one {
  &:before {
    content: $ts-icon-media-repeate-one;

  }
}
.ts-icon-media-repeate-music {
  &:before {
    content: $ts-icon-media-repeate-music;

  }
}
.ts-icon-media-music-square-search {
  &:before {
    content: $ts-icon-media-music-square-search;

  }
}
.ts-icon-media-music-square-add {
  &:before {
    content: $ts-icon-media-music-square-add;

  }
}
.ts-icon-media-music-square-remove {
  &:before {
    content: $ts-icon-media-music-square-remove;

  }
}
.ts-icon-media-music-dashboard {
  &:before {
    content: $ts-icon-media-music-dashboard;

  }
}
.ts-icon-media-music-filter {
  &:before {
    content: $ts-icon-media-music-filter;

  }
}
.ts-icon-media-music-playlist {
  &:before {
    content: $ts-icon-media-music-playlist;

  }
}
.ts-icon-media-forward-15-seconds {
  &:before {
    content: $ts-icon-media-forward-15-seconds;

  }
}
.ts-icon-media-forward-5-seconds {
  &:before {
    content: $ts-icon-media-forward-5-seconds;

  }
}
.ts-icon-media-radio {
  &:before {
    content: $ts-icon-media-radio;

  }
}
.ts-icon-media-next {
  &:before {
    content: $ts-icon-media-next;

  }
}
.ts-icon-media-previous {
  &:before {
    content: $ts-icon-media-previous;

  }
}
.ts-icon-media-forward {
  &:before {
    content: $ts-icon-media-forward;

  }
}
.ts-icon-media-backward-10-seconds {
  &:before {
    content: $ts-icon-media-backward-10-seconds;

  }
}
.ts-icon-media-backward-15-seconds {
  &:before {
    content: $ts-icon-media-backward-15-seconds;

  }
}
.ts-icon-media-backward-5-seconds {
  &:before {
    content: $ts-icon-media-backward-5-seconds;

  }
}
.ts-icon-media-play-add {
  &:before {
    content: $ts-icon-media-play-add;

  }
}
.ts-icon-media-play-remove {
  &:before {
    content: $ts-icon-media-play-remove;

  }
}
.ts-icon-media-music-circle {
  &:before {
    content: $ts-icon-media-music-circle;

  }
}
.ts-icon-media-play-cricle {
  &:before {
    content: $ts-icon-media-play-cricle;

  }
}
.ts-icon-media-backward {
  &:before {
    content: $ts-icon-media-backward;

  }
}
.ts-icon-media-scissor {
  &:before {
    content: $ts-icon-media-scissor;

  }
}
.ts-icon-location-direct-down {
  &:before {
    content: $ts-icon-location-direct-down;

  }
}
.ts-icon-location-routing-2 {
  &:before {
    content: $ts-icon-location-routing-2;

  }
}
.ts-icon-location-routing {
  &:before {
    content: $ts-icon-location-routing;

  }
}
.ts-icon-location-location-minus {
  &:before {
    content: $ts-icon-location-location-minus;

  }
}
.ts-icon-location-map2 {
  &:before {
    content: $ts-icon-location-map2;

  }
}
.ts-icon-location-gps-slash {
  &:before {
    content: $ts-icon-location-gps-slash;

  }
}
.ts-icon-location-location {
  &:before {
    content: $ts-icon-location-location;

  }
}
.ts-icon-location-location-tick {
  &:before {
    content: $ts-icon-location-location-tick;

  }
}
.ts-icon-location-gps {
  &:before {
    content: $ts-icon-location-gps;

  }
}
.ts-icon-location-location-cross {
  &:before {
    content: $ts-icon-location-location-cross;

  }
}
.ts-icon-location-direct-right {
  &:before {
    content: $ts-icon-location-direct-right;

  }
}
.ts-icon-location-location-add {
  &:before {
    content: $ts-icon-location-location-add;

  }
}
.ts-icon-location-direct-left {
  &:before {
    content: $ts-icon-location-direct-left;

  }
}
.ts-icon-location-direct-up {
  &:before {
    content: $ts-icon-location-direct-up;

  }
}
.ts-icon-location-global {
  &:before {
    content: $ts-icon-location-global;

  }
}
.ts-icon-location-global-refresh {
  &:before {
    content: $ts-icon-location-global-refresh;

  }
}
.ts-icon-location-discover {
  &:before {
    content: $ts-icon-location-discover;

  }
}
.ts-icon-location-route-square {
  &:before {
    content: $ts-icon-location-route-square;

  }
}
.ts-icon-location-arrow {
  &:before {
    content: $ts-icon-location-arrow;

  }
}
.ts-icon-location-picture-frame {
  &:before {
    content: $ts-icon-location-picture-frame;

  }
}
.ts-icon-location-arrow-square {
  &:before {
    content: $ts-icon-location-arrow-square;

  }
}
.ts-icon-location-map {
  &:before {
    content: $ts-icon-location-map;

  }
}
.ts-icon-location-global-edit {
  &:before {
    content: $ts-icon-location-global-edit;

  }
}
.ts-icon-location-global-search {
  &:before {
    content: $ts-icon-location-global-search;

  }
}
.ts-icon-location-radar-2 {
  &:before {
    content: $ts-icon-location-radar-2;

  }
}
.ts-icon-location-radar {
  &:before {
    content: $ts-icon-location-radar;

  }
}
.ts-icon-computers-lamp {
  &:before {
    content: $ts-icon-computers-lamp;

  }
}
.ts-icon-computers-simcard-2 {
  &:before {
    content: $ts-icon-computers-simcard-2;

  }
}
.ts-icon-computers-simcard {
  &:before {
    content: $ts-icon-computers-simcard;

  }
}
.ts-icon-computers-ram-2 {
  &:before {
    content: $ts-icon-computers-ram-2;

  }
}
.ts-icon-computers-ram {
  &:before {
    content: $ts-icon-computers-ram;

  }
}
.ts-icon-computers-clock {
  &:before {
    content: $ts-icon-computers-clock;

  }
}
.ts-icon-computers-mouse {
  &:before {
    content: $ts-icon-computers-mouse;

  }
}
.ts-icon-computers-keyboard {
  &:before {
    content: $ts-icon-computers-keyboard;

  }
}
.ts-icon-computers-weight {
  &:before {
    content: $ts-icon-computers-weight;

  }
}
.ts-icon-computers-game {
  &:before {
    content: $ts-icon-computers-game;

  }
}
.ts-icon-computers-cpu-setting {
  &:before {
    content: $ts-icon-computers-cpu-setting;

  }
}
.ts-icon-computers-headphone {
  &:before {
    content: $ts-icon-computers-headphone;

  }
}
.ts-icon-computers-airpod {
  &:before {
    content: $ts-icon-computers-airpod;

  }
}
.ts-icon-computers-printer-slash {
  &:before {
    content: $ts-icon-computers-printer-slash;

  }
}
.ts-icon-computers-monitor-mobbile {
  &:before {
    content: $ts-icon-computers-monitor-mobbile;

  }
}
.ts-icon-computers-monitor-recorder {
  &:before {
    content: $ts-icon-computers-monitor-recorder;

  }
}
.ts-icon-computers-monitor {
  &:before {
    content: $ts-icon-computers-monitor;

  }
}
.ts-icon-computers-cpu {
  &:before {
    content: $ts-icon-computers-cpu;

  }
}
.ts-icon-computers-printer {
  &:before {
    content: $ts-icon-computers-printer;

  }
}
.ts-icon-computers-cpu-charge {
  &:before {
    content: $ts-icon-computers-cpu-charge;

  }
}
.ts-icon-computers-cloud-change {
  &:before {
    content: $ts-icon-computers-cloud-change;

  }
}
.ts-icon-computers-watch-status {
  &:before {
    content: $ts-icon-computers-watch-status;

  }
}
.ts-icon-computers-watch {
  &:before {
    content: $ts-icon-computers-watch;

  }
}
.ts-icon-computers-gameboy {
  &:before {
    content: $ts-icon-computers-gameboy;

  }
}
.ts-icon-computers-airdrop {
  &:before {
    content: $ts-icon-computers-airdrop;

  }
}
.ts-icon-computers-keyboard-open {
  &:before {
    content: $ts-icon-computers-keyboard-open;

  }
}
.ts-icon-computers-simcard-code {
  &:before {
    content: $ts-icon-computers-simcard-code;

  }
}
.ts-icon-computers-microscope {
  &:before {
    content: $ts-icon-computers-microscope;

  }
}
.ts-icon-computers-mirroring-screen {
  &:before {
    content: $ts-icon-computers-mirroring-screen;

  }
}
.ts-icon-computers-electricity {
  &:before {
    content: $ts-icon-computers-electricity;

  }
}
.ts-icon-computers-mobile {
  &:before {
    content: $ts-icon-computers-mobile;

  }
}
.ts-icon-computers-folder-connection {
  &:before {
    content: $ts-icon-computers-folder-connection;

  }
}
.ts-icon-computers-headphones {
  &:before {
    content: $ts-icon-computers-headphones;

  }
}
.ts-icon-computers-airpods {
  &:before {
    content: $ts-icon-computers-airpods;

  }
}
.ts-icon-computers-devices {
  &:before {
    content: $ts-icon-computers-devices;

  }
}
.ts-icon-computers-music-play {
  &:before {
    content: $ts-icon-computers-music-play;

  }
}
.ts-icon-computers-cloud-connection {
  &:before {
    content: $ts-icon-computers-cloud-connection;

  }
}
.ts-icon-computers-external-drive {
  &:before {
    content: $ts-icon-computers-external-drive;

  }
}
.ts-icon-computers-cloud-remove {
  &:before {
    content: $ts-icon-computers-cloud-remove;

  }
}
.ts-icon-computers-cloud-add {
  &:before {
    content: $ts-icon-computers-cloud-add;

  }
}
.ts-icon-computers-driver-refresh {
  &:before {
    content: $ts-icon-computers-driver-refresh;

  }
}
.ts-icon-computers-driver-2 {
  &:before {
    content: $ts-icon-computers-driver-2;

  }
}
.ts-icon-computers-driver {
  &:before {
    content: $ts-icon-computers-driver;

  }
}
.ts-icon-computers-speaker {
  &:before {
    content: $ts-icon-computers-speaker;

  }
}
.ts-icon-programing-data-2 {
  &:before {
    content: $ts-icon-programing-data-2;

  }
}
.ts-icon-programing-hierarchy-square-3 {
  &:before {
    content: $ts-icon-programing-hierarchy-square-3;

  }
}
.ts-icon-programing-hierarchy-square-2 {
  &:before {
    content: $ts-icon-programing-hierarchy-square-2;

  }
}
.ts-icon-programing-sidebar-bottom {
  &:before {
    content: $ts-icon-programing-sidebar-bottom;

  }
}
.ts-icon-programing-scroll {
  &:before {
    content: $ts-icon-programing-scroll;

  }
}
.ts-icon-programing-sidebar-top {
  &:before {
    content: $ts-icon-programing-sidebar-top;

  }
}
.ts-icon-programing-sidebar-left {
  &:before {
    content: $ts-icon-programing-sidebar-left;

  }
}
.ts-icon-programing-sidebar-right {
  &:before {
    content: $ts-icon-programing-sidebar-right;

  }
}
.ts-icon-programing-code {
  &:before {
    content: $ts-icon-programing-code;

  }
}
.ts-icon-programing-hashtag {
  &:before {
    content: $ts-icon-programing-hashtag;

  }
}
.ts-icon-programing-hashtag-up {
  &:before {
    content: $ts-icon-programing-hashtag-up;

  }
}
.ts-icon-programing-hashtag-down {
  &:before {
    content: $ts-icon-programing-hashtag-down;

  }
}
.ts-icon-programing-code2 {
  &:before {
    content: $ts-icon-programing-code2;

  }
}
.ts-icon-programing-mobile-programming {
  &:before {
    content: $ts-icon-programing-mobile-programming;

  }
}
.ts-icon-programing-message-programming {
  &:before {
    content: $ts-icon-programing-message-programming;

  }
}
.ts-icon-programing-document-code-2 {
  &:before {
    content: $ts-icon-programing-document-code-2;

  }
}
.ts-icon-programing-programming-arrows {
  &:before {
    content: $ts-icon-programing-programming-arrows;

  }
}
.ts-icon-programing-hierarchy-3 {
  &:before {
    content: $ts-icon-programing-hierarchy-3;

  }
}
.ts-icon-programing-code-circle {
  &:before {
    content: $ts-icon-programing-code-circle;

  }
}
.ts-icon-programing-document-code {
  &:before {
    content: $ts-icon-programing-document-code;

  }
}
.ts-icon-programing-data {
  &:before {
    content: $ts-icon-programing-data;

  }
}
.ts-icon-programing-hierarchy-square {
  &:before {
    content: $ts-icon-programing-hierarchy-square;

  }
}
.ts-icon-programing-ommand-square {
  &:before {
    content: $ts-icon-programing-ommand-square;

  }
}
.ts-icon-programing-hierarchy {
  &:before {
    content: $ts-icon-programing-hierarchy;

  }
}
.ts-icon-programing-programming-arrow {
  &:before {
    content: $ts-icon-programing-programming-arrow;

  }
}
.ts-icon-programing-command {
  &:before {
    content: $ts-icon-programing-command;

  }
}
.ts-icon-building-hospital {
  &:before {
    content: $ts-icon-building-hospital;

  }
}
.ts-icon-building-buliding {
  &:before {
    content: $ts-icon-building-buliding;

  }
}
.ts-icon-building-house-2 {
  &:before {
    content: $ts-icon-building-house-2;

  }
}
.ts-icon-building-house {
  &:before {
    content: $ts-icon-building-house;

  }
}
.ts-icon-building-courthouse {
  &:before {
    content: $ts-icon-building-courthouse;

  }
}
.ts-icon-building-building-4 {
  &:before {
    content: $ts-icon-building-building-4;

  }
}
.ts-icon-building-building-3 {
  &:before {
    content: $ts-icon-building-building-3;

  }
}
.ts-icon-building-buildings-2 {
  &:before {
    content: $ts-icon-building-buildings-2;

  }
}
.ts-icon-building-bank {
  &:before {
    content: $ts-icon-building-bank;

  }
}
.ts-icon-building-buildings {
  &:before {
    content: $ts-icon-building-buildings;

  }
}
.ts-icon-building-building {
  &:before {
    content: $ts-icon-building-building;

  }
}
.ts-icon-charts-vertical-chart {
  &:before {
    content: $ts-icon-charts-vertical-chart;

  }
}
.ts-icon-charts-pie-chart-alt2 {
  &:before {
    content: $ts-icon-charts-pie-chart-alt2;

  }
}
.ts-icon-charts-pie-chart-alt {
  &:before {
    content: $ts-icon-charts-pie-chart-alt;

  }
}
.ts-icon-charts-analytics-2 {
  &:before {
    content: $ts-icon-charts-analytics-2;

  }
}
.ts-icon-charts-analytics {
  &:before {
    content: $ts-icon-charts-analytics;

  }
}
.ts-icon-charts-seo {
  &:before {
    content: $ts-icon-charts-seo;

  }
}
.ts-icon-charts-pie-chart-segments {
  &:before {
    content: $ts-icon-charts-pie-chart-segments;

  }
}
.ts-icon-charts-pie-chart {
  &:before {
    content: $ts-icon-charts-pie-chart;

  }
}
.ts-icon-charts-status-up {
  &:before {
    content: $ts-icon-charts-status-up;

  }
}
.ts-icon-charts-personalcard {
  &:before {
    content: $ts-icon-charts-personalcard;

  }
}
.ts-icon-charts-chart {
  &:before {
    content: $ts-icon-charts-chart;

  }
}
.ts-icon-charts-hashtag {
  &:before {
    content: $ts-icon-charts-hashtag;

  }
}
.ts-icon-charts-trend-down {
  &:before {
    content: $ts-icon-charts-trend-down;

  }
}
.ts-icon-charts-home-hashtag {
  &:before {
    content: $ts-icon-charts-home-hashtag;

  }
}
.ts-icon-charts-trend-up {
  &:before {
    content: $ts-icon-charts-trend-up;

  }
}
.ts-icon-charts-home-trend-down {
  &:before {
    content: $ts-icon-charts-home-trend-down;

  }
}
.ts-icon-charts-chart2 {
  &:before {
    content: $ts-icon-charts-chart2;

  }
}
.ts-icon-charts-home-trend-up {
  &:before {
    content: $ts-icon-charts-home-trend-up;

  }
}
.ts-icon-charts-graph {
  &:before {
    content: $ts-icon-charts-graph;

  }
}
.ts-icon-charts-chart-2 {
  &:before {
    content: $ts-icon-charts-chart-2;

  }
}
.ts-icon-charts-activity {
  &:before {
    content: $ts-icon-charts-activity;

  }
}
.ts-icon-charts-frame {
  &:before {
    content: $ts-icon-charts-frame;

  }
}
.ts-icon-charts-health {
  &:before {
    content: $ts-icon-charts-health;

  }
}
.ts-icon-charts-chart-success {
  &:before {
    content: $ts-icon-charts-chart-success;

  }
}
.ts-icon-charts-favorite-chart {
  &:before {
    content: $ts-icon-charts-favorite-chart;

  }
}
.ts-icon-charts-chart3 {
  &:before {
    content: $ts-icon-charts-chart3;

  }
}
.ts-icon-charts-presention-chart {
  &:before {
    content: $ts-icon-charts-presention-chart;

  }
}
.ts-icon-charts-diagram {
  &:before {
    content: $ts-icon-charts-diagram;

  }
}
.ts-icon-learning-calculator {
  &:before {
    content: $ts-icon-learning-calculator;

  }
}
.ts-icon-learning-book-open {
  &:before {
    content: $ts-icon-learning-book-open;

  }
}
.ts-icon-learning-gift {
  &:before {
    content: $ts-icon-learning-gift;

  }
}
.ts-icon-learning-note {
  &:before {
    content: $ts-icon-learning-note;

  }
}
.ts-icon-learning-teacher {
  &:before {
    content: $ts-icon-learning-teacher;

  }
}
.ts-icon-learning-award {
  &:before {
    content: $ts-icon-learning-award;

  }
}
.ts-icon-learning-note-2 {
  &:before {
    content: $ts-icon-learning-note-2;

  }
}
.ts-icon-learning-bookmark-2 {
  &:before {
    content: $ts-icon-learning-bookmark-2;

  }
}
.ts-icon-learning-clipboard {
  &:before {
    content: $ts-icon-learning-clipboard;

  }
}
.ts-icon-learning-book {
  &:before {
    content: $ts-icon-learning-book;

  }
}
.ts-icon-learning-bookmark {
  &:before {
    content: $ts-icon-learning-bookmark;

  }
}
.ts-icon-learning-brifecase-cross {
  &:before {
    content: $ts-icon-learning-brifecase-cross;

  }
}
.ts-icon-learning-brifecase-tick {
  &:before {
    content: $ts-icon-learning-brifecase-tick;

  }
}
.ts-icon-learning-brifecase-timer {
  &:before {
    content: $ts-icon-learning-brifecase-timer;

  }
}
.ts-icon-learning-briefcase {
  &:before {
    content: $ts-icon-learning-briefcase;

  }
}
.ts-icon-users-support {
  &:before {
    content: $ts-icon-users-support;
  }
}
.ts-icon-users-profile {
  &:before {
    content: $ts-icon-users-profile;

  }
}
.ts-icon-users-profile-circle {
  &:before {
    content: $ts-icon-users-profile-circle;

  }
}
.ts-icon-users-profile-2user {
  &:before {
    content: $ts-icon-users-profile-2user;

  }
}
.ts-icon-users-profile-delete {
  &:before {
    content: $ts-icon-users-profile-delete;

  }
}
.ts-icon-users-profile-tick {
  &:before {
    content: $ts-icon-users-profile-tick;

  }
}
.ts-icon-users-profile-add {
  &:before {
    content: $ts-icon-users-profile-add;

  }
}
.ts-icon-users-profile-remove {
  &:before {
    content: $ts-icon-users-profile-remove;

  }
}
.ts-icon-users-user-square {
  &:before {
    content: $ts-icon-users-user-square;

  }
}
.ts-icon-users-user-cirlce-add {
  &:before {
    content: $ts-icon-users-user-cirlce-add;

  }
}
.ts-icon-users-user-octagon {
  &:before {
    content: $ts-icon-users-user-octagon;

  }
}
.ts-icon-users-user-edit {
  &:before {
    content: $ts-icon-users-user-edit;

  }
}
.ts-icon-users-user-search {
  &:before {
    content: $ts-icon-users-user-search;

  }
}
.ts-icon-users-user-tag {
  &:before {
    content: $ts-icon-users-user-tag;

  }
}
.ts-icon-users-user-minus {
  &:before {
    content: $ts-icon-users-user-minus;

  }
}
.ts-icon-users-user-tick {
  &:before {
    content: $ts-icon-users-user-tick;

  }
}
.ts-icon-users-user-remove {
  &:before {
    content: $ts-icon-users-user-remove;

  }
}
.ts-icon-users-user-add {
  &:before {
    content: $ts-icon-users-user-add;

  }
}
.ts-icon-users-user {
  &:before {
    content: $ts-icon-users-user;

  }
}
.ts-icon-users-people {
  &:before {
    content: $ts-icon-users-people;

  }
}
.ts-icon-users-tag-user {
  &:before {
    content: $ts-icon-users-tag-user;

  }
}
.ts-icon-call-call-minus {
  &:before {
    content: $ts-icon-call-call-minus;

  }
}
.ts-icon-call-call-remove {
  &:before {
    content: $ts-icon-call-call-remove;

  }
}
.ts-icon-call-call {
  &:before {
    content: $ts-icon-call-call;

  }
}
.ts-icon-call-call-slash {
  &:before {
    content: $ts-icon-call-call-slash;

  }
}
.ts-icon-call-call-outgoing {
  &:before {
    content: $ts-icon-call-call-outgoing;

  }
}
.ts-icon-call-call-add {
  &:before {
    content: $ts-icon-call-call-add;

  }
}
.ts-icon-call-call-incoming {
  &:before {
    content: $ts-icon-call-call-incoming;

  }
}
.ts-icon-call-call-received {
  &:before {
    content: $ts-icon-call-call-received;

  }
}
.ts-icon-call-call-calling {
  &:before {
    content: $ts-icon-call-call-calling;
  }
}
.ts-icon-communication-Emoji {
  &:before {
    content: $ts-icon-communication-Emoji;

  }
}
.ts-icon-communication-star-slash {
  &:before {
    content: $ts-icon-communication-star-slash;

  }
}
.ts-icon-communication-like {
  &:before {
    content: $ts-icon-communication-like;

  }
}
.ts-icon-communication-heart-circle {
  &:before {
    content: $ts-icon-communication-heart-circle;

  }
}
.ts-icon-communication-like_heart {
  &:before {
    content: $ts-icon-communication-like_heart;

  }
}
.ts-icon-communication-unlimited {
  &:before {
    content: $ts-icon-communication-unlimited;

  }
}
.ts-icon-communication-medal {
  &:before {
    content: $ts-icon-communication-medal;

  }
}
.ts-icon-communication-lovely {
  &:before {
    content: $ts-icon-communication-lovely;

  }
}
.ts-icon-communication-medal-star {
  &:before {
    content: $ts-icon-communication-medal-star;

  }
}
.ts-icon-communication-heart-add {
  &:before {
    content: $ts-icon-communication-heart-add;

  }
}
.ts-icon-communication-heart-remove {
  &:before {
    content: $ts-icon-communication-heart-remove;

  }
}
.ts-icon-communication-heart-slash {
  &:before {
    content: $ts-icon-communication-heart-slash;

  }
}
.ts-icon-communication-like-shapes {
  &:before {
    content: $ts-icon-communication-like-shapes;

  }
}
.ts-icon-communication-24-support {
  &:before {
    content: $ts-icon-communication-24-support;

  }
}
.ts-icon-communication-like-tag {
  &:before {
    content: $ts-icon-communication-like-tag;

  }
}
.ts-icon-communication-like-dislike {
  &:before {
    content: $ts-icon-communication-like-dislike;

  }
}
.ts-icon-communication-smileys {
  &:before {
    content: $ts-icon-communication-smileys;

  }
}
.ts-icon-communication-message-question {
  &:before {
    content: $ts-icon-communication-message-question;

  }
}
.ts-icon-communication-heart-tick {
  &:before {
    content: $ts-icon-communication-heart-tick;

  }
}
.ts-icon-communication-heart-search {
  &:before {
    content: $ts-icon-communication-heart-search;

  }
}
.ts-icon-communication-heart-edit {
  &:before {
    content: $ts-icon-communication-heart-edit;

  }
}
.ts-icon-communication-magic-star {
  &:before {
    content: $ts-icon-communication-magic-star;

  }
}
.ts-icon-communication-ranking {
  &:before {
    content: $ts-icon-communication-ranking;

  }
}
.ts-icon-communication-star2 {
  &:before {
    content: $ts-icon-communication-star2;

  }
}
.ts-icon-communication-dislike {
  &:before {
    content: $ts-icon-communication-dislike;

  }
}
.ts-icon-type-textalign-justifycenter {
  &:before {
    content: $ts-icon-type-textalign-justifycenter;

  }
}
.ts-icon-type-paperclip {
  &:before {
    content: $ts-icon-type-paperclip;

  }
}
.ts-icon-type-textalign-justifyright {
  &:before {
    content: $ts-icon-type-textalign-justifyright;

  }
}
.ts-icon-type-paperclip-2 {
  &:before {
    content: $ts-icon-type-paperclip-2;

  }
}
.ts-icon-type-Default {
  &:before {
    content: $ts-icon-type-Default;

  }
}
.ts-icon-type-textalign-right {
  &:before {
    content: $ts-icon-type-textalign-right;

  }
}
.ts-icon-type-text-underline {
  &:before {
    content: $ts-icon-type-text-underline;

  }
}
.ts-icon-type-textalign-center {
  &:before {
    content: $ts-icon-type-textalign-center;

  }
}
.ts-icon-type-text-bold {
  &:before {
    content: $ts-icon-type-text-bold;

  }
}
.ts-icon-type-textalign-left {
  &:before {
    content: $ts-icon-type-textalign-left;

  }
}
.ts-icon-type-smallcaps {
  &:before {
    content: $ts-icon-type-smallcaps;

  }
}
.ts-icon-type-text {
  &:before {
    content: $ts-icon-type-text;

  }
}
.ts-icon-type-maximize {
  &:before {
    content: $ts-icon-type-maximize;

  }
}
.ts-icon-type-pharagraphspacing {
  &:before {
    content: $ts-icon-type-pharagraphspacing;

  }
}
.ts-icon-type-text-italic {
  &:before {
    content: $ts-icon-type-text-italic;

  }
}
.ts-icon-type-text-block {
  &:before {
    content: $ts-icon-type-text-block;

  }
}
.ts-icon-type-link {
  &:before {
    content: $ts-icon-type-link;

  }
}
.ts-icon-type-firstline {
  &:before {
    content: $ts-icon-type-firstline;

  }
}
.ts-icon-type-link2 {
  &:before {
    content: $ts-icon-type-link2;

  }
}
.ts-icon-type-textalign-justifyleft {
  &:before {
    content: $ts-icon-type-textalign-justifyleft;

  }
}
.ts-icon-type-anguage-circle {
  &:before {
    content: $ts-icon-type-anguage-circle;

  }
}
.ts-icon-type-language-square {
  &:before {
    content: $ts-icon-type-language-square;

  }
}
.ts-icon-type-translate {
  &:before {
    content: $ts-icon-type-translate;

  }
}
.ts-icon-type-link-circle {
  &:before {
    content: $ts-icon-type-link-circle;

  }
}
.ts-icon-type-link-square {
  &:before {
    content: $ts-icon-type-link-square;

  }
}
.ts-icon-type-link3 {
  &:before {
    content: $ts-icon-type-link3;

  }
}
.ts-icon-type-quote-up-circle {
  &:before {
    content: $ts-icon-type-quote-up-circle;

  }
}
.ts-icon-type-quote-down-circle {
  &:before {
    content: $ts-icon-type-quote-down-circle;

  }
}
.ts-icon-type-quote-down-square {
  &:before {
    content: $ts-icon-type-quote-down-square;

  }
}
.ts-icon-type-quote-up-square {
  &:before {
    content: $ts-icon-type-quote-up-square;

  }
}
.ts-icon-type-quote-down {
  &:before {
    content: $ts-icon-type-quote-down;

  }
}
.ts-icon-type-quote-up {
  &:before {
    content: $ts-icon-type-quote-up;

  }
}
.ts-icon-type-attach-circle {
  &:before {
    content: $ts-icon-type-attach-circle;

  }
}
.ts-icon-type-attach-square {
  &:before {
    content: $ts-icon-type-attach-square;

  }
}
.ts-icon-type-eraser {
  &:before {
    content: $ts-icon-type-eraser;

  }
}
.ts-icon-messages-message-text {
  &:before {
    content: $ts-icon-messages-message-text;

  }
}
.ts-icon-messages-message-notif {
  &:before {
    content: $ts-icon-messages-message-notif;

  }
}
.ts-icon-messages-message-favorite {
  &:before {
    content: $ts-icon-messages-message-favorite;

  }
}
.ts-icon-messages-message {
  &:before {
    content: $ts-icon-messages-message;

  }
}
.ts-icon-messages-direct-send {
  &:before {
    content: $ts-icon-messages-direct-send;

  }
}
.ts-icon-messages-direct-inbox {
  &:before {
    content: $ts-icon-messages-direct-inbox;

  }
}
.ts-icon-messages-direct-notification {
  &:before {
    content: $ts-icon-messages-direct-notification;

  }
}
.ts-icon-messages-direct {
  &:before {
    content: $ts-icon-messages-direct;

  }
}
.ts-icon-messages-direct-normal {
  &:before {
    content: $ts-icon-messages-direct-normal;

  }
}
.ts-icon-messages-directbox-send {
  &:before {
    content: $ts-icon-messages-directbox-send;

  }
}
.ts-icon-messages-directbox-receive {
  &:before {
    content: $ts-icon-messages-directbox-receive;

  }
}
.ts-icon-messages-directbox-notif {
  &:before {
    content: $ts-icon-messages-directbox-notif;

  }
}
.ts-icon-messages-directbox-default {
  &:before {
    content: $ts-icon-messages-directbox-default;

  }
}
.ts-icon-messages-message-edit {
  &:before {
    content: $ts-icon-messages-message-edit;

  }
}
.ts-icon-messages-message-plus {
  &:before {
    content: $ts-icon-messages-message-plus;

  }
}
.ts-icon-messages-message-minus {
  &:before {
    content: $ts-icon-messages-message-minus;

  }
}
.ts-icon-messages-message-text2 {
  &:before {
    content: $ts-icon-messages-message-text2;

  }
}
.ts-icon-messages-message-square {
  &:before {
    content: $ts-icon-messages-message-square;

  }
}
.ts-icon-messages-message-circle {
  &:before {
    content: $ts-icon-messages-message-circle;

  }
}
.ts-icon-messages-messages2 {
  &:before {
    content: $ts-icon-messages-messages2;

  }
}
.ts-icon-messages-message-2 {
  &:before {
    content: $ts-icon-messages-message-2;

  }
}
.ts-icon-messages-message-time {
  &:before {
    content: $ts-icon-messages-message-time;

  }
}
.ts-icon-messages-message-tick {
  &:before {
    content: $ts-icon-messages-message-tick;

  }
}
.ts-icon-messages-message-add {
  &:before {
    content: $ts-icon-messages-message-add;

  }
}
.ts-icon-messages-message-remove {
  &:before {
    content: $ts-icon-messages-message-remove;
  }
}
.ts-icon-messages-sms-search {
  &:before {
    content: $ts-icon-messages-sms-search;

  }
}
.ts-icon-messages-sms-star {
  &:before {
    content: $ts-icon-messages-sms-star;

  }
}
.ts-icon-messages-sms-edit {
  &:before {
    content: $ts-icon-messages-sms-edit;

  }
}
.ts-icon-messages-sms-notification {
  &:before {
    content: $ts-icon-messages-sms-notification;

  }
}
.ts-icon-messages-sms-tracking {
  &:before {
    content: $ts-icon-messages-sms-tracking;

  }
}
.ts-icon-messages-sms {
  &:before {
    content: $ts-icon-messages-sms;

  }
}
.ts-icon-messages-messages-3 {
  &:before {
    content: $ts-icon-messages-messages-3;

  }
}
.ts-icon-messages-messages-2 {
  &:before {
    content: $ts-icon-messages-messages-2;

  }
}
.ts-icon-messages-messages {
  &:before {
    content: $ts-icon-messages-messages;

  }
}
.ts-icon-messages-device-message {
  &:before {
    content: $ts-icon-messages-device-message;

  }
}
.ts-icon-messages-message-search {
  &:before {
    content: $ts-icon-messages-message-search;

  }
}
.ts-icon-goods-box {
  &:before {
    content: $ts-icon-goods-box;

  }
}
.ts-icon-goods-3d-rotate {
  &:before {
    content: $ts-icon-goods-3d-rotate;

  }
}
.ts-icon-goods-3d-square {
  &:before {
    content: $ts-icon-goods-3d-square;

  }
}
.ts-icon-goods-truck-remove {
  &:before {
    content: $ts-icon-goods-truck-remove;

  }
}
.ts-icon-goods-truck-time {
  &:before {
    content: $ts-icon-goods-truck-time;

  }
}
.ts-icon-goods-truck-tick {
  &:before {
    content: $ts-icon-goods-truck-tick;

  }
}
.ts-icon-goods-box-search {
  &:before {
    content: $ts-icon-goods-box-search;

  }
}
.ts-icon-goods-group {
  &:before {
    content: $ts-icon-goods-group;

  }
}
.ts-icon-goods-group2 {
  &:before {
    content: $ts-icon-goods-group2;

  }
}
.ts-icon-goods-box2 {
  &:before {
    content: $ts-icon-goods-box2;

  }
}
.ts-icon-goods-box-time {
  &:before {
    content: $ts-icon-goods-box-time;

  }
}
.ts-icon-goods-box-tick {
  &:before {
    content: $ts-icon-goods-box-tick;

  }
}
.ts-icon-goods-box-remove {
  &:before {
    content: $ts-icon-goods-box-remove;

  }
}
.ts-icon-goods-box-add {
  &:before {
    content: $ts-icon-goods-box-add;

  }
}
.ts-icon-goods-convert-3d-cube {
  &:before {
    content: $ts-icon-goods-convert-3d-cube;

  }
}
.ts-icon-goods-3d-cube-scan {
  &:before {
    content: $ts-icon-goods-3d-cube-scan;

  }
}
.ts-icon-money-vuesax {
  &:before {
    content: $ts-icon-money-vuesax;

  }
}
.ts-icon-money-money-recive {
  &:before {
    content: $ts-icon-money-money-recive;

  }
}
.ts-icon-money-math {
  &:before {
    content: $ts-icon-money-math;

  }
}
.ts-icon-money-ticket-star {
  &:before {
    content: $ts-icon-money-ticket-star;

  }
}
.ts-icon-money-ticket {
  &:before {
    content: $ts-icon-money-ticket;

  }
}
.ts-icon-money-receipt-disscount {
  &:before {
    content: $ts-icon-money-receipt-disscount;

  }
}
.ts-icon-money-receipt-3 {
  &:before {
    content: $ts-icon-money-receipt-3;

  }
}
.ts-icon-money-dollar-circle {
  &:before {
    content: $ts-icon-money-dollar-circle;

  }
}
.ts-icon-money-wallet2 {
  &:before {
    content: $ts-icon-money-wallet2;

  }
}
.ts-icon-money-wallet-add2 {
  &:before {
    content: $ts-icon-money-wallet-add2;

  }
}
.ts-icon-money-receipt {
  &:before {
    content: $ts-icon-money-receipt;

  }
}
.ts-icon-money-dollar-square {
  &:before {
    content: $ts-icon-money-dollar-square;

  }
}
.ts-icon-money-card-receive {
  &:before {
    content: $ts-icon-money-card-receive;

  }
}
.ts-icon-money-card-pos {
  &:before {
    content: $ts-icon-money-card-pos;

  }
}
.ts-icon-money-card-tick2 {
  &:before {
    content: $ts-icon-money-card-tick2;

  }
}
.ts-icon-money-card {
  &:before {
    content: $ts-icon-money-card;

  }
}
.ts-icon-money-card-add {
  &:before {
    content: $ts-icon-money-card-add;

  }
}
.ts-icon-money-card-slash {
  &:before {
    content: $ts-icon-money-card-slash;

  }
}
.ts-icon-money-card-remove2 {
  &:before {
    content: $ts-icon-money-card-remove2;

  }
}
.ts-icon-money-tag {
  &:before {
    content: $ts-icon-money-tag;

  }
}
.ts-icon-money-ticket-discount {
  &:before {
    content: $ts-icon-money-ticket-discount;

  }
}
.ts-icon-money-ticket-2 {
  &:before {
    content: $ts-icon-money-ticket-2;

  }
}
.ts-icon-money-ticket-expired {
  &:before {
    content: $ts-icon-money-ticket-expired;

  }
}
.ts-icon-money-card-edit {
  &:before {
    content: $ts-icon-money-card-edit;

  }
}
.ts-icon-money-wallet-minus {
  &:before {
    content: $ts-icon-money-wallet-minus;

  }
}
.ts-icon-money-tag-2 {
  &:before {
    content: $ts-icon-money-tag-2;

  }
}
.ts-icon-money-card-send {
  &:before {
    content: $ts-icon-money-card-send;

  }
}
.ts-icon-money-coin2 {
  &:before {
    content: $ts-icon-money-coin2;

  }
}
.ts-icon-money-document {
  &:before {
    content: $ts-icon-money-document;

  }
}
.ts-icon-money-archive {
  &:before {
    content: $ts-icon-money-archive;

  }
}
.ts-icon-money-security-card {
  &:before {
    content: $ts-icon-money-security-card;

  }
}
.ts-icon-money-wallet {
  &:before {
    content: $ts-icon-money-wallet;

  }
}
.ts-icon-money-strongbox {
  &:before {
    content: $ts-icon-money-strongbox;

  }
}
.ts-icon-money-receipt-text {
  &:before {
    content: $ts-icon-money-receipt-text;

  }
}
.ts-icon-money-money-time {
  &:before {
    content: $ts-icon-money-money-time;

  }
}
.ts-icon-money-wallet-remove {
  &:before {
    content: $ts-icon-money-wallet-remove;

  }
}
.ts-icon-money-receipt3 {
  &:before {
    content: $ts-icon-money-receipt3;

  }
}
.ts-icon-money-money-change {
  &:before {
    content: $ts-icon-money-money-change;

  }
}
.ts-icon-money-receipt-2 {
  &:before {
    content: $ts-icon-money-receipt-2;

  }
}
.ts-icon-money-card-tick {
  &:before {
    content: $ts-icon-money-card-tick;

  }
}
.ts-icon-money-wallet-3 {
  &:before {
    content: $ts-icon-money-wallet-3;

  }
}
.ts-icon-money-money-forbidden {
  &:before {
    content: $ts-icon-money-money-forbidden;

  }
}
.ts-icon-money-cards {
  &:before {
    content: $ts-icon-money-cards;

  }
}
.ts-icon-money-card-remove {
  &:before {
    content: $ts-icon-money-card-remove;

  }
}
.ts-icon-money-wallet-money {
  &:before {
    content: $ts-icon-money-wallet-money;

  }
}
.ts-icon-money-money-remove {
  &:before {
    content: $ts-icon-money-money-remove;

  }
}
.ts-icon-money-wallet-2 {
  &:before {
    content: $ts-icon-money-wallet-2;

  }
}
.ts-icon-money-discount-circle {
  &:before {
    content: $ts-icon-money-discount-circle;

  }
}
.ts-icon-money-group2 {
  &:before {
    content: $ts-icon-money-group2;

  }
}
.ts-icon-money-money-add {
  &:before {
    content: $ts-icon-money-money-add;

  }
}
.ts-icon-money-receipt-search {
  &:before {
    content: $ts-icon-money-receipt-search;

  }
}
.ts-icon-money-discount-shape {
  &:before {
    content: $ts-icon-money-discount-shape;

  }
}
.ts-icon-money-money-tick {
  &:before {
    content: $ts-icon-money-money-tick;

  }
}
.ts-icon-money-receipt-edit {
  &:before {
    content: $ts-icon-money-receipt-edit;

  }
}
.ts-icon-money-coin {
  &:before {
    content: $ts-icon-money-coin;

  }
}
.ts-icon-money-moneys {
  &:before {
    content: $ts-icon-money-moneys;

  }
}
.ts-icon-money-chart-square {
  &:before {
    content: $ts-icon-money-chart-square;

  }
}
.ts-icon-money-group {
  &:before {
    content: $ts-icon-money-group;

  }
}
.ts-icon-money-money-4 {
  &:before {
    content: $ts-icon-money-money-4;

  }
}
.ts-icon-money-percentage-square {
  &:before {
    content: $ts-icon-money-percentage-square;

  }
}
.ts-icon-money-money-3 {
  &:before {
    content: $ts-icon-money-money-3;

  }
}
.ts-icon-money-empty-wallet-change {
  &:before {
    content: $ts-icon-money-empty-wallet-change;

  }
}
.ts-icon-money-money-2 {
  &:before {
    content: $ts-icon-money-money-2;

  }
}
.ts-icon-money-transaction-minus {
  &:before {
    content: $ts-icon-money-transaction-minus;

  }
}
.ts-icon-money-empty-wallet-time {
  &:before {
    content: $ts-icon-money-empty-wallet-time;

  }
}
.ts-icon-money-money {
  &:before {
    content: $ts-icon-money-money;

  }
}
.ts-icon-money-receipt-add {
  &:before {
    content: $ts-icon-money-receipt-add;

  }
}
.ts-icon-money-empty-wallet-tick {
  &:before {
    content: $ts-icon-money-empty-wallet-tick;

  }
}
.ts-icon-money-strongbox-2 {
  &:before {
    content: $ts-icon-money-strongbox-2;

  }
}
.ts-icon-money-wallet-search {
  &:before {
    content: $ts-icon-money-wallet-search;

  }
}
.ts-icon-money-receipt-minus {
  &:before {
    content: $ts-icon-money-receipt-minus;

  }
}
.ts-icon-money-empty-wallet-add {
  &:before {
    content: $ts-icon-money-empty-wallet-add;

  }
}
.ts-icon-money-empty-wallet-remove {
  &:before {
    content: $ts-icon-money-empty-wallet-remove;

  }
}
.ts-icon-money-receipt-discount {
  &:before {
    content: $ts-icon-money-receipt-discount;

  }
}
.ts-icon-money-receipt-item {
  &:before {
    content: $ts-icon-money-receipt-item;

  }
}
.ts-icon-money-wallet-check {
  &:before {
    content: $ts-icon-money-wallet-check;

  }
}
.ts-icon-money-empty-wallet {
  &:before {
    content: $ts-icon-money-empty-wallet;

  }
}
.ts-icon-money-wallet-add {
  &:before {
    content: $ts-icon-money-wallet-add;

  }
}
.ts-icon-shop-Gift {
  &:before {
    content: $ts-icon-shop-Gift;

  }
}
.ts-icon-shop-Basket_alt_3 {
  &:before {
    content: $ts-icon-shop-Basket_alt_3;

  }
}
.ts-icon-shop-Bag_alt {
  &:before {
    content: $ts-icon-shop-Bag_alt;

  }
}
.ts-icon-shop-bag {
  &:before {
    content: $ts-icon-shop-bag;

  }
}
.ts-icon-shop-shop {
  &:before {
    content: $ts-icon-shop-shop;

  }
}
.ts-icon-shop-Home {
  &:before {
    content: $ts-icon-shop-Home;

  }
}
.ts-icon-shop-bag-tick {
  &:before {
    content: $ts-icon-shop-bag-tick;

  }
}
.ts-icon-shop-bag-cross2 {
  &:before {
    content: $ts-icon-shop-bag-cross2;

  }
}
.ts-icon-shop-bag-tick-2 {
  &:before {
    content: $ts-icon-shop-bag-tick-2;

  }
}
.ts-icon-shop-bag-happy {
  &:before {
    content: $ts-icon-shop-bag-happy;

  }
}
.ts-icon-shop-bag-2 {
  &:before {
    content: $ts-icon-shop-bag-2;

  }
}
.ts-icon-shop-bag-timer {
  &:before {
    content: $ts-icon-shop-bag-timer;

  }
}
.ts-icon-shop-bag-cross {
  &:before {
    content: $ts-icon-shop-bag-cross;

  }
}
.ts-icon-shop-barcode {
  &:before {
    content: $ts-icon-shop-barcode;

  }
}
.ts-icon-shop-shop-remove {
  &:before {
    content: $ts-icon-shop-shop-remove;

  }
}
.ts-icon-shop-shop-add {
  &:before {
    content: $ts-icon-shop-shop-add;

  }
}
.ts-icon-shop-shopping-bag {
  &:before {
    content: $ts-icon-shop-shopping-bag;

  }
}
.ts-icon-shop-shopping-cart {
  &:before {
    content: $ts-icon-shop-shopping-cart;

  }
}
.ts-icon-social-Telegram {
  &:before {
    content: $ts-icon-social-Telegram;

  }
}
.ts-icon-currency-Usd {
  &:before {
    content: $ts-icon-currency-Usd;

  }
}
.ts-icon-currency-Euro {
  &:before {
    content: $ts-icon-currency-Euro;

  }
}
.ts-icon-currency-Ruble {
  &:before {
    content: $ts-icon-currency-Ruble;

  }
}
.ts-icon-security-Qr {
  &:before {
    content: $ts-icon-security-Qr;
  }
}
.ts-icon-security-password-check {
  &:before {
    content: $ts-icon-security-password-check;

  }
}
.ts-icon-security-shield-search {
  &:before {
    content: $ts-icon-security-shield-search;

  }
}
.ts-icon-security-shield-slash {
  &:before {
    content: $ts-icon-security-shield-slash;

  }
}
.ts-icon-security-shield-tick {
  &:before {
    content: $ts-icon-security-shield-tick;

  }
}
.ts-icon-security-shield-security {
  &:before {
    content: $ts-icon-security-shield-security;

  }
}
.ts-icon-security-eye-slash {
  &:before {
    content: $ts-icon-security-eye-slash;

  }
}
.ts-icon-security-shield-cross {
  &:before {
    content: $ts-icon-security-shield-cross;

  }
}
.ts-icon-security-scan {
  &:before {
    content: $ts-icon-security-scan;

  }
}
.ts-icon-security-shield {
  &:before {
    content: $ts-icon-security-shield;

  }
}
.ts-icon-security-scanning {
  &:before {
    content: $ts-icon-security-scanning;

  }
}
.ts-icon-security-scan-barcode {
  &:before {
    content: $ts-icon-security-scan-barcode;

  }
}
.ts-icon-security-eye {
  &:before {
    content: $ts-icon-security-eye;

  }
}
.ts-icon-security-lock-slash {
  &:before {
    content: $ts-icon-security-lock-slash;

  }
}
.ts-icon-security-unlock {
  &:before {
    content: $ts-icon-security-unlock;

  }
}
.ts-icon-security-lock {
  &:before {
    content: $ts-icon-security-lock;

  }
}
.ts-icon-security-check {
  &:before {
    content: $ts-icon-security-check;

  }
}
.ts-icon-security-key-square {
  &:before {
    content: $ts-icon-security-key-square;

  }
}
.ts-icon-security-key {
  &:before {
    content: $ts-icon-security-key;

  }
}
.ts-icon-security-security {
  &:before {
    content: $ts-icon-security-security;

  }
}
.ts-icon-security-security-safe {
  &:before {
    content: $ts-icon-security-security-safe;

  }
}
.ts-icon-security-radar {
  &:before {
    content: $ts-icon-security-radar;

  }
}
.ts-icon-security-alarm {
  &:before {
    content: $ts-icon-security-alarm;

  }
}
.ts-icon-security-lock-circle {
  &:before {
    content: $ts-icon-security-lock-circle;

  }
}
.ts-icon-security-lock2 {
  &:before {
    content: $ts-icon-security-lock2;

  }
}
.ts-icon-security-security-user {
  &:before {
    content: $ts-icon-security-security-user;

  }
}
.ts-icon-security-scanner {
  &:before {
    content: $ts-icon-security-scanner;

  }
}
.ts-icon-security-finger-cricle {
  &:before {
    content: $ts-icon-security-finger-cricle;

  }
}
.ts-icon-security-finger-scan {
  &:before {
    content: $ts-icon-security-finger-scan;

  }
}
.ts-icon-time-Auto {
  &:before {
    content: $ts-icon-time-Auto;
  }
}
.ts-icon-time-calendar2 {
  &:before {
    content: $ts-icon-time-calendar2;

  }
}
.ts-icon-time-timer-start {
  &:before {
    content: $ts-icon-time-timer-start;

  }
}
.ts-icon-time-timer-pause {
  &:before {
    content: $ts-icon-time-timer-pause;

  }
}
.ts-icon-time-timer2 {
  &:before {
    content: $ts-icon-time-timer2;

  }
}
.ts-icon-time-clock {
  &:before {
    content: $ts-icon-time-clock;

  }
}
.ts-icon-time-timer {
  &:before {
    content: $ts-icon-time-timer;

  }
}
.ts-icon-time-calendar-circle {
  &:before {
    content: $ts-icon-time-calendar-circle;

  }
}
.ts-icon-time-calendar-2 {
  &:before {
    content: $ts-icon-time-calendar-2;

  }
}
.ts-icon-time-calendar-edit {
  &:before {
    content: $ts-icon-time-calendar-edit;

  }
}
.ts-icon-time-calendar-add {
  &:before {
    content: $ts-icon-time-calendar-add;

  }
}
.ts-icon-time-calendar-remove {
  &:before {
    content: $ts-icon-time-calendar-remove;

  }
}
.ts-icon-time-calendar-search {
  &:before {
    content: $ts-icon-time-calendar-search;

  }
}
.ts-icon-time-calendar-tick {
  &:before {
    content: $ts-icon-time-calendar-tick;

  }
}
.ts-icon-time-calendar {
  &:before {
    content: $ts-icon-time-calendar;

  }
}
.ts-icon-time-security-time {
  &:before {
    content: $ts-icon-time-security-time;

  }
}
.ts-icon-document-note-add {
  &:before {
    content: $ts-icon-document-note-add;

  }
}
.ts-icon-document-note2 {
  &:before {
    content: $ts-icon-document-note2;

  }
}
.ts-icon-document-stickynote {
  &:before {
    content: $ts-icon-document-stickynote;

  }
}
.ts-icon-document-note-remove {
  &:before {
    content: $ts-icon-document-note-remove;

  }
}
.ts-icon-document-task {
  &:before {
    content: $ts-icon-document-task;

  }
}
.ts-icon-document-clipboard-import {
  &:before {
    content: $ts-icon-document-clipboard-import;

  }
}
.ts-icon-document-clipboard-export {
  &:before {
    content: $ts-icon-document-clipboard-export;

  }
}
.ts-icon-document-clipboard-close {
  &:before {
    content: $ts-icon-document-clipboard-close;

  }
}
.ts-icon-document-clipboard-tick {
  &:before {
    content: $ts-icon-document-clipboard-tick;

  }
}
.ts-icon-document-clipboard-text {
  &:before {
    content: $ts-icon-document-clipboard-text;

  }
}
.ts-icon-document-document-upload {
  &:before {
    content: $ts-icon-document-document-upload;

  }
}
.ts-icon-document-task-square {
  &:before {
    content: $ts-icon-document-task-square;

  }
}
.ts-icon-document-document-previous {
  &:before {
    content: $ts-icon-document-document-previous;

  }
}
.ts-icon-document-document-favorite {
  &:before {
    content: $ts-icon-document-document-favorite;

  }
}
.ts-icon-document-edit {
  &:before {
    content: $ts-icon-document-edit;

  }
}
.ts-icon-document-document-forward {
  &:before {
    content: $ts-icon-document-document-forward;

  }
}
.ts-icon-document-document {
  &:before {
    content: $ts-icon-document-document;

  }
}
.ts-icon-document-document-download {
  &:before {
    content: $ts-icon-document-document-download;

  }
}
.ts-icon-document-document-text {
  &:before {
    content: $ts-icon-document-document-text;

  }
}
.ts-icon-document-menu-board {
  &:before {
    content: $ts-icon-document-menu-board;

  }
}
.ts-icon-document-note-favorite {
  &:before {
    content: $ts-icon-document-note-favorite;

  }
}
.ts-icon-document-note-text {
  &:before {
    content: $ts-icon-document-note-text;

  }
}
.ts-icon-document-archive-book {
  &:before {
    content: $ts-icon-document-archive-book;

  }
}
.ts-icon-document-document-filter {
  &:before {
    content: $ts-icon-document-document-filter;

  }
}
.ts-icon-document-bill {
  &:before {
    content: $ts-icon-document-bill;

  }
}
.ts-icon-document-creative-commons {
  &:before {
    content: $ts-icon-document-creative-commons;

  }
}
.ts-icon-document-copyright {
  &:before {
    content: $ts-icon-document-copyright;

  }
}
.ts-icon-document-document-like {
  &:before {
    content: $ts-icon-document-document-like;

  }
}
.ts-icon-document-document-text2 {
  &:before {
    content: $ts-icon-document-document-text2;

  }
}
.ts-icon-document-note {
  &:before {
    content: $ts-icon-document-note;

  }
}
.ts-icon-document-document-cloud {
  &:before {
    content: $ts-icon-document-document-cloud;

  }
}
.ts-icon-document-document-normal {
  &:before {
    content: $ts-icon-document-document-normal;

  }
}
.ts-icon-document-document-sketch2 {
  &:before {
    content: $ts-icon-document-document-sketch2;

  }
}
.ts-icon-files-Load_list_alt {
  &:before {
    content: $ts-icon-files-Load_list_alt;

  }
}
.ts-icon-files-Load_list {
  &:before {
    content: $ts-icon-files-Load_list;

  }
}
.ts-icon-files-File_dock_search {
  &:before {
    content: $ts-icon-files-File_dock_search;

  }
}
.ts-icon-files-Blank_alt {
  &:before {
    content: $ts-icon-files-Blank_alt;

  }
}
.ts-icon-files-Blank {
  &:before {
    content: $ts-icon-files-Blank;

  }
}
.ts-icon-files-File_dock_add {
  &:before {
    content: $ts-icon-files-File_dock_add;

  }
}
.ts-icon-files-File_dock {
  &:before {
    content: $ts-icon-files-File_dock;

  }
}
.ts-icon-files-File {
  &:before {
    content: $ts-icon-files-File;

  }
}
.ts-icon-files-folder-cloud {
  &:before {
    content: $ts-icon-files-folder-cloud;

  }
}
.ts-icon-files-folder-open {
  &:before {
    content: $ts-icon-files-folder-open;

  }
}
.ts-icon-files-folder-2 {
  &:before {
    content: $ts-icon-files-folder-2;

  }
}
.ts-icon-files-folder-favorite {
  &:before {
    content: $ts-icon-files-folder-favorite;

  }
}
.ts-icon-files-folder-minus {
  &:before {
    content: $ts-icon-files-folder-minus;

  }
}
.ts-icon-files-folder-add {
  &:before {
    content: $ts-icon-files-folder-add;

  }
}
.ts-icon-files-folder-cross {
  &:before {
    content: $ts-icon-files-folder-cross;

  }
}
.ts-icon-files-folder {
  &:before {
    content: $ts-icon-files-folder;

  }
}
.ts-icon-grid-maximize-4 {
  &:before {
    content: $ts-icon-grid-maximize-4;

  }
}
.ts-icon-grid-maximize-3 {
  &:before {
    content: $ts-icon-grid-maximize-3;

  }
}
.ts-icon-grid-maximize-2 {
  &:before {
    content: $ts-icon-grid-maximize-2;

  }
}
.ts-icon-grid-maximize {
  &:before {
    content: $ts-icon-grid-maximize;

  }
}
.ts-icon-grid-align-bottom {
  &:before {
    content: $ts-icon-grid-align-bottom;

  }
}
.ts-icon-grid-convert-shape-2 {
  &:before {
    content: $ts-icon-grid-convert-shape-2;

  }
}
.ts-icon-grid-align-top {
  &:before {
    content: $ts-icon-grid-align-top;

  }
}
.ts-icon-grid-align-right {
  &:before {
    content: $ts-icon-grid-align-right;

  }
}
.ts-icon-grid-maximize-21 {
  &:before {
    content: $ts-icon-grid-maximize-21;

  }
}
.ts-icon-grid-align-left {
  &:before {
    content: $ts-icon-grid-align-left;

  }
}
.ts-icon-grid-align-horizontally {
  &:before {
    content: $ts-icon-grid-align-horizontally;

  }
}
.ts-icon-grid-element-equal {
  &:before {
    content: $ts-icon-grid-element-equal;

  }
}
.ts-icon-grid-element-plus {
  &:before {
    content: $ts-icon-grid-element-plus;

  }
}
.ts-icon-grid-element-4 {
  &:before {
    content: $ts-icon-grid-element-4;

  }
}
.ts-icon-grid-element-3 {
  &:before {
    content: $ts-icon-grid-element-3;

  }
}
.ts-icon-grid-element-2 {
  &:before {
    content: $ts-icon-grid-element-2;

  }
}
.ts-icon-grid-element-1 {
  &:before {
    content: $ts-icon-grid-element-1;

  }
}
.ts-icon-grid-grid-1 {
  &:before {
    content: $ts-icon-grid-grid-1;

  }
}
.ts-icon-grid-grid-8 {
  &:before {
    content: $ts-icon-grid-grid-8;

  }
}
.ts-icon-grid-grid-5 {
  &:before {
    content: $ts-icon-grid-grid-5;

  }
}
.ts-icon-grid-format-circle {
  &:before {
    content: $ts-icon-grid-format-circle;

  }
}
.ts-icon-grid-kanban {
  &:before {
    content: $ts-icon-grid-kanban;

  }
}
.ts-icon-grid-grid-9 {
  &:before {
    content: $ts-icon-grid-grid-9;

  }
}
.ts-icon-grid-slider-horizontal-2 {
  &:before {
    content: $ts-icon-grid-slider-horizontal-2;

  }
}
.ts-icon-grid-align-vertically {
  &:before {
    content: $ts-icon-grid-align-vertically;

  }
}
.ts-icon-grid-slider-vertical-2 {
  &:before {
    content: $ts-icon-grid-slider-vertical-2;

  }
}
.ts-icon-grid-grid-2 {
  &:before {
    content: $ts-icon-grid-grid-2;

  }
}
.ts-icon-grid-convert-shape {
  &:before {
    content: $ts-icon-grid-convert-shape;

  }
}
.ts-icon-grid-3square {
  &:before {
    content: $ts-icon-grid-3square;

  }
}
.ts-icon-grid-slider-vertical {
  &:before {
    content: $ts-icon-grid-slider-vertical;

  }
}
.ts-icon-grid-grid-6 {
  &:before {
    content: $ts-icon-grid-grid-6;

  }
}
.ts-icon-grid-crop {
  &:before {
    content: $ts-icon-grid-crop;

  }
}
.ts-icon-grid-slider-horizontal {
  &:before {
    content: $ts-icon-grid-slider-horizontal;

  }
}
.ts-icon-grid-grid-3 {
  &:before {
    content: $ts-icon-grid-grid-3;

  }
}
.ts-icon-grid-rotate-left {
  &:before {
    content: $ts-icon-grid-rotate-left;

  }
}
.ts-icon-grid-row-vertical {
  &:before {
    content: $ts-icon-grid-row-vertical;

  }
}
.ts-icon-grid-grid-7 {
  &:before {
    content: $ts-icon-grid-grid-7;

  }
}
.ts-icon-grid-format-square {
  &:before {
    content: $ts-icon-grid-format-square;

  }
}
.ts-icon-grid-fatrows {
  &:before {
    content: $ts-icon-grid-fatrows;

  }
}
.ts-icon-grid-grid-4 {
  &:before {
    content: $ts-icon-grid-grid-4;

  }
}
.ts-icon-grid-rotate-right {
  &:before {
    content: $ts-icon-grid-rotate-right;

  }
}
.ts-icon-grid-row-horizontal {
  &:before {
    content: $ts-icon-grid-row-horizontal;

  }
}
.ts-icon-grid-grid-eraser {
  &:before {
    content: $ts-icon-grid-grid-eraser;

  }
}
.ts-icon-grid-grid-edit {
  &:before {
    content: $ts-icon-grid-grid-edit;

  }
}
.ts-icon-grid-grid-lock3 {
  &:before {
    content: $ts-icon-grid-grid-lock3;

  }
}
.ts-icon-search-search-normal_alt {
  &:before {
    content: $ts-icon-search-search-normal_alt;

  }
}
.ts-icon-search-search-status {
  &:before {
    content: $ts-icon-search-search-status;

  }
}
.ts-icon-search-search-favorite_alt {
  &:before {
    content: $ts-icon-search-search-favorite_alt;

  }
}
.ts-icon-search-search-zoom-out_alt {
  &:before {
    content: $ts-icon-search-search-zoom-out_alt;

  }
}
.ts-icon-search-search-status_alt {
  &:before {
    content: $ts-icon-search-search-status_alt;

  }
}
.ts-icon-search-search-zoom-in {
  &:before {
    content: $ts-icon-search-search-zoom-in;

  }
}
.ts-icon-search-search-normal {
  &:before {
    content: $ts-icon-search-search-normal;

  }
}
.ts-icon-search-search-favorite {
  &:before {
    content: $ts-icon-search-search-favorite;

  }
}
.ts-icon-search-search-zoom-in_alt {
  &:before {
    content: $ts-icon-search-search-zoom-in_alt;

  }
}
.ts-icon-search-search-zoom-out {
  &:before {
    content: $ts-icon-search-search-zoom-out;

  }
}
.ts-icon-menu-Meatballs_menu {
  &:before {
    content: $ts-icon-menu-Meatballs_menu;

  }
}
.ts-icon-menu-Setting_vert {
  &:before {
    content: $ts-icon-menu-Setting_vert;

  }
}
.ts-icon-menu-Move {
  &:before {
    content: $ts-icon-menu-Move;

  }
}
.ts-icon-menu-Menu3 {
  &:before {
    content: $ts-icon-menu-Menu3;

  }
}
.ts-icon-menu-Status_list {
  &:before {
    content: $ts-icon-menu-Status_list;

  }
}
.ts-icon-menu-Status {
  &:before {
    content: $ts-icon-menu-Status;

  }
}
.ts-icon-menu-Menu2 {
  &:before {
    content: $ts-icon-menu-Menu2;

  }
}
.ts-icon-menu-Widget_alt {
  &:before {
    content: $ts-icon-menu-Widget_alt;

  }
}
.ts-icon-menu-Widget {
  &:before {
    content: $ts-icon-menu-Widget;

  }
}
.ts-icon-menu-Stat {
  &:before {
    content: $ts-icon-menu-Stat;

  }
}
.ts-icon-menu-darhboard_alt {
  &:before {
    content: $ts-icon-menu-darhboard_alt;

  }
}
.ts-icon-menu-darhboard {
  &:before {
    content: $ts-icon-menu-darhboard;

  }
}
.ts-icon-menu-Sort {
  &:before {
    content: $ts-icon-menu-Sort;

  }
}
.ts-icon-settings-category-2 {
  &:before {
    content: $ts-icon-settings-category-2;

  }
}
.ts-icon-settings-toggle-on {
  &:before {
    content: $ts-icon-settings-toggle-on;

  }
}
.ts-icon-settings-toggle-off {
  &:before {
    content: $ts-icon-settings-toggle-off;

  }
}
.ts-icon-settings-more-2 {
  &:before {
    content: $ts-icon-settings-more-2;

  }
}
.ts-icon-settings-setting-5 {
  &:before {
    content: $ts-icon-settings-setting-5;

  }
}
.ts-icon-settings-setting-3 {
  &:before {
    content: $ts-icon-settings-setting-3;

  }
}
.ts-icon-settings-setting-2 {
  &:before {
    content: $ts-icon-settings-setting-2;

  }
}
.ts-icon-settings-more {
  &:before {
    content: $ts-icon-settings-more;

  }
}
.ts-icon-settings-setting {
  &:before {
    content: $ts-icon-settings-setting;

  }
}
.ts-icon-settings-setting-4 {
  &:before {
    content: $ts-icon-settings-setting-4;

  }
}
.ts-icon-settings-category {
  &:before {
    content: $ts-icon-settings-category;

  }
}
.ts-icon-settings-settings {
  &:before {
    content: $ts-icon-settings-settings;

  }
}
.ts-icon-settings-candle {
  &:before {
    content: $ts-icon-settings-candle;

  }
}
.ts-icon-settings-candle-2 {
  &:before {
    content: $ts-icon-settings-candle-2;

  }
}
.ts-icon-settings-menu {
  &:before {
    content: $ts-icon-settings-menu;

  }
}
.ts-icon-notifications-Alarm_noty {
  &:before {
    content: $ts-icon-notifications-Alarm_noty;

  }
}
.ts-icon-notifications-Alarm {
  &:before {
    content: $ts-icon-notifications-Alarm;

  }
}
.ts-icon-notifications-lamp-charge {
  &:before {
    content: $ts-icon-notifications-lamp-charge;

  }
}
.ts-icon-notifications-lamp-on {
  &:before {
    content: $ts-icon-notifications-lamp-on;

  }
}
.ts-icon-notifications-lamp-slash {
  &:before {
    content: $ts-icon-notifications-lamp-slash;

  }
}
.ts-icon-notifications-notification-circle {
  &:before {
    content: $ts-icon-notifications-notification-circle;

  }
}
.ts-icon-notifications-notification-favorite {
  &:before {
    content: $ts-icon-notifications-notification-favorite;

  }
}
.ts-icon-notifications-notification {
  &:before {
    content: $ts-icon-notifications-notification;

  }
}
.ts-icon-notifications-notification-status {
  &:before {
    content: $ts-icon-notifications-notification-status;

  }
}
.ts-icon-actions-minus-square {
  &:before {
    content: $ts-icon-actions-minus-square;
  }
}
.ts-icon-actions-Done_all_alt_round {
  &:before {
    content: $ts-icon-actions-Done_all_alt_round;

  }
}
.ts-icon-actions-warning {
  &:before {
    content: $ts-icon-actions-warning;

  }
}
.ts-icon-actions-Done {
  &:before {
    content: $ts-icon-actions-Done;

  }
}
.ts-icon-actions-minus_cicrcle {
  &:before {
    content: $ts-icon-actions-minus_cicrcle;

  }
}
.ts-icon-actions-info-circle {
  &:before {
    content: $ts-icon-actions-info-circle;

  }
}
.ts-icon-actions-Add_square {
  &:before {
    content: $ts-icon-actions-Add_square;

  }
}
.ts-icon-actions-Done_ring_round {
  &:before {
    content: $ts-icon-actions-Done_ring_round;

  }
}
.ts-icon-actions-group {
  &:before {
    content: $ts-icon-actions-group;

  }
}
.ts-icon-actions-Close_ring {
  &:before {
    content: $ts-icon-actions-Close_ring;

  }
}
.ts-icon-actions-Add_ring {
  &:before {
    content: $ts-icon-actions-Add_ring;

  }
}
.ts-icon-actions-more-circle {
  &:before {
    content: $ts-icon-actions-more-circle;

  }
}
.ts-icon-actions-Check_ring {
  &:before {
    content: $ts-icon-actions-Check_ring;

  }
}
.ts-icon-actions-minus {
  &:before {
    content: $ts-icon-actions-minus;

  }
}
.ts-icon-actions-Close {
  &:before {
    content: $ts-icon-actions-Close;

  }
}
.ts-icon-actions-Add {
  &:before {
    content: $ts-icon-actions-Add;

  }
}
.ts-icon-actions-hammer {
  &:before {
    content: $ts-icon-actions-hammer;

  }
}
.ts-icon-actions-save {
  &:before {
    content: $ts-icon-actions-save;

  }
}
.ts-icon-actions-filter-square {
  &:before {
    content: $ts-icon-actions-filter-square;

  }
}
.ts-icon-actions-filter {
  &:before {
    content: $ts-icon-actions-filter;

  }
}
.ts-icon-actions-filter-tick {
  &:before {
    content: $ts-icon-actions-filter-tick;

  }
}
.ts-icon-actions-filter-edit {
  &:before {
    content: $ts-icon-actions-filter-edit;

  }
}
.ts-icon-actions-filter-search {
  &:before {
    content: $ts-icon-actions-filter-search;

  }
}
.ts-icon-actions-filter-add {
  &:before {
    content: $ts-icon-actions-filter-add;

  }
}
.ts-icon-actions-filter-remove {
  &:before {
    content: $ts-icon-actions-filter-remove;

  }
}
.ts-icon-actions-Edit {
  &:before {
    content: $ts-icon-actions-Edit;

  }
}
.ts-icon-actions-clip {
  &:before {
    content: $ts-icon-actions-clip;

  }
}
.ts-icon-actions-Info {
  &:before {
    content: $ts-icon-actions-Info;

  }
}
.ts-icon-actions-Question {
  &:before {
    content: $ts-icon-actions-Question;

  }
}
.ts-icon-actions-Flag_alt {
  &:before {
    content: $ts-icon-actions-Flag_alt;

  }
}
.ts-icon-actions-Flag {
  &:before {
    content: $ts-icon-actions-Flag;

  }
}
.ts-icon-actions-Bookmark {
  &:before {
    content: $ts-icon-actions-Bookmark;

  }
}
.ts-icon-actions-Star {
  &:before {
    content: $ts-icon-actions-Star;

  }
}
.ts-icon-actions-Favorite {
  &:before {
    content: $ts-icon-actions-Favorite;

  }
}
.ts-icon-actions-Flag_finish {
  &:before {
    content: $ts-icon-actions-Flag_finish;

  }
}
.ts-icon-actions-Pined {
  &:before {
    content: $ts-icon-actions-Pined;

  }
}
.ts-icon-actions-Pin {
  &:before {
    content: $ts-icon-actions-Pin;

  }
}
.ts-icon-actions-Progress {
  &:before {
    content: $ts-icon-actions-Progress;

  }
}
.ts-icon-actions-archive-tick {
  &:before {
    content: $ts-icon-actions-archive-tick;

  }
}
.ts-icon-actions-archive-slash {
  &:before {
    content: $ts-icon-actions-archive-slash;

  }
}
.ts-icon-actions-archive-minus {
  &:before {
    content: $ts-icon-actions-archive-minus;

  }
}
.ts-icon-actions-archive-add {
  &:before {
    content: $ts-icon-actions-archive-add;

  }
}
.ts-icon-actions-frame {
  &:before {
    content: $ts-icon-actions-frame;

  }
}
.ts-icon-actions-book-saved {
  &:before {
    content: $ts-icon-actions-book-saved;

  }
}
.ts-icon-actions-save-remove {
  &:before {
    content: $ts-icon-actions-save-remove;

  }
}
.ts-icon-actions-save-minus {
  &:before {
    content: $ts-icon-actions-save-minus;

  }
}
.ts-icon-actions-save-add {
  &:before {
    content: $ts-icon-actions-save-add;

  }
}
.ts-icon-actions-save-2 {
  &:before {
    content: $ts-icon-actions-save-2;

  }
}
.ts-icon-actions-book-square {
  &:before {
    content: $ts-icon-actions-book-square;

  }
}
.ts-icon-actions-receipt-square {
  &:before {
    content: $ts-icon-actions-receipt-square;

  }
}
.ts-icon-actions-Copy_alt {
  &:before {
    content: $ts-icon-actions-Copy_alt;

  }
}
.ts-icon-actions-Copy {
  &:before {
    content: $ts-icon-actions-Copy;

  }
}
.ts-icon-actions-Trash {
  &:before {
    content: $ts-icon-actions-Trash;

  }
}
.ts-icon-arrow-arrow-minus {
  &:before {
    content: $ts-icon-arrow-arrow-minus;

  }
}
.ts-icon-arrow-arrow-plus {
  &:before {
    content: $ts-icon-arrow-arrow-plus;

  }
}
.ts-icon-arrow-redo-2 {
  &:before {
    content: $ts-icon-arrow-redo-2;

  }
}
.ts-icon-arrow-Icon92 {
  &:before {
    content: $ts-icon-arrow-Icon92;

  }
}
.ts-icon-arrow-Expand_down_stop {
  &:before {
    content: $ts-icon-arrow-Expand_down_stop;

  }
}
.ts-icon-arrow-Expand_top_stop {
  &:before {
    content: $ts-icon-arrow-Expand_top_stop;

  }
}
.ts-icon-arrow-Expand_right_stop {
  &:before {
    content: $ts-icon-arrow-Expand_right_stop;

  }
}
.ts-icon-arrow-Expand_left_stop {
  &:before {
    content: $ts-icon-arrow-Expand_left_stop;

  }
}
.ts-icon-arrow-Expand_down_double {
  &:before {
    content: $ts-icon-arrow-Expand_down_double;

  }
}
.ts-icon-arrow-Expand_up_double {
  &:before {
    content: $ts-icon-arrow-Expand_up_double;

  }
}
.ts-icon-arrow-Expand_right_double {
  &:before {
    content: $ts-icon-arrow-Expand_right_double;

  }
}
.ts-icon-arrow-Expand_left_double {
  &:before {
    content: $ts-icon-arrow-Expand_left_double;

  }
}
.ts-icon-arrow-Download_cloud {
  &:before {
    content: $ts-icon-arrow-Download_cloud;

  }
}
.ts-icon-arrow-Upload_cloud {
  &:before {
    content: $ts-icon-arrow-Upload_cloud;

  }
}
.ts-icon-arrow-Export {
  &:before {
    content: $ts-icon-arrow-Export;

  }
}
.ts-icon-arrow-Import {
  &:before {
    content: $ts-icon-arrow-Import;

  }
}
.ts-icon-arrow-Download_circle {
  &:before {
    content: $ts-icon-arrow-Download_circle;

  }
}
.ts-icon-arrow-Load_circle {
  &:before {
    content: $ts-icon-arrow-Load_circle;

  }
}
.ts-icon-arrow-Move_2 {
  &:before {
    content: $ts-icon-arrow-Move_2;

  }
}
.ts-icon-arrow-Upload {
  &:before {
    content: $ts-icon-arrow-Upload;

  }
}
.ts-icon-arrow-Download {
  &:before {
    content: $ts-icon-arrow-Download;

  }
}
.ts-icon-arrow-Fluid {
  &:before {
    content: $ts-icon-arrow-Fluid;

  }
}
.ts-icon-arrow-Collapse {
  &:before {
    content: $ts-icon-arrow-Collapse;

  }
}
.ts-icon-arrow-Vertical_switch_long {
  &:before {
    content: $ts-icon-arrow-Vertical_switch_long;

  }
}
.ts-icon-arrow-Vertical_switch_alt {
  &:before {
    content: $ts-icon-arrow-Vertical_switch_alt;

  }
}
.ts-icon-arrow-Vertical_switch {
  &:before {
    content: $ts-icon-arrow-Vertical_switch;

  }
}
.ts-icon-arrow-Expand_down {
  &:before {
    content: $ts-icon-arrow-Expand_down;

  }
}
.ts-icon-arrow-Expand_up {
  &:before {
    content: $ts-icon-arrow-Expand_up;

  }
}
.ts-icon-arrow-Expand_right {
  &:before {
    content: $ts-icon-arrow-Expand_right;

  }
}
.ts-icon-arrow-Expand_left {
  &:before {
    content: $ts-icon-arrow-Expand_left;

  }
}
.ts-icon-arrow-Move_alt {
  &:before {
    content: $ts-icon-arrow-Move_alt;

  }
}
.ts-icon-arrow-Move {
  &:before {
    content: $ts-icon-arrow-Move;

  }
}
.ts-icon-arrow-Full_Screen_Corner {
  &:before {
    content: $ts-icon-arrow-Full_Screen_Corner;

  }
}
.ts-icon-arrow-Full_alt {
  &:before {
    content: $ts-icon-arrow-Full_alt;

  }
}
.ts-icon-arrow-Reduce {
  &:before {
    content: $ts-icon-arrow-Reduce;

  }
}
.ts-icon-arrow-Full {
  &:before {
    content: $ts-icon-arrow-Full;

  }
}
.ts-icon-arrow-Arrow_left_stop {
  &:before {
    content: $ts-icon-arrow-Arrow_left_stop;

  }
}
.ts-icon-arrow-Arrow_right_stop {
  &:before {
    content: $ts-icon-arrow-Arrow_right_stop;

  }
}
.ts-icon-arrow-export-3 {
  &:before {
    content: $ts-icon-arrow-export-3;

  }
}
.ts-icon-arrow-refresh-2 {
  &:before {
    content: $ts-icon-arrow-refresh-2;

  }
}
.ts-icon-arrow-refresh-3 {
  &:before {
    content: $ts-icon-arrow-refresh-3;

  }
}
.ts-icon-arrow-arrow-right-2 {
  &:before {
    content: $ts-icon-arrow-arrow-right-2;

  }
}
.ts-icon-arrow-import-3 {
  &:before {
    content: $ts-icon-arrow-import-3;

  }
}
.ts-icon-arrow-logout {
  &:before {
    content: $ts-icon-arrow-logout;

  }
}
.ts-icon-arrow-export-4 {
  &:before {
    content: $ts-icon-arrow-export-4;

  }
}
.ts-icon-arrow-import-2 {
  &:before {
    content: $ts-icon-arrow-import-2;

  }
}
.ts-icon-arrow-login {
  &:before {
    content: $ts-icon-arrow-login;

  }
}
.ts-icon-arrow-arrow-bottom-2 {
  &:before {
    content: $ts-icon-arrow-arrow-bottom-2;

  }
}
.ts-icon-arrow-logout-2 {
  &:before {
    content: $ts-icon-arrow-logout-2;

  }
}
.ts-icon-arrow-vuesax {
  &:before {
    content: $ts-icon-arrow-vuesax;

  }
}
.ts-icon-arrow-login-2 {
  &:before {
    content: $ts-icon-arrow-login-2;

  }
}
.ts-icon-arrow-arrow-up-2 {
  &:before {
    content: $ts-icon-arrow-arrow-up-2;

  }
}
.ts-icon-arrow-convert {
  &:before {
    content: $ts-icon-arrow-convert;

  }
}
.ts-icon-arrow-rotate-right {
  &:before {
    content: $ts-icon-arrow-rotate-right;

  }
}
.ts-icon-arrow-rotate-left {
  &:before {
    content: $ts-icon-arrow-rotate-left;

  }
}
.ts-icon-arrow-arrow-down-3 {
  &:before {
    content: $ts-icon-arrow-arrow-down-3;

  }
}
.ts-icon-arrow-arrow-right-3 {
  &:before {
    content: $ts-icon-arrow-arrow-right-3;

  }
}
.ts-icon-arrow-arrow-left-3 {
  &:before {
    content: $ts-icon-arrow-arrow-left-3;

  }
}
.ts-icon-arrow-redo {
  &:before {
    content: $ts-icon-arrow-redo;

  }
}
.ts-icon-arrow-arrow-swap-horizontal {
  &:before {
    content: $ts-icon-arrow-arrow-swap-horizontal;

  }
}
.ts-icon-arrow-undo {
  &:before {
    content: $ts-icon-arrow-undo;

  }
}
.ts-icon-arrow-arrow-up-3 {
  &:before {
    content: $ts-icon-arrow-arrow-up-3;

  }
}
.ts-icon-arrow-arrange {
  &:before {
    content: $ts-icon-arrow-arrange;

  }
}
.ts-icon-arrow-arrow-down {
  &:before {
    content: $ts-icon-arrow-arrow-down;

  }
}
.ts-icon-arrow-arrow-circle-up {
  &:before {
    content: $ts-icon-arrow-arrow-circle-up;

  }
}
.ts-icon-arrow-arrow-circle-down {
  &:before {
    content: $ts-icon-arrow-arrow-circle-down;

  }
}
.ts-icon-arrow-arrow-circle-right {
  &:before {
    content: $ts-icon-arrow-arrow-circle-right;

  }
}
.ts-icon-arrow-arrow-circle-left {
  &:before {
    content: $ts-icon-arrow-arrow-circle-left;

  }
}
.ts-icon-arrow-arrow-up {
  &:before {
    content: $ts-icon-arrow-arrow-up;

  }
}
.ts-icon-arrow-arrow-right {
  &:before {
    content: $ts-icon-arrow-arrow-right;

  }
}
.ts-icon-arrow-arrow-square-right {
  &:before {
    content: $ts-icon-arrow-arrow-square-right;

  }
}
.ts-icon-arrow-arrow-square-left {
  &:before {
    content: $ts-icon-arrow-arrow-square-left;

  }
}
.ts-icon-arrow-arrow-square-up {
  &:before {
    content: $ts-icon-arrow-arrow-square-up;

  }
}
.ts-icon-arrow-arrow-left {
  &:before {
    content: $ts-icon-arrow-arrow-left;

  }
}
.ts-icon-arrow-arrow-square-down {
  &:before {
    content: $ts-icon-arrow-arrow-square-down;

  }
}
.ts-icon-arrow-refresh-circle {
  &:before {
    content: $ts-icon-arrow-refresh-circle;

  }
}
.ts-icon-arrow-repeat-circle {
  &:before {
    content: $ts-icon-arrow-repeat-circle;

  }
}
.ts-icon-arrow-repeat {
  &:before {
    content: $ts-icon-arrow-repeat;

  }
}
.ts-icon-arrow-arrange-circle-2 {
  &:before {
    content: $ts-icon-arrow-arrange-circle-2;

  }
}
.ts-icon-arrow-arrange-circle {
  &:before {
    content: $ts-icon-arrow-arrange-circle;

  }
}
.ts-icon-arrow-receive-square-2 {
  &:before {
    content: $ts-icon-arrow-receive-square-2;

  }
}
.ts-icon-arrow-send-sqaure-2 {
  &:before {
    content: $ts-icon-arrow-send-sqaure-2;

  }
}
.ts-icon-arrow-send-square {
  &:before {
    content: $ts-icon-arrow-send-square;

  }
}
.ts-icon-arrow-refresh-square-2 {
  &:before {
    content: $ts-icon-arrow-refresh-square-2;

  }
}
.ts-icon-arrow-refresh-left-square {
  &:before {
    content: $ts-icon-arrow-refresh-left-square;

  }
}
.ts-icon-arrow-refresh-right-square {
  &:before {
    content: $ts-icon-arrow-refresh-right-square;

  }
}
.ts-icon-arrow-forward-square {
  &:before {
    content: $ts-icon-arrow-forward-square;

  }
}
.ts-icon-arrow-receive-square {
  &:before {
    content: $ts-icon-arrow-receive-square;

  }
}
.ts-icon-arrow-back-square {
  &:before {
    content: $ts-icon-arrow-back-square;

  }
}
.ts-icon-arrow-arrange-square-2 {
  &:before {
    content: $ts-icon-arrow-arrange-square-2;

  }
}
.ts-icon-arrow-arrange-square {
  &:before {
    content: $ts-icon-arrow-arrange-square;

  }
}
.ts-icon-arrow-arrow-3 {
  &:before {
    content: $ts-icon-arrow-arrow-3;

  }
}
.ts-icon-arrow-arrow-2 {
  &:before {
    content: $ts-icon-arrow-arrow-2;

  }
}
.ts-icon-arrow-send {
  &:before {
    content: $ts-icon-arrow-send;

  }
}
.ts-icon-arrow-received {
  &:before {
    content: $ts-icon-arrow-received;

  }
}

