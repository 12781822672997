.form-switch {
  .form-check-input {
    //width: 2.5em;

    &:checked {
      &[type='checkbox'] {
        background-color: $primary;
      }
    }
  }
}

$input-border-radius: 30px;

.input-group-text {
  display: flex;
  align-items: center;
  padding: $input-group-addon-padding-y $input-group-addon-padding-x;
  @include font-size($input-font-size); // Match inputs
  font-weight: $input-group-addon-font-weight;
  line-height: $input-line-height;
  color: $input-group-addon-color;

  height: 40px;
  background-color: #f4f6fa;
  border: none;
  @include border-radius($input-border-radius);
}

.input-group > .input-group-append > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
