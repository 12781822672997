// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$topseller-main: (
  50: #e9f6f3,
  100: #c8e9e0,
  200: #a4dbcc,
  300: #7fcdb7,
  400: #63c2a7,
  500: #48b798,
  600: #41b090,
  700: #38a785,
  800: #309f7b,
  900: #21906a,
  A100: #cdffec,
  A200: #9affda,
  A400: #67ffc7,
  A700: #4dffbd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$ts-front-primary: mat.m2-define-palette($topseller-main);
$ts-front-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ts-front-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ts-front-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $ts-front-primary,
      accent: $ts-front-accent,
      warn: $ts-front-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ts-front-theme);

.auth-page {
  display: block;
  background: url(../img/login-background.svg);
  background-size: cover;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');

@import 'core/mixins/shadows';
@import 'core/mixins/utilities';

@import 'bootstrap/scss/bootstrap';

@import 'bootstrap-extended/reboot';
@import 'bootstrap-extended/forms';

@import 'core/layouts/sidebar-content';
@import 'core/list';

@import 'components/components';
@import 'components/customizer';
@import 'components/ex-component-avatar';
@import 'components/helper';
@import 'components/misc';
@import 'components/notification-sidebar';
@import 'components/responsive';
@import 'components/utilities';
@import 'components/dashboard';

@import 'ui/hint.scss';
@import 'ui/button';
@import 'ui/table';
@import 'ui/forms';
@import 'ui/layout';
@import 'ui/padding';
@import 'ui/margin';
@import 'ui/dropdown';
@import 'ui/data-list';
@import 'ui/input-file.scss';

@import 'third-party/perfect-scrollbar';

@import 'third-party/perfect-scrollbar';

//material extended
@import 'material-extended/fields.scss';
@import 'material-extended/datepicker.scss';

@import 'bootstrap-extended/modal';

//hub layout styles
@import 'core/hub/table';
@import 'core/hub/info';
@import 'core/hub/upload';
@import 'core/hub/accounting';

@import 'core/variables/app-variables';
@import 'core/themes/dark-theme';
@import 'core/themes/light-theme';
@import 'core/variables/variables2';
@import 'core/typography/styles';
@import 'icons/style';
@import 'core/colors/styles';
@import 'core/mediaQueries/styles';
@import 'core/navigation/styles';
@import 'core/layouts/styles';
::-webkit-input-placeholder {
  color: red;
}

::-moz-placeholder {
  color: red;
}

::-ms-placeholder {
  color: red;
}

::placeholder {
  color: red;
}

body {
  overflow-x: hidden;
  color: $text-primary-color;
  background-color: $background-primary-color;
}

.service-modal-video-container {
  height: 280px;

  @include breakpoint-md {
    height: 250px;
    flex: 1;
  }
}
