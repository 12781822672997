// content overlay
@import '../colors/styles';
@import '../typography/styles';
@import '../mediaQueries/styles';

.wrapper {
  &.show-overlay {
    .content-overlay {
      z-index: 16;
      // z-index: 3;
      opacity: 1;
    }
  }

  .content-overlay {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: calc(100% + 54px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black, 0.6);
    cursor: pointer;
    transition: all 0.7s;
    z-index: -1;
    visibility: visible;
  }
}

.ts-page {
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  &__content {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

$default-card-padding: 0.5rem 1rem;

.form-controls-card {
  border-radius: 0.5rem;
  background-color: $surface-primary-color;

  &__header {
    @include border-style('bottom', $separator-secondary-color);
    display: flex;

    &.break {
      display: grid;
      grid-template-columns: auto;

      @include breakpoint-md {
        display: flex;
      }

      & .breakable-actions {
        width: 100%;
        overflow: hidden;

        &:has(*) {
          @include border-style('top', $separator-secondary-color);
          padding: $default-card-padding;
        }

        @include breakpoint-md {
          padding-left: 0;
        }
      }
    }

    & .label-container {
      padding: 1.125rem 1rem;
      @include border-style('right', $separator-secondary-color);
      display: flex;
      gap: 0.5rem;
      align-items: center;
      width: fit-content;

      &.expandable {
        padding: $default-card-padding;
      }

      & .label {
        @include ts-subtitle;
        white-space: nowrap;
      }
    }

    & .actions {
      padding: $default-card-padding;
      padding-right: 0.5rem;
      flex-shrink: 0;
    }
  }

  &__content {
    padding: 1rem;
  }

  &__empty {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: var(--ts-empty-card-height, 104px);
    margin-top: -2rem;
    position: relative;

    & .ts-table__no-items {
      position: absolute;
      top: 2rem;
    }
  }
}

.ts-toggle-group {
  background-color: $surface-secondary-color;
  padding: 3px;
  border-radius: 6px;
  height: 100%;
  display: flex;

  &__button {
    flex: 1;
    border-radius: 6px;
    padding: 0.5rem 1.5rem;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    color: $text-primary-color;
    @include ts-caption-strong;
    white-space: nowrap;

    &:hover {
      color: $controls-primary-hover-color;
    }

    &.selected {
      background-color: $controls-primary-color;
      color: $text-inverse-color;
    }
  }
}
