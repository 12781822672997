@import '../../core/colors/light-palette';

.light-theme {
  /***** START controls*****/
  // Primary
  --ts-primary-color: #{map-get($emerald-light, '900')};
  --ts-primary-hover-color: #{map-get($emerald-light, '1100')};
  --ts-primary-active-color: #{map-get($emerald-light, '1200')};
  --ts-primary-focus-color: #{map-get($emerald-light, '700')};

  --ts-secondary-color: #{map-get($cyan-light, '1000')};
  --ts-secondary-hover-color: #{map-get($cyan-light, '1100')};
  --ts-secondary-active-color: #{map-get($cyan-light, '1200')};
  --ts-secondary-focus-color: #{map-get($cyan-light, '700')};

  // Error
  --ts-error-color: #{map-get($red-light, '900')};
  --ts-error-hover-color: #{map-get($red-light, '1100')};
  --ts-error-active-color: #{map-get($red-light, '1200')};
  --ts-error-focus-color: #{map-get($red-light, '700')};

  // Warning
  --ts-warning-color: #{map-get($orange-light, '900')};
  --ts-warning-hover-color: #{map-get($orange-light, '1100')};
  --ts-warning-active-color: #{map-get($orange-light, '1200')};
  --ts-warning-focus-color: #{map-get($orange-light, '700')};

  // Success
  --ts-success-color: #{map-get($green-light, '900')};
  --ts-success-hover-color: #{map-get($green-light, '1100')};
  --ts-success-active-color: #{map-get($green-light, '1200')};
  --ts-success-focus-color: #{map-get($green-light, '700')};

  // Info
  --ts-info-color: #{map-get($blue-light, '900')};
  --ts-info-hover-color: #{map-get($blue-light, '1100')};
  --ts-info-active-color: #{map-get($blue-light, '1200')};
  --ts-info-focus-color: #{map-get($blue-light, '700')};


  --ts-always-black-color: #{map-get($neutral-light, '900')};
  --ts-always-white-color: #{map-get($neutral-light, '100')};
  --ts-controls-disabled-color: #{map-get($neutral-light, '600')};
  /*****END controls*****/

  /***** START surface*****/
  --ts-surface-primary-color: #{map-get($neutral-light, '100')};
  --ts-surface-secondary-color: #{map-get($neutral-light, '200')};
  --ts-surface-accent-primary-color: #{map-get($emerald-light, '200')};
  --ts-surface-accent-secondary-color: #{map-get($cyan-light, '400')};

  --ts-surface-error-color: #{map-get($red-light, '100')};
  --ts-surface-success-color: #{map-get($green-light, '100')};
  --ts-surface-warning-color: #{map-get($orange-light, '100')};
  --ts-surface-info-color: #{map-get($blue-light, '100')};

  --ts-surface-tertiary-color: #{map-get($neutral-light, '400')};

  --ts-surface-inverse-color: #{map-get($neutral-light, '1200')};
  --ts-surface-disable-color: #{map-get($neutral-light, '400')};
  --ts-surface-light-color: #{map-get($neutral-light, '150')};
  /*****END surface*****/

  /***** START text*****/
  --ts-text-primary-color: #{map-get($neutral-light, '1100')};
  --ts-text-secondary-color: #{map-get($neutral-light, '1000')};
  --ts-text-tertiary-color: #{map-get($neutral-light, '900')};
  --ts-text-inverse-color: #{map-get($neutral-light, '100')};
  --ts-text-disabled-color: #{map-get($neutral-light, '700')};
  --ts-text-placeholder-color: #{map-get($neutral-light, '700')};
  --ts-text-disabled-color: #{map-get($neutral-light, '700')};

  --ts-text-error-color: #{map-get($red-light, '1000')};
  --ts-text-link-color: #{map-get($emerald-light, '800')};
  --ts-text-success-color: #{map-get($green-light, '1000')};
  --ts-text-warning-color: #{map-get($orange-light, '1000')};
  --ts-text-info-color: #{map-get($blue-light, '1000')};

  /*****END text*****/

  /***** START separator*****/
  --ts-separator-primary-color: #{map-get($neutral-light, '600')};
  --ts-separator-secondary-color: #{map-get($neutral-light, '400')};
  --ts-separator-primary-accent-color: #{map-get($emerald-light, '800')};
  --ts-separator-secondary-accent-color: #{map-get($cyan-light, '900')};
  /*****END separator*****/

  /***** START icons*****/
  --ts-icon-primary-color: #{map-get($emerald-light, '800')};
  --ts-icon-secondary-color: #{map-get($cyan-light, '800')};
  --ts-icon-accent-color: #{map-get($neutral-light, '1100')};
  --ts-icon-neutral-color: #{map-get($neutral-light, '800')};
  --ts-icon-error-primary-color: #{map-get($red-light, '900')};
  --ts-icon-success-primary-color: #{map-get($green-light, '900')};
  --ts-icon-warning-primary-color: #{map-get($orange-light, '900')};
  --ts-icon-info-primary-color: #{map-get($blue-light, '900')};
  --ts-icon-inverse-color: #{map-get($neutral-light, '100')};
  --ts-icon-accent-dim-color: #{map-get($neutral-light, '1000')};

  /*****END icons*****/

  /***** START tags*****/
  --ts-tag-red-color: #{map-get($red-light, '900')};
  --ts-tag-cyan-color: #{map-get($cyan-light, '900')};
  --ts-tag-lime-color: #{map-get($lime-light, '900')};
  --ts-tag-purple-color: #{map-get($purple-light, '900')};
  --ts-tag-green-color: #{map-get($green-light, '900')};
  --ts-tag-yellow-color: #{map-get($yellow-light, '900')};
  --ts-tag-pink-color: #{map-get($pink-light, '900')};
  --ts-tag-emerald-color: #{map-get($emerald-light, '900')};
  --ts-tag-orange-color: #{map-get($orange-light, '900')};
  --ts-tag-blue-color: #{map-get($blue-light, '900')};
  --ts-tag-malachite-color: #{map-get($malachite-light, '900')};
  --ts-tag-grey-color: #{map-get($neutral-light, '600')};

  /***** END tags*****/

  /***** START background*****/
  --ts-background-primary-color: #{map-get($neutral-light, '400')};
  --ts-background-secondary-color: #{map-get($neutral-light, '100')};
  --ts-overlay-color: #{rgba(map-get($neutral-light, '1200'), 0.7)};
  --ts-modal-background-color: #{map-get($neutral-light, '100')};
  --ts-tooltip-background-color: #{map-get($neutral-light, '1100')};
  /*****END background*****/


  /***** START TABLES*****/
  --ts-table-neutral-color: #{map-get($neutral-light, '100')};
  --ts-table-hover-color: #{map-get($neutral-light, '200')};
  --ts-table-selected-color: #{map-get($emerald-light, '200')};
  --ts-table-visited-color: #{map-get($yellow-light, '100')};
  --ts-table-error-color: #{map-get($red-light, '1300')};
  --ts-table-focus-color: #{map-get($emerald-light, '100')};
  --ts-table-update-color: #{map-get($blue-light, '100')};
  /***** END TABLES*****/

  /***** START High opacity *****/
  --ts-color-high-opacity-grey: rgba(163, 163, 163, 0.5);
  /***** END High opacity *****/

  /***** START SIMPLE COLORS ******/
  --ts-pink-100 : #{map-get($pink-light, '100')};
  --ts-purple-100 : #{map-get($purple-light, '100')};
  --ts-purple-300 : #{map-get($purple-light, '300')};
  --ts-blue-100 : #{map-get($blue-light, '100')};
  --ts-green-100 : #{map-get($green-light, '100')};
  --ts-yellow-100 : #{map-get($yellow-light, '100')};
  --ts-orange-100 : #{map-get($orange-light, '100')};
  --ts-emerald-100 : #{map-get($emerald-light, '100' )};
  --ts-red-100 : #{map-get($red-light, '100' )};
  --ts-malachite-100 : #{map-get($malachite-light, '100')};
  --ts-cyan-100 : #{map-get($cyan-light, '100')};
  --ts-neutral-150 : #{map-get($neutral-light, '150')};
  --ts-neutral-200 : #{map-get($neutral-light, '200')};
  --ts-neutral-300 : #{map-get($neutral-light, '300')};
  --ts-neutral-500 : #{map-get($neutral-light, '500')};
  --ts-neutral-700 : #{map-get($neutral-light, '700')};
  --ts-neutral-1000 : #{map-get($neutral-light, '1000')};
  --ts-emerald-900 : #{map-get($emerald-light, '900')};
  /***** END SIMPLE COLORS ******/
}
