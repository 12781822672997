.ps__rail-y {
  background-color: #f8f8f8;
  width: 10px;
  z-index: 10;

  &:hover > .ps__thumb-y,
  &:focus > .ps__thumb-y,
  &.ps--clicking .ps__thumb-y {
    width: 6px;
  }
}
