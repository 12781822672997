@import '/src/assets/sass/core/colors/_palette-variables';

.entity-list {
  display: flex;
  width: 100%;
  padding: 0 10px;

  &.first {
    height: calc(100vh - 100px);

    .entity-list__container {
      padding-bottom: 24px;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 20px;
    padding-bottom: 0;
    margin-bottom: 1rem;

    .search {
      width: 100%;
      border-radius: 999px;
      display: flex;
      align-items: center;
      gap: 10px;

      &__field {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 16px;

        &::placeholder {
          color: map-get($grey-color, lighten-1);
        }
      }
    }

    > .settings {
      flex: none;
      height: 42px;
      width: 42px;
      border-radius: 999px;
      background: $grey;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        transform: scale(0.9);
        transition: 0.15s all;

        use {
          stroke: $grey-dark;
        }
      }

      &:hover {
        svg {
          transform: scale(1);
        }
      }
    }
  }

  &__labels {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0;
    padding: 0 20px;
  }

  &__table {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: hidden;

    &-row {
      display: flex;
      font-size: 13px;
      font-weight: 400;
      min-width: max-content;

      &.dimmed {
        color: rgba(0, 0, 0, 0.3);
      }

      &:nth-child(even) {
        > div {
          background-color: #f4f6fa;
        }
      }

      &.active-row {
        > div {
          background-color: #fffceb;
        }
      }

      &.header {
        background-color: #fff;
        border-bottom: 1px solid #fff;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }

    &-cell {
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        flex: 0 0 250px;
        width: 250px;
      }

      &:last-child {
        flex: 1 0 250px;
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 5px 0;
        width: 1px;
        background: #dce1ec;
      }

      &.data-cell {
        cursor: pointer;
      }

      .content {
        align-items: center;
        padding: 6px 10px;
        white-space: nowrap;
        overflow-x: hidden;
      }

      &.checkbox {
        flex: 0 0 80px;
      }

      &:nth-child(1) {
        padding-left: 15px;
      }
    }

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $border-grey;
      border-radius: 99px;
    }

    &::-webkit-scrollbar-track {
      background-color: $grey;
      border-radius: 99px;
    }

    .columns {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;

      tr {
        &:nth-child(odd) {
          td {
            background-color: $grey;
          }
        }
      }
    }
  }
}
