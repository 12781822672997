@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';
@import 'variables';

// vertical menu
$menu-expanded-width-sm: 220px;
$menu-expanded-width: 230px;
$menu-expanded-width-lg: 231px;
$menu-collapsed-width: 60px;

// Horizontal Menu
$menu-active-bg: #e7e9ec;

$font-size-root: 14px;

// Avatar
$avatar-bg: #e0e0e0;
$avatar-text-color: #616161;
$avatar-size: 32px;
$avatar-size-xl: 70px;
$avatar-size-lg: 50px;
$avatar-size-md: 40px;
$avatar-size-sm: 24px;
$avatar-status-size: 11px;
$avatar-status-size-lg: 17px;

// Shadows
$shadow-umbra-opacity: 0.2 !default;
$shadow-penumbra-opacity: 0.14 !default;
$shadow-ambient-opacity: 0.12 !default;

///////////////////////////////////////////////

// Variables for Dark Layout
$dark-style: true;
$bg-color-dark: #101010;
$dl-text-color: #b2b1b5;
$dl-bg-color: #1e1e1e;
$dl-headings-color: #bfbec2;
$dl-text-muted-color: #848484;
$dl-disabled-color: #626164;
$dl-border-color: #474748;
$dl-card-shadow: -8px 8px 14px 0 rgba($black, 0.25);

//////////////////////////////////////////////

// Variables For Transparent Layout

$tl-text-color: #d6d5d8;
$tl-headings-color: #e9e9ea;
$tl-bg-color: transparent;
$tl-text-muted-color: #c3c2c5;
$tl-disabled-color: #9e9e9e;
$tl-border-color: rgba(#eee, 0.2);
$tl-card-bg-color: rgba($black, 0.12);

$bg-glass-hibiscus: linear-gradient(
  to right bottom,
  #f05f57,
  #c83d5c,
  #99245a,
  #671351,
  #360940
);
$bg-glass-purple-pizzazz: linear-gradient(
  to right bottom,
  #662d86,
  #8b2a8a,
  #ae2389,
  #cf1d83,
  #ed1e79
);
$bg-glass-blue-lagoon: linear-gradient(
  to right bottom,
  #144e68,
  #006d83,
  #008d92,
  #00ad91,
  #57ca85
);
$bg-glass-electric-violet: linear-gradient(
  to left top,
  #4a00e0,
  #600de0,
  #7119e1,
  #8023e1,
  #8e2de2
);
$bg-glass-portage: linear-gradient(
  to left top,
  #97abff,
  #798ce5,
  #5b6ecb,
  #3b51b1,
  #123597
);
$bg-glass-tundora: linear-gradient(to left top, #ef4673, #ffc846);
