$icon-primary-color:var(--ts-icon-primary-color);
$icon-secondary-color:var(--ts-icon-secondary-color);
$icon-accent-color:var(--ts-icon-accent-color);
$icon-accent-dim-color:var(--ts-icon-accent-dim-color);
$icon-neutral-color:var(--ts-icon-neutral-color);
$icon-error-primary-color:var(--ts-icon-error-primary-color);
$icon-success-primary-color:var(--ts-icon-success-primary-color);
$icon-warning-primary-color:var(--ts-icon-warning-primary-color);
$icon-info-primary-color:var(--ts-icon-info-primary-color);
$icon-inverse-color:var(--ts-icon-inverse-color);
