@import '/src/assets/sass/core/colors/_palette-variables.scss';

.product-info,
.company-info,
.department-info,
.employees-info,
.partners-info,
.sales-info,
.exist-modifications_single,
.item-info,
.invoice,
.write-off {
  width: 100%;
  padding: 0 10px;
  padding-bottom: 10px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    position: sticky;
    top: 30px;
    z-index: 10;
    background-color: #fff;

    .header-btns {
      display: flex;
      gap: 10px;
    }
  }

  .left-btns {
    display: flex;
    gap: 10px;
    align-items: center;

    .other-btns {
      display: flex;
      gap: 10px;
    }

    > div:hover {
      use {
        fill: white;
      }
    }
  }

  svg {
    use {
      transition: 0.15s all;
      fill: #6e84a3;
    }
  }

  &__content {
    width: 100%;
    padding: 20px;
    margin-top: 20px;

    .content-top {
      display: flex;
      gap: 14px;
      align-items: center;
      margin-bottom: 20px;

      h3 {
        margin: 0;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        color: $grey-dark-l;
      }

      p {
        font-size: 14px;
        line-height: 19.6px;
        font-weight: 400;
        color: $grey-dark;
      }
    }

    .content-header {
      margin-top: 16px;
      font-size: 14px;
      line-height: 19.6px;
      font-weight: 500;
      color: $grey-dark-l;
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    p {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
    }

    .add-account {
      cursor: pointer;
      margin: 10px 0;
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        font-weight: 500;
        color: $green-emerald;
      }

      svg {
        use {
          fill: $green-emerald;
        }
      }

      &.hide {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  //.bordered-light {
  //  border: 1px solid $border-grey;
  //  background-color: #fff;
  //  color: $grey-dark-l;
  //  height: 100%;
  //  border-radius: 30px;
  //}

}

.content-inputs {
  margin-top: 14px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(326px, 1fr));
  width: 100%;
  gap: 20px;

  > div {
    display: grid;
    gap: 20px;

    &:not(div:first-child) {
      padding-left: 20px;
      border-left: 1px solid $border-grey;
    }
  }

  .input-error {
    font-size: 12px;
    line-height: 16.8px;
    color: $red;
    margin-top: 6px;
  }

  .input-info {
    font-size: 14px;
    font-weight: 400;
    color: $grey-light-m;
    margin-top: 6px;
    cursor: pointer;
    opacity: 0.7;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;

    &.active {
      color: $green;
      opacity: 1;

      &:hover {
        color: $green-dark;
      }
    }
  }

  &.settlementAccounts {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    margin-top: 0px;
    border: 0px solid $border-grey;
    margin-bottom: 30px;

    > div {
      > .settlementAccounts__wrapper {
        height: auto;
        width: 100%;
        display: flex;
        gap: 20px;

        > div {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.settlementAccounts__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;

  .settlementAccounts__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    margin-bottom: 20px;

    > div {
      display: flex;
      align-items: center;
      gap: 20px;

      p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0px;
      }
    }

    .remove-account {
      margin-left: auto;
      cursor: pointer;

      p {
        color: $red;
        font-weight: 400;
      }
    }
  }
}

.state-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
}
