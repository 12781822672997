@import '../core/colors/styles';

$colors: (
  primary: $controls-primary-color,
  secondary: $controls-secondary-color,
  error: $controls-error-color,
  warning: $controls-warning-color,
  success: $controls-success-color,
  info: $controls-info-color,
  btn-grey: $text-secondary-color,
  btn-white: $text-secondary-color,
);

$colors-hover: (
  primary: $controls-primary-hover-color,
  secondary: $controls-secondary-hover-color,
  error: $controls-error-hover-color,
  warning: $controls-warning-hover-color,
  success: $controls-success-hover-color,
  info: $controls-info-hover-color,
  btn-grey: $controls-primary-hover-color,
  btn-white: $controls-primary-hover-color,
);

$colors-active: (
  primary: $controls-primary-active-color,
  secondary: $controls-secondary-active-color,
  error: $controls-error-active-color,
  warning: $controls-warning-active-color,
  success: $controls-success-active-color,
  info: $controls-info-active-color,
  btn-grey: $controls-primary-active-color,
  btn-white: $controls-primary-active-color,
);

$colors-focus: (
  primary: $controls-primary-focus-color,
  secondary: $controls-secondary-focus-color,
  error: $controls-error-focus-color,
  warning: $controls-warning-focus-color,
  success: $controls-success-focus-color,
  info: $controls-info-focus-color,
  btn-grey: $controls-primary-focus-color,
  btn-white: $controls-primary-focus-color,
);

$surfaces: (
  primary: $surface-accent-primary-color,
  secondary: $surface-accent-secondary-color,
  error: $surface-error-color,
  warning: $surface-warning-color,
  success: $surface-success-color,
  info: $surface-info-color,
  btn-grey: $surface-secondary-color,
  btn-white: $surface-primary-color,
);

@mixin button-type($type) {
  &-filled {
    color: $text-inverse-color;

    &.#{$type} {
      background-color: map-get($colors, #{$type});

      &:hover {
        background-color: map-get($colors-hover, #{$type});
      }

      &:active {
        background-color: map-get($colors-active, #{$type});
      }

      &:focus-visible {
        background-color: map-get($colors-focus, #{$type});
      }
    }
  }

  &-bezeled {

    &.#{$type} {
      color: map-get($colors, #{$type});
      background-color: map-get($surfaces, #{$type}); // используем другую карту цветов для фоновых цветов

      &:hover {
        color: map-get($colors-hover, #{$type});
      }

      &:active {
        color: map-get($colors-active, #{$type});
      }

      &:focus-visible {
        color: map-get($colors-focus, #{$type});
      }
    }
  }

  &-borders {
    background-color: transparent;

    &.#{$type} {
      color: map-get($colors, #{$type});
      border: 1px solid map-get($colors, #{$type});

      &:hover {
        color: map-get($colors-hover, #{$type});
        border: 1px solid map-get($colors-hover, #{$type});
      }

      &:active {
        color: map-get($colors-active, #{$type});
      }

      &:focus-visible {
        color: map-get($colors-focus, #{$type});
      }
    }
  }
}
