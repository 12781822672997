@import '../../core/colors/dark-palette';

.dark-theme {

  /***** START controls*****/
  // Primary for Dark Theme
  --ts-primary-color: #{map-get($emerald-dark, '800')};
  --ts-primary-hover-color: #{map-get($emerald-dark, '600')};
  --ts-primary-active-color: #{map-get($emerald-dark, '500')};
  --ts-primary-focus-color: #{map-get($emerald-dark, '1000')};

  // Secondary for Dark Theme
  --ts-secondary-color: #{map-get($cyan-dark, '800')};
  --ts-secondary-hover-color: #{map-get($cyan-dark, '600')};
  --ts-secondary-active-color: #{map-get($cyan-dark, '500')};
  --ts-secondary-focus-color: #{map-get($cyan-dark, '1000')};

  // Error for Dark Theme
  --ts-error-color: #{map-get($red-dark, '800')};
  --ts-error-hover-color: #{map-get($red-dark, '600')};
  --ts-error-active-color: #{map-get($red-dark, '500')};
  --ts-error-focus-color: #{map-get($red-dark, '1000')};

  // Warning for Dark Theme
  --ts-warning-color: #{map-get($orange-dark, '800')};
  --ts-warning-hover-color: #{map-get($orange-dark, '600')};
  --ts-warning-active-color: #{map-get($orange-dark, '500')};
  --ts-warning-focus-color: #{map-get($orange-dark, '1000')};

  // Success for Dark Theme
  --ts-success-color: #{map-get($green-dark, '800')};
  --ts-success-hover-color: #{map-get($green-dark, '600')};
  --ts-success-active-color: #{map-get($green-dark, '500')};
  --ts-success-focus-color: #{map-get($green-dark, '1000')};

  // Info for Dark Theme
  --ts-info-color: #{map-get($blue-dark, '800')};
  --ts-info-hover-color: #{map-get($blue-dark, '600')};
  --ts-info-active-color: #{map-get($blue-dark, '500')};
  --ts-info-focus-color: #{map-get($blue-dark, '1000')};


  --ts-always-black-color: #{map-get($neutral-dark, '900')};
  --ts-always-white-color: #{map-get($neutral-dark, '100')};
  --ts-controls-disabled-color: #{map-get($neutral-dark, '800')};
  /*****END controls*****/

  /***** START surface*****/
  --ts-surface-primary-color: #{map-get($neutral-dark, '1100')};
  --ts-surface-secondary-color: #{map-get($neutral-dark, '800')};
  --ts-surface-accent-primary-color: #{map-get($emerald-dark, '200')};
  --ts-surface-accent-secondary-color: #{map-get($cyan-dark, '300')};

  --ts-surface-error-color: #{map-get($red-dark, '100')};
  --ts-surface-success-color: #{map-get($green-dark, '100')};
  --ts-surface-warning-color: #{map-get($orange-dark, '100')};
  --ts-surface-info-color: #{map-get($blue-dark, '100')};

  --ts-surface-tertiary-color: #{map-get($neutral-dark, '600')};

  --ts-surface-inverse-color: #{map-get($neutral-dark, '100')};
  --ts-surface-disable-color: #{map-get($neutral-dark, '700')};
  --ts-surface-light-color: #{map-get($neutral-dark, '900')};
  /*****END surface*****/

  /***** START text*****/
  --ts-text-primary-color: #{map-get($neutral-dark, '200')};
  --ts-text-secondary-color: #{map-get($neutral-dark, '300')};
  --ts-text-tertiary-color: #{map-get($neutral-dark, '400')};
  --ts-text-inverse-color: #{map-get($neutral-dark, '1200')};
  --ts-text-disabled-color: #{map-get($neutral-dark, '600')};
  --ts-text-placeholder-color: #{map-get($neutral-dark, '400')};

  --ts-text-error-color: #{map-get($red-dark, '1000')};
  --ts-text-link-color: #{map-get($emerald-dark, '800')};
  --ts-text-success-color: #{map-get($green-dark, '1000')};
  --ts-text-warning-color: #{map-get($orange-dark, '1000')};
  --ts-text-info-color: #{map-get($blue-dark, '1000')};
  /*****END text*****/

  /***** START separator*****/
  --ts-separator-primary-color: #{map-get($neutral-dark, '700')};
  --ts-separator-secondary-color: #{map-get($neutral-dark, '900')};
  --ts-separator-primary-accent-color: #{map-get($emerald-dark, '800')};
  --ts-separator-secondary-accent-color: #{map-get($cyan-dark, '700')};
  /*****END separator*****/

  /***** START icons*****/
  --ts-icon-primary-color: #{map-get($emerald-dark, '800')};
  --ts-icon-secondary-color: #{map-get($cyan-dark, '800')};
  --ts-icon-accent-color: #{map-get($neutral-dark, '100')};
  --ts-icon-neutral-color: #{map-get($neutral-dark, '400')};
  --ts-icon-error-primary-color: #{map-get($red-dark, '900')};
  --ts-icon-success-primary-color: #{map-get($green-dark, '900')};
  --ts-icon-warning-primary-color: #{map-get($orange-dark, '900')};
  --ts-icon-info-primary-color: #{map-get($blue-dark, '900')};
  --ts-icon-inverse-color: #{map-get($neutral-dark, '1200')};
  --ts-icon-accent-dim-color: #{map-get($neutral-dark, '200')};
  /*****END icons*****/

  /***** START tags*****/
  --ts-tag-red-color: #{map-get($red-dark, '900')};
  --ts-tag-cyan-color: #{map-get($cyan-dark, '900')};
  --ts-tag-lime-color: #{map-get($lime-dark, '900')};
  --ts-tag-purple-color: #{map-get($purple-dark, '900')};
  --ts-tag-green-color: #{map-get($green-dark, '900')};
  --ts-tag-yellow-color: #{map-get($yellow-dark, '900')};
  --ts-tag-pink-color: #{map-get($pink-dark, '900')};
  --ts-tag-emerald-color: #{map-get($emerald-dark, '900')};
  --ts-tag-orange-color: #{map-get($orange-dark, '900')};
  --ts-tag-blue-color: #{map-get($blue-dark, '900')};
  --ts-tag-malachite-color: #{map-get($malachite-dark, '900')};
  --ts-tag-grey-color: #{map-get($neutral-dark, '700')};

  /***** END tags*****/

  /***** START background*****/
  --ts-background-primary-color: #{map-get($neutral-dark, '1300')};
  --ts-background-secondary-color: #{map-get($neutral-dark, '800')};
  --ts-overlay-color: #{rgba(map-get($emerald-dark, '800'), 0.7)};
  --ts-modal-background-color: #{map-get($neutral-dark, '1000')};
  --ts-tooltip-background-color: #{map-get($neutral-dark, '200')};
  /*****END background*****/

  /***** START TABLES*****/
  --ts-table-neutral-color: #{map-get($neutral-dark, '1000')};
  --ts-table-hover-color: #{map-get($neutral-dark, '900')};
  --ts-table-selected-color: #{map-get($emerald-dark, '200')};
  --ts-table-visited-color: #{map-get($yellow-dark, '100')};
  --ts-table-error-color: #{map-get($red-dark, '1300')};
  --ts-table-focus-color: #{map-get($emerald-dark, '100')};
  --ts-table-update-color: #{map-get($blue-dark, '100')};
  /***** END TABLES*****/

  /***** START High opacity *****/
  --ts-color-high-opacity-grey: rgba(102, 102, 102, 0.4);
  /***** END High opacity *****/

  /***** START SIMPLE COLORS ******/
  --ts-pink-100 : #{map-get($pink-dark, '100')};
  --ts-purple-100 : #{map-get($purple-dark, '100')};
  --ts-purple-300 : #{map-get($purple-dark, '300')};
  --ts-blue-100 : #{map-get($blue-dark, '100')};
  --ts-green-100 : #{map-get($green-dark, '100')};
  --ts-yellow-100 : #{map-get($yellow-dark, '100')};
  --ts-orange-100 : #{map-get($orange-dark, '100')};
  --ts-emerald-100 : #{map-get($emerald-dark, '100' )};
  --ts-red-100 : #{map-get($red-dark, '100' )};
  --ts-malachite-100 : #{map-get($malachite-dark, '100')};
  --ts-cyan-100 : #{map-get($cyan-dark, '100')};
  --ts-neutral-150 : #{map-get($neutral-dark, '150')};
  --ts-neutral-200 : #{map-get($neutral-dark, '200')};
  --ts-neutral-300 : #{map-get($neutral-dark, '300')};
  --ts-neutral-500 : #{map-get($neutral-dark, '500')};
  --ts-neutral-600 : #{map-get($neutral-dark, '600')};
  --ts-neutral-700 : #{map-get($neutral-dark, '700')};
  --ts-neutral-1000 : #{map-get($neutral-dark, '1000')};
  --ts-emerald-900 : #{map-get($emerald-dark, '900')};
  /***** END SIMPLE COLORS ******/
}
