@import "core/colors/styles";


// Pagination
.page-link {
  border-radius: 0.35rem;
  margin-left: 5px;
}

// scroll to top button
.scroll-top {
  position: fixed;
  z-index: 1039;
  bottom: 4%;
  right: 2rem;
  padding: 0.4rem 0.8rem;
  opacity: 0.7;
}


.ts-drag-handle {
  cursor: pointer;
  color: $grey-high-opacity;
}

.modal-table-setting-content {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  background-color: $surface-primary-color;
  position: relative;
  height: 41px;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-inline: 1rem;
  @include border-style('top');

  &:last-child{
    @include border-style('bottom');
  }

  &.dragged{
    @include border-style;
  }
}
