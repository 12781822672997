// Primary
$controls-primary-color: var(--ts-primary-color);
$controls-primary-hover-color: var(--ts-primary-hover-color);
$controls-primary-active-color: var(--ts-primary-active-color);
$controls-primary-focus-color: var(--ts-primary-focus-color);

// Secondary
$controls-secondary-color: var(--ts-secondary-color);
$controls-secondary-hover-color: var(--ts-secondary-hover-color);
$controls-secondary-active-color: var(--ts-secondary-active-color);
$controls-secondary-focus-color: var(--ts-secondary-focus-color);

// Error
$controls-error-color: var(--ts-error-color);
$controls-error-hover-color: var(--ts-error-hover-color);
$controls-error-active-color: var(--ts-error-active-color);
$controls-error-focus-color: var(--ts-error-focus-color);

// Warning
$controls-warning-color: var(--ts-warning-color);
$controls-warning-hover-color: var(--ts-warning-hover-color);
$controls-warning-active-color: var(--ts-warning-active-color);
$controls-warning-focus-color: var(--ts-warning-focus-color);

// Success
$controls-success-color: var(--ts-success-color);
$controls-success-hover-color: var(--ts-success-hover-color);
$controls-success-active-color: var(--ts-success-active-color);
$controls-success-focus-color: var(--ts-success-focus-color);

// Info
$controls-info-color: var(--ts-info-color);
$controls-info-hover-color: var(--ts-info-hover-color);
$controls-info-active-color: var(--ts-info-active-color);
$controls-info-focus-color: var(--ts-info-focus-color);

$controls-disabled-color: var(--ts-controls-disabled-color);

$always-black-color:var(--ts-always-black-color);
$always-white-color:var(--ts-always-white-color);
