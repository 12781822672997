@import './mixins';
@import '../typography/styles';
@import '../mediaQueries/styles';

.nav-icon-button {
  @include nav-icon-button;
}

.nav-bar {
  width: 100%;
  background-color: $surface-primary-color;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0;
  @include border-style('bottom', $separator-secondary-color);

  & .navbar-brand {
    padding: 0.5rem;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    margin-right: 0.5rem;
    @include ts-subtitle-strong;
    @include border-style('right', $separator-secondary-color);

    &:hover {
      background-color: $surface-accent-primary-color;
    }

    & .title {
      color: $text-primary-color;
      @include ts-subtitle-strong;
    }
  }

  &-mobile__controls {
    margin-left: auto;
    margin-right: 0.5rem;
  }

  & .navbar-nav {
    gap: 0.25rem;
  }

  & .navbar-collapse {
    @include border-style('top', $separator-secondary-color);
    @include breakpoint-sm {
      border-top: none;
    }
  }

  & .nav-link {
    @include nav-item-common-styles;
    @include breakpoint-sm {
      flex-direction: column;
      gap: 0;

      justify-content: center;
    }

    @include breakpoint-lg {
      padding: 3px 12px;
    }

    @include breakpoint-xl {
      flex-direction: row;
      padding: 0.5rem 12px;
      gap: 0.25rem;
    }

    &__title {
      @include ts-caption-strong;
      display: block;

      @include breakpoint-sm {
        display: none;
      }

      @include breakpoint-lg {
        display: block;
      }
    }
  }
}


.navbar-toggler {
  margin-left: auto;
  margin-right: 0.5rem;
  border: none;

  &:focus {
    box-shadow: none;
  }
}

.nav-icon-button-with-text {
  @include nav-item-common-styles;
  border: none;
  outline: none;
  background: inherit;
  & .ts-icon {
    @include font-size('normal');
  }

  &__title {
    @include ts-caption-strong;
  }

  @include breakpoint-sm {
    @include nav-icon-button;

    &:not(&-active):hover {
      background-color: $surface-secondary-color;
    }
    &__title {
      display: none;
    }
  }
}

.sub-nav-container {
  width: 100%;
  overflow-x: auto;
  @include border-style('bottom', $separator-primary-color);
  background-color: $surface-primary-color;

  & .sub-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: $text-tertiary-color;
    overflow-x: auto;
    &__item {

      @include ts-body-small;
      white-space: nowrap;

      & .nav-link {
        padding: 0.5rem 1rem;
      }

      &:not(&-active):not(.disabled):hover {
        cursor: pointer;
        background-color: $surface-accent-primary-color;
        color: $controls-primary-color;
      }

      &-active {
        color: $controls-primary-color;
        @include border-style('bottom', $controls-primary-color);
        border-width: 3px;
      }
    }
  }
}
