@import '/src/assets/sass/core/colors/_palette-variables.scss';

.goods-accounting {
  padding: 0 10px;
  padding-bottom: 10px;

  .inputs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
  }

  .add-field {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: $green;
    margin: 0;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    border-bottom: 1px solid $green;
    min-height: 45px;
    max-height: 100%;
    padding-bottom: 8px;

    &:hover {
      color: $green-dark;
      border-color: $green-dark;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__cards {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__card {
    padding: 20px;
    border-radius: 30px;
    background-color: #ffffff;
    border: 1px solid $border-grey;
    display: flex;
    flex-direction: column;

    &__fields-header {
      display: flex;
      align-items: center;
      gap: 20px;
      height: 36px;

      p {
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        color: $green;

        span {
          font-size: 22px;
          font-weight: 200;
          margin-bottom: 3px;
        }

        &:hover {
          color: $green-dark;
        }
      }
    }

    &__header {
      display: flex;
      gap: 24px;

      app-toggle-switch {
        margin-top: 10px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      > div {
        display: flex;
        gap: 14px;

        .number-input {
          width: 80px;
          font-size: 18px;

          input {
            font-weight: 600;
            color: $grey-dark-l;
          }
        }

        .date-time {
          width: 250px;

          input {
            font-size: 18px;
            font-weight: 600;
            color: $grey-dark-l;
          }
        }
      }

      h1 {
        font-size: 18px;
        font-weight: 600;
        color: $grey-dark-l;
        white-space: nowrap;
        margin-top: 6px;
      }
    }

    &__block {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      h3 {
        font-size: 13px;
        font-weight: 500;
        color: $grey-dark-l;
        margin-bottom: 0;
      }

      .inputs {
        &.hide {
          height: 0px;
          overflow-y: hidden;
        }
      }
    }
  }
}

.other-blocks {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 10px;
  margin-top: 10px;

  .tabs {
    width: 100%;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid $border-grey;

    .tab {
      transition: 0.15s all;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid white;
      padding-top: 2px;

      h3 {
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        margin: 0;
        color: $grey-dark;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      svg {
        use {
          fill: $grey-dark;
        }
      }

      &:hover {
        border-bottom-color: $border-grey;
      }

      &.active {
        border-bottom-color: $green;

        svg {
          use {
            fill: $green;
          }
        }

        h3 {
          color: $green;
        }
      }
    }
  }
}

.other-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}

svg {
  use {
    transition: 0.15s all;
    fill: #6e84a3;
  }
}

