@import 'variables';
@import 'mixins';

@include generate-text-sizes($font-sizes);

.ts-header {
  @include ts-header;
}

.ts-header-larger {
  @include ts-header-larger;
}

.ts-header-small {
  @include ts-header-small;
}

.ts-subtitle-strong {
  @include ts-subtitle-strong;
}

.ts-subtitle {
  @include ts-subtitle;
}

.ts-subtitle-small {
  @include ts-subtitle-small;
}

.ts-caption-strong-bold {
  @include ts-caption-strong-bold;
}

.ts-caption-strong {
  @include ts-caption-strong;
}

.ts-caption {
  @include ts-caption;
}

.ts-caption-small {
  @include ts-caption-small;
}

.ts-body-strong {
  @include ts-body-strong;
}

.ts-body {
  @include ts-body;
}

.ts-body-small {
  @include ts-body-small;
}

.ts-label-large {
  @include ts-label-large;
}

.ts-label-medium {
  @include ts-label-medium;
}

.ts-label-small {
  @include ts-label-small;
}

.ts-control-large {
  @include ts-control-large;
}

.ts-control-medium {
  @include ts-control-medium;
}

.ts-control-small {
  @include ts-control-small;
}

.ts-icon-medium {
  font-size: 1.25rem;
}

.ts-supporting-text {
  font-size: 10px;
}
