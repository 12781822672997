$font-sizes: (
        'normal': 1rem,
        'sm': 0.875rem, //14px
        'xs': 0.75rem,  //12px
        '2xs': 0.625rem, //10px
        'lg': 1.125rem, //18px
        '2lg': 1.25rem, //20px
        'xl': 1.5rem, // 24px
        '2xl': 1.875rem, //30px
        '3xl': 2.25rem, //36px
        '4xl': 2.5rem //40px
);

$weights: (
        thin: 100,
        extra-light: 200,
        ultra-light: 200,
        light: 300,
        normal: 400,
        book: 400,
        regular: 400,
        medium: 500,
        semi-bold: 600,
        demi-bold: 600,
        bold: 700,
        extra-bold: 800,
        ultra-bold: 900,
        heavy: 900,
        black: 900,
        ultra: 900,
        ultra-black: 900,
        extra-ultra: 900,
);
