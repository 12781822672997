@import 'variables';

@mixin breakpoint-sm {
  @media (min-width: $screen-sm) {
    @content;
  }
}

@mixin breakpoint-md {
  @media (min-width: $screen-md) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media (min-width: $screen-lg) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media (min-width: $screen-xl) {
    @content;
  }
}

@mixin breakpoint-2xl {
  @media (min-width: $screen-2xl) {
    @content;
  }
}
