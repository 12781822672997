@import '../colors/styles';
@import '../variables/variables2';

@mixin nav-icon-button {
  font-size: 20px;
  background-color: $surface-secondary-color;
  width: 32px;
  height: 32px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  color: $icon-accent-color;
  cursor: pointer;
  border: none;
  &:hover {
    color: $controls-primary-hover-color;
  }

  &:active {
    color: $controls-primary-active-color;
  }

  &:focus {
    color: $controls-primary-focus-color;
    outline: none;
  }
}

@mixin nav-item-common-styles {
  color: $text-primary-color;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  justify-content: start;
  border-radius: $border-radius;
  padding: 0.5rem 12px;

  &:not(&-active):hover, &-active {
    color: $controls-primary-color;

    & .ts-icon {
      color: $icon-primary-color;
    }
  }

  &:not(&-active):hover {
    cursor: pointer;
    background-color: $surface-accent-primary-color;
  }

  &-active {
    background-color: $surface-secondary-color;
  }
}
