@import "surface";
@import "text";
@import "separators";
@import "icons";
@import "controls";
@import "background";
@import "tables";
@import "tag";
@import "opacity";

.ts-bg-secondary {
  background-color: $surface-secondary-color;
}

.ts-bg-primary {
  background-color: $surface-primary-color;
}

.ts-bg-tertiary {
  background-color: $surface-tertiary-color;
}

.ts-bg-tertiary-enforce {
  background-color: $surface-tertiary-color !important;
}

.ts-bg-tooltip {
  background-color: $background-tooltip-color;
}

.ts-bg-surface-secondary {
  background-color: $surface-secondary-color
}

.ts-bg-table-update {
  background-color: $table-update-color;
}

.ts-surface-primary {
  background-color: $surface-primary-color;
}

.ts-surface-success{
  background-color: $surface-success-color;
}

.ts-surface-error{
  background-color: $surface-error-color;
}

.ts-surface-warning{
  background-color: $surface-warning-color;
}

.ts-text-primary {
  color: $text-primary-color;
}

.ts-text-error {
  color: $text-error-color;
}

.ts-text-success {
  color: $text-success-color;
}

.ts-text-warning {
  color: $text-warning-color;
}

.ts-text-secondary {
  color: $text-secondary-color;
}

.ts-text-control-primary {
  color: $controls-primary-color;
}

.ts-text-tertiary {
  color: $text-tertiary-color;
}

.ts-text-icon-primary-color {
  color: $icon-primary-color;
}

.ts-text-icon-neutral-color {
  color: $icon-neutral-color;
}

.ts-text-icon-info-primary-color {
  color: $icon-info-primary-color;
}

.ts-text-icon-error-primary-color {
  color: $icon-error-primary-color;
}

.ts-text-icon-warning-primary-color {
  color: $icon-warning-primary-color;
}

.ts-text-icon-success-primary-color {
  color: $icon-success-primary-color;
}

.ts-text-icon-accent-dim-color {
  color: $icon-accent-dim-color;
}

.ts-text-placeholder-color {
  color: $text-placeholder-color;
}

.ts-text-link-color {
  color: $text-link-color;
}

.ts-text-info-color {
  color: $text-info-color;
}

.ts-text-inverse-color {
  color: $text-inverse-color;
}

.ts-text-disabled {
  color: $text-disabled-color;
}
