@import "../core/colors/styles";
@import "../core/typography/styles";

.dropdown-list {
  overflow: auto;
}

.dropdown-menu, .ts-dropdown-menu {

  border-radius: 0.5rem;
  padding: 0;
  background-color: $surface-primary-color;

  & .select-option, .dropdown-item, .ts-dropdown-item {
    background-color: $surface-primary-color;
    border: none;
    width: 100%;
    @include ts-control-medium;
    color: $text-secondary-color;
    padding: 0.5rem 1rem;
    @include border-style('bottom', $separator-secondary-color);

    &:hover {
      background-color: $table-hover-color;
    }

  }
}


