$tag-red-color: var(--ts-tag-red-color);
$tag-cyan-color: var(--ts-tag-cyan-color);
$tag-lime-color: var(--ts-tag-lime-color);
$tag-purple-color: var(--ts-tag-purple-color);
$tag-green-color: var(--ts-tag-green-color);
$tag-yellow-color: var(--ts-tag-yellow-color);
$tag-pink-color: var(--ts-tag-pink-color);
$tag-emerald-color: var(--ts-tag-emerald-color);
$tag-orange-color: var(--ts-tag-orange-color);
$tag-blue-color: var(--ts-tag-blue-color);
$tag-malachite-color: var(--ts-tag-malachite-color);
$tag-grey-color: var(--ts-tag-grey-color);

$pink-100: var(--ts-pink-100);
$purple-100: var(--ts-purple-100);
$purple-300: var(--ts-purple-300);
$blue-100: var(--ts-blue-100);
$red-100: var(--ts-red-100);
$green-100: var(--ts-green-100);
$yellow-100: var(--ts-yellow-100);
$emerald-100: var(--ts-emerald-100);
$malachite-100: var(--ts-malachite-100);
$cyan-100: var(--ts-cyan-100);
$orange-100: var(--ts-orange-100);
$neutral-150: var(--ts-neutral-150);
$neutral-200: var(--ts-neutral-200);
$neutral-300: var(--ts-neutral-300);
$neutral-500: var(--ts-neutral-500);
$neutral-600: var(--ts-neutral-600);
$neutral-700: var(--ts-neutral-700);
$neutral-1000: var(--ts-neutral-1000);
$emerald-900: var(--ts-emerald-900);
