$text-primary-color: var(--ts-text-primary-color);
$text-secondary-color: var(--ts-text-secondary-color);
$text-tertiary-color: var(--ts-text-tertiary-color);
$text-inverse-color: var(--ts-text-inverse-color);
$text-link-color: var(--ts-text-link-color);
$text-placeholder-color: var(--ts-text-placeholder-color);
$text-disabled-color: var(--ts-text-disabled-color);

$text-error-color: var(--ts-text-error-color);
$text-success-color: var(--ts-text-success-color);
$text-warning-color: var(--ts-text-warning-color);
$text-info-color: var(--ts-text-info-color);
$high-opacity-grey: rgba(163, 163, 163, 0.5);
