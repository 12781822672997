.form-control,
.form-select {
  border-radius: 30px;
  background-color: $grey;
  border: none;
  color: $grey-dark;
  height: 40px;

  &:disabled {
    background-color: #e9ecef;
    opacity: 0.3;
  }

  &:focus {
    box-shadow: none;
    background-color: $grey;
    color: $grey-dark;
  }

  &::placeholder {
    color: #90a1b9;
  }

  &-outline {
    border-radius: 30px;
    background-color: transparent;
    border: 1px solid $grey;
    color: $grey-dark;
    height: 40px;
    width: 100%;
    padding-left: 20px;

    &:focus {
      box-shadow: none;
      color: $grey-dark;
    }

    &::placeholder {
      color: #6e84a3;
    }
  }
}

.form-select {
  background-image: url('/assets/img/icons/arrow.svg');
  background-size: 24px;
  background-position: right 0.25rem center;
}

.form-check {
  &-input:checked {
    background-color: $green;
    border-color: $green;
  }

  &-switch {
    // height: 0;
    // width: 0;
    // visibility: hidden;
    display: none;
  }
}

.form-label {
  &-switch {
    cursor: pointer;
    text-indent: -9999px;
    width: 32px;
    min-width: 32px;
    height: 19px;
    background: #e3ebf6;
    display: block;
    border-radius: 20px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      width: 17px;
      height: 17px;
      background: #fff;
      border-radius: 50%;
      transition: 0.3s;
    }
  }
}

.form-check-switch {
  &:disabled + label {
    opacity: 0.6;
  }
  &:not([disabled]):checked + label::after {
    left: 31px;
    background: $green;
  }
}

.form-check-switch:checked + label:after {
  left: calc(100%);
  transform: translateX(-100%);
}

.form-check-switch:active:after {
  width: 130px;
}

label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $grey-dark;
  padding-left: 0.5rem;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: pink;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: pink;
}

.--search {
  background: no-repeat 7px url('../../../assets/img/ui/forms/search.svg') $grey;
  padding-left: 2rem;
}
.--search-outline {
  background: no-repeat 7px url('../../../assets/img/ui/forms/search.svg')
    transparent;
  padding-left: 2rem;
}

.input-group {
  flex-wrap: nowrap;
  display: inline-flex;

  & > input {
    padding-right: 5px;
  }
}

/* radio */
.form-radio {
  width: 24px;
  height: 24px;
  position: relative;
  // margin: 20px auto;
  background: transparent;
  border-radius: 50px;
  border: 1px solid #6e84a3;
  label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    left: 4px;
    top: 4px;
    background: linear-gradient(top, #222 0%, #45484d 100%);
    border-radius: 50px;
    &:after {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: -2px;
      left: -1px;
      background: #6e84a3;
      opacity: 0;
      border-radius: 50px;
      box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
    }
    &:hover::after {
      opacity: 0.3;
    }
  }
  input[type='radio'] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}
/* end radio */

.text-danger {
  color: $red;
}
.text-succes {
  color: $green;
}
