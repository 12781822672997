$icomoon-font-family: "Topseller_Icons" !default;
$icomoon-font-path: "../../fonts/topseller-icons" !default;

$ts-icon-media-pause: "\e900";
$ts-icon-media-volume-mute: "\e901";
$ts-icon-media-volume-high: "\e902";
$ts-icon-media-pause-circle: "\e903";
$ts-icon-media-volume-up: "\e904";
$ts-icon-media-play-circle: "\e905";
$ts-icon-media-volume-slash: "\e906";
$ts-icon-media-stop-circle: "\e907";
$ts-icon-media-image: "\e908";
$ts-icon-media-gallery-edit: "\e909";
$ts-icon-media-volume-low: "\e90a";
$ts-icon-media-gallery-remove: "\e90b";
$ts-icon-media-record: "\e90c";
$ts-icon-media-play: "\e90d";
$ts-icon-media-screenmirroring: "\e90e";
$ts-icon-media-musicnote: "\e90f";
$ts-icon-media-video: "\e910";
$ts-icon-media-gallery-add: "\e911";
$ts-icon-media-gallery: "\e912";
$ts-icon-media-gallery-tick: "\e913";
$ts-icon-media-video-circle: "\e914";
$ts-icon-media-video-square: "\e915";
$ts-icon-media-microphone-slash: "\e916";
$ts-icon-media-subtitle: "\e917";
$ts-icon-media-microphone: "\e918";
$ts-icon-media-video-play: "\e919";
$ts-icon-media-microphone-slash2: "\e91a";
$ts-icon-media-video-vertical: "\e91b";
$ts-icon-media-video-horizontal: "\e91c";
$ts-icon-media-microphone-2: "\e91d";
$ts-icon-media-gallery-export: "\e91e";
$ts-icon-media-volume-down: "\e91f";
$ts-icon-media-gallery-import: "\e920";
$ts-icon-media-record-circle: "\e921";
$ts-icon-media-gallery-slash: "\e922";
$ts-icon-media-gallery-favorite: "\e923";
$ts-icon-media-camera: "\e924";
$ts-icon-media-volume-cross: "\e925";
$ts-icon-media-music: "\e926";
$ts-icon-media-camera-slash: "\e927";
$ts-icon-media-video-slash: "\e928";
$ts-icon-media-stop: "\e929";
$ts-icon-media-maximize-circle: "\e92a";
$ts-icon-media-oice-cricle: "\e92b";
$ts-icon-media-voice-square: "\e92c";
$ts-icon-media-video-octagon: "\e92d";
$ts-icon-media-video-time: "\e92e";
$ts-icon-media-video-tick: "\e92f";
$ts-icon-media-video-remove: "\e930";
$ts-icon-media-video-add: "\e931";
$ts-icon-media-mini-music-sqaure: "\e932";
$ts-icon-media-audio-square: "\e933";
$ts-icon-media-note-square: "\e934";
$ts-icon-media-music-square: "\e935";
$ts-icon-media-music-library-2: "\e936";
$ts-icon-media-group: "\e937";
$ts-icon-media-devices: "\e938";
$ts-icon-media-forward-10-seconds: "\e939";
$ts-icon-media-repeate-one: "\e93a";
$ts-icon-media-repeate-music: "\e93b";
$ts-icon-media-music-square-search: "\e93c";
$ts-icon-media-music-square-add: "\e93d";
$ts-icon-media-music-square-remove: "\e93e";
$ts-icon-media-music-dashboard: "\e93f";
$ts-icon-media-music-filter: "\e940";
$ts-icon-media-music-playlist: "\e941";
$ts-icon-media-forward-15-seconds: "\e942";
$ts-icon-media-forward-5-seconds: "\e943";
$ts-icon-media-radio: "\e944";
$ts-icon-media-next: "\e945";
$ts-icon-media-previous: "\e946";
$ts-icon-media-forward: "\e947";
$ts-icon-media-backward-10-seconds: "\e948";
$ts-icon-media-backward-15-seconds: "\e949";
$ts-icon-media-backward-5-seconds: "\e94a";
$ts-icon-media-play-add: "\e94b";
$ts-icon-media-play-remove: "\e94c";
$ts-icon-media-music-circle: "\e94d";
$ts-icon-media-play-cricle: "\e94e";
$ts-icon-media-backward: "\e94f";
$ts-icon-media-scissor: "\e950";
$ts-icon-location-direct-down: "\e951";
$ts-icon-location-routing-2: "\e952";
$ts-icon-location-routing: "\e953";
$ts-icon-location-location-minus: "\e954";
$ts-icon-location-map2: "\e955";
$ts-icon-location-gps-slash: "\e956";
$ts-icon-location-location: "\e957";
$ts-icon-location-location-tick: "\e958";
$ts-icon-location-gps: "\e959";
$ts-icon-location-location-cross: "\e95a";
$ts-icon-location-direct-right: "\e95b";
$ts-icon-location-location-add: "\e95c";
$ts-icon-location-direct-left: "\e95d";
$ts-icon-location-direct-up: "\e95e";
$ts-icon-location-global: "\e95f";
$ts-icon-location-global-refresh: "\e960";
$ts-icon-location-discover: "\e961";
$ts-icon-location-route-square: "\e962";
$ts-icon-location-arrow: "\e963";
$ts-icon-location-picture-frame: "\e964";
$ts-icon-location-arrow-square: "\e965";
$ts-icon-location-map: "\e966";
$ts-icon-location-global-edit: "\e967";
$ts-icon-location-global-search: "\e968";
$ts-icon-location-radar-2: "\e969";
$ts-icon-location-radar: "\e96a";
$ts-icon-computers-lamp: "\e96b";
$ts-icon-computers-simcard-2: "\e96c";
$ts-icon-computers-simcard: "\e96d";
$ts-icon-computers-ram-2: "\e96e";
$ts-icon-computers-ram: "\e96f";
$ts-icon-computers-clock: "\e970";
$ts-icon-computers-mouse: "\e971";
$ts-icon-computers-keyboard: "\e972";
$ts-icon-computers-weight: "\e973";
$ts-icon-computers-game: "\e974";
$ts-icon-computers-cpu-setting: "\e975";
$ts-icon-computers-headphone: "\e976";
$ts-icon-computers-airpod: "\e977";
$ts-icon-computers-printer-slash: "\e978";
$ts-icon-computers-monitor-mobbile: "\e979";
$ts-icon-computers-monitor-recorder: "\e97a";
$ts-icon-computers-monitor: "\e97b";
$ts-icon-computers-cpu: "\e97c";
$ts-icon-computers-printer: "\e97d";
$ts-icon-computers-cpu-charge: "\e97e";
$ts-icon-computers-cloud-change: "\e97f";
$ts-icon-computers-watch-status: "\e980";
$ts-icon-computers-watch: "\e981";
$ts-icon-computers-gameboy: "\e982";
$ts-icon-computers-airdrop: "\e983";
$ts-icon-computers-keyboard-open: "\e984";
$ts-icon-computers-simcard-code: "\e985";
$ts-icon-computers-microscope: "\e986";
$ts-icon-computers-mirroring-screen: "\e987";
$ts-icon-computers-electricity: "\e988";
$ts-icon-computers-mobile: "\e989";
$ts-icon-computers-folder-connection: "\e98a";
$ts-icon-computers-headphones: "\e98b";
$ts-icon-computers-airpods: "\e98c";
$ts-icon-computers-devices: "\e98d";
$ts-icon-computers-music-play: "\e98e";
$ts-icon-computers-cloud-connection: "\e98f";
$ts-icon-computers-external-drive: "\e990";
$ts-icon-computers-cloud-remove: "\e991";
$ts-icon-computers-cloud-add: "\e992";
$ts-icon-computers-driver-refresh: "\e993";
$ts-icon-computers-driver-2: "\e994";
$ts-icon-computers-driver: "\e995";
$ts-icon-computers-speaker: "\e996";
$ts-icon-programing-data-2: "\e997";
$ts-icon-programing-hierarchy-square-3: "\e998";
$ts-icon-programing-hierarchy-square-2: "\e999";
$ts-icon-programing-sidebar-bottom: "\e99a";
$ts-icon-programing-scroll: "\e99b";
$ts-icon-programing-sidebar-top: "\e99c";
$ts-icon-programing-sidebar-left: "\e99d";
$ts-icon-programing-sidebar-right: "\e99e";
$ts-icon-programing-code: "\e99f";
$ts-icon-programing-hashtag: "\e9a0";
$ts-icon-programing-hashtag-up: "\e9a1";
$ts-icon-programing-hashtag-down: "\e9a2";
$ts-icon-programing-code2: "\e9a3";
$ts-icon-programing-mobile-programming: "\e9a4";
$ts-icon-programing-message-programming: "\e9a5";
$ts-icon-programing-document-code-2: "\e9a6";
$ts-icon-programing-programming-arrows: "\e9a7";
$ts-icon-programing-hierarchy-3: "\e9a8";
$ts-icon-programing-code-circle: "\e9a9";
$ts-icon-programing-document-code: "\e9aa";
$ts-icon-programing-data: "\e9ab";
$ts-icon-programing-hierarchy-square: "\e9ac";
$ts-icon-programing-ommand-square: "\e9ad";
$ts-icon-programing-hierarchy: "\e9ae";
$ts-icon-programing-programming-arrow: "\e9af";
$ts-icon-programing-command: "\e9b0";
$ts-icon-building-hospital: "\e9b1";
$ts-icon-building-buliding: "\e9b2";
$ts-icon-building-house-2: "\e9b3";
$ts-icon-building-house: "\e9b4";
$ts-icon-building-courthouse: "\e9b5";
$ts-icon-building-building-4: "\e9b6";
$ts-icon-building-building-3: "\e9b7";
$ts-icon-building-buildings-2: "\e9b8";
$ts-icon-building-bank: "\e9b9";
$ts-icon-building-buildings: "\e9ba";
$ts-icon-building-building: "\e9bb";
$ts-icon-charts-vertical-chart: "\e9bc";
$ts-icon-charts-pie-chart-alt2: "\e9bd";
$ts-icon-charts-pie-chart-alt: "\e9be";
$ts-icon-charts-analytics-2: "\e9bf";
$ts-icon-charts-analytics: "\e9c0";
$ts-icon-charts-seo: "\e9c1";
$ts-icon-charts-pie-chart-segments: "\e9c2";
$ts-icon-charts-pie-chart: "\e9c3";
$ts-icon-charts-status-up: "\e9c4";
$ts-icon-charts-personalcard: "\e9c5";
$ts-icon-charts-chart: "\e9c6";
$ts-icon-charts-hashtag: "\e9c7";
$ts-icon-charts-trend-down: "\e9c8";
$ts-icon-charts-home-hashtag: "\e9c9";
$ts-icon-charts-trend-up: "\e9ca";
$ts-icon-charts-home-trend-down: "\e9cb";
$ts-icon-charts-chart2: "\e9cc";
$ts-icon-charts-home-trend-up: "\e9cd";
$ts-icon-charts-graph: "\e9ce";
$ts-icon-charts-chart-2: "\e9cf";
$ts-icon-charts-activity: "\e9d0";
$ts-icon-charts-frame: "\e9d1";
$ts-icon-charts-health: "\e9d2";
$ts-icon-charts-chart-success: "\e9d3";
$ts-icon-charts-favorite-chart: "\e9d4";
$ts-icon-charts-chart3: "\e9d5";
$ts-icon-charts-presention-chart: "\e9d6";
$ts-icon-charts-diagram: "\e9d7";
$ts-icon-learning-calculator: "\e9d8";
$ts-icon-learning-book-open: "\e9d9";
$ts-icon-learning-gift: "\e9da";
$ts-icon-learning-note: "\e9db";
$ts-icon-learning-teacher: "\e9dc";
$ts-icon-learning-award: "\e9dd";
$ts-icon-learning-note-2: "\e9de";
$ts-icon-learning-bookmark-2: "\e9df";
$ts-icon-learning-clipboard: "\e9e0";
$ts-icon-learning-book: "\e9e1";
$ts-icon-learning-bookmark: "\e9e2";
$ts-icon-learning-brifecase-cross: "\e9e3";
$ts-icon-learning-brifecase-tick: "\e9e4";
$ts-icon-learning-brifecase-timer: "\e9e5";
$ts-icon-learning-briefcase: "\e9e6";
$ts-icon-users-support: "\e9e7";
$ts-icon-users-profile: "\e9e8";
$ts-icon-users-profile-circle: "\e9e9";
$ts-icon-users-profile-2user: "\e9ea";
$ts-icon-users-profile-delete: "\e9eb";
$ts-icon-users-profile-tick: "\e9ec";
$ts-icon-users-profile-add: "\e9ed";
$ts-icon-users-profile-remove: "\e9ee";
$ts-icon-users-user-square: "\e9ef";
$ts-icon-users-user-cirlce-add: "\e9f0";
$ts-icon-users-user-octagon: "\e9f1";
$ts-icon-users-user-edit: "\e9f2";
$ts-icon-users-user-search: "\e9f3";
$ts-icon-users-user-tag: "\e9f4";
$ts-icon-users-user-minus: "\e9f5";
$ts-icon-users-user-tick: "\e9f6";
$ts-icon-users-user-remove: "\e9f7";
$ts-icon-users-user-add: "\e9f8";
$ts-icon-users-user: "\e9f9";
$ts-icon-users-people: "\e9fa";
$ts-icon-users-tag-user: "\e9fb";
$ts-icon-call-call-minus: "\e9fc";
$ts-icon-call-call-remove: "\e9fd";
$ts-icon-call-call: "\e9fe";
$ts-icon-call-call-slash: "\e9ff";
$ts-icon-call-call-outgoing: "\ea00";
$ts-icon-call-call-add: "\ea01";
$ts-icon-call-call-incoming: "\ea02";
$ts-icon-call-call-received: "\ea03";
$ts-icon-call-call-calling: "\ea04";
$ts-icon-communication-Emoji: "\ea05";
$ts-icon-communication-star-slash: "\ea06";
$ts-icon-communication-like: "\ea07";
$ts-icon-communication-heart-circle: "\ea08";
$ts-icon-communication-like_heart: "\ea09";
$ts-icon-communication-unlimited: "\ea0a";
$ts-icon-communication-medal: "\ea0b";
$ts-icon-communication-lovely: "\ea0c";
$ts-icon-communication-medal-star: "\ea0d";
$ts-icon-communication-heart-add: "\ea0e";
$ts-icon-communication-heart-remove: "\ea0f";
$ts-icon-communication-heart-slash: "\ea10";
$ts-icon-communication-like-shapes: "\ea11";
$ts-icon-communication-24-support: "\ea12";
$ts-icon-communication-like-tag: "\ea13";
$ts-icon-communication-like-dislike: "\ea14";
$ts-icon-communication-smileys: "\ea15";
$ts-icon-communication-message-question: "\ea16";
$ts-icon-communication-heart-tick: "\ea17";
$ts-icon-communication-heart-search: "\ea18";
$ts-icon-communication-heart-edit: "\ea19";
$ts-icon-communication-magic-star: "\ea1a";
$ts-icon-communication-ranking: "\ea1b";
$ts-icon-communication-star2: "\ea1c";
$ts-icon-communication-dislike: "\ea1d";
$ts-icon-type-textalign-justifycenter: "\ea1e";
$ts-icon-type-paperclip: "\ea1f";
$ts-icon-type-textalign-justifyright: "\ea20";
$ts-icon-type-paperclip-2: "\ea21";
$ts-icon-type-Default: "\ea22";
$ts-icon-type-textalign-right: "\ea23";
$ts-icon-type-text-underline: "\ea24";
$ts-icon-type-textalign-center: "\ea25";
$ts-icon-type-text-bold: "\ea26";
$ts-icon-type-textalign-left: "\ea27";
$ts-icon-type-smallcaps: "\ea28";
$ts-icon-type-text: "\ea29";
$ts-icon-type-maximize: "\ea2a";
$ts-icon-type-pharagraphspacing: "\ea2b";
$ts-icon-type-text-italic: "\ea2c";
$ts-icon-type-text-block: "\ea2d";
$ts-icon-type-link: "\ea2e";
$ts-icon-type-firstline: "\ea2f";
$ts-icon-type-link2: "\ea30";
$ts-icon-type-textalign-justifyleft: "\ea31";
$ts-icon-type-anguage-circle: "\ea32";
$ts-icon-type-language-square: "\ea33";
$ts-icon-type-translate: "\ea34";
$ts-icon-type-link-circle: "\ea35";
$ts-icon-type-link-square: "\ea36";
$ts-icon-type-link3: "\ea37";
$ts-icon-type-quote-up-circle: "\ea38";
$ts-icon-type-quote-down-circle: "\ea39";
$ts-icon-type-quote-down-square: "\ea3a";
$ts-icon-type-quote-up-square: "\ea3b";
$ts-icon-type-quote-down: "\ea3c";
$ts-icon-type-quote-up: "\ea3d";
$ts-icon-type-attach-circle: "\ea3e";
$ts-icon-type-attach-square: "\ea3f";
$ts-icon-type-eraser: "\ea40";
$ts-icon-messages-message-text: "\ea41";
$ts-icon-messages-message-notif: "\ea42";
$ts-icon-messages-message-favorite: "\ea43";
$ts-icon-messages-message: "\ea44";
$ts-icon-messages-direct-send: "\ea45";
$ts-icon-messages-direct-inbox: "\ea46";
$ts-icon-messages-direct-notification: "\ea47";
$ts-icon-messages-direct: "\ea48";
$ts-icon-messages-direct-normal: "\ea49";
$ts-icon-messages-directbox-send: "\ea4a";
$ts-icon-messages-directbox-receive: "\ea4b";
$ts-icon-messages-directbox-notif: "\ea4c";
$ts-icon-messages-directbox-default: "\ea4d";
$ts-icon-messages-message-edit: "\ea4e";
$ts-icon-messages-message-plus: "\ea4f";
$ts-icon-messages-message-minus: "\ea50";
$ts-icon-messages-message-text2: "\ea51";
$ts-icon-messages-message-square: "\ea52";
$ts-icon-messages-message-circle: "\ea53";
$ts-icon-messages-messages2: "\ea54";
$ts-icon-messages-message-2: "\ea55";
$ts-icon-messages-message-time: "\ea56";
$ts-icon-messages-message-tick: "\ea57";
$ts-icon-messages-message-add: "\ea58";
$ts-icon-messages-message-remove: "\ea59";
$ts-icon-messages-sms-search: "\ea5a";
$ts-icon-messages-sms-star: "\ea5b";
$ts-icon-messages-sms-edit: "\ea5c";
$ts-icon-messages-sms-notification: "\ea5d";
$ts-icon-messages-sms-tracking: "\ea5e";
$ts-icon-messages-sms: "\ea5f";
$ts-icon-messages-messages-3: "\ea60";
$ts-icon-messages-messages-2: "\ea61";
$ts-icon-messages-messages: "\ea62";
$ts-icon-messages-device-message: "\ea63";
$ts-icon-messages-message-search: "\ea64";
$ts-icon-goods-box: "\ea65";
$ts-icon-goods-3d-rotate: "\ea66";
$ts-icon-goods-3d-square: "\ea67";
$ts-icon-goods-truck-remove: "\ea68";
$ts-icon-goods-truck-time: "\ea69";
$ts-icon-goods-truck-tick: "\ea6a";
$ts-icon-goods-box-search: "\ea6b";
$ts-icon-goods-group: "\ea6c";
$ts-icon-goods-group2: "\ea6d";
$ts-icon-goods-box2: "\ea6e";
$ts-icon-goods-box-time: "\ea6f";
$ts-icon-goods-box-tick: "\ea70";
$ts-icon-goods-box-remove: "\ea71";
$ts-icon-goods-box-add: "\ea72";
$ts-icon-goods-convert-3d-cube: "\ea73";
$ts-icon-goods-3d-cube-scan: "\ea74";
$ts-icon-money-vuesax: "\ea75";
$ts-icon-money-money-recive: "\ea76";
$ts-icon-money-math: "\ea77";
$ts-icon-money-ticket-star: "\ea78";
$ts-icon-money-ticket: "\ea79";
$ts-icon-money-receipt-disscount: "\ea7a";
$ts-icon-money-receipt-3: "\ea7b";
$ts-icon-money-dollar-circle: "\ea7c";
$ts-icon-money-wallet2: "\ea7d";
$ts-icon-money-wallet-add2: "\ea7e";
$ts-icon-money-receipt: "\ea7f";
$ts-icon-money-dollar-square: "\ea80";
$ts-icon-money-card-receive: "\ea81";
$ts-icon-money-card-pos: "\ea82";
$ts-icon-money-card-tick2: "\ea83";
$ts-icon-money-card: "\ea84";
$ts-icon-money-card-add: "\ea85";
$ts-icon-money-card-slash: "\ea86";
$ts-icon-money-card-remove2: "\ea87";
$ts-icon-money-tag: "\ea88";
$ts-icon-money-ticket-discount: "\ea89";
$ts-icon-money-ticket-2: "\ea8a";
$ts-icon-money-ticket-expired: "\ea8b";
$ts-icon-money-card-edit: "\ea8c";
$ts-icon-money-wallet-minus: "\ea8d";
$ts-icon-money-tag-2: "\ea8e";
$ts-icon-money-card-send: "\ea8f";
$ts-icon-money-coin2: "\ea90";
$ts-icon-money-document: "\ea91";
$ts-icon-money-archive: "\ea92";
$ts-icon-money-security-card: "\ea93";
$ts-icon-money-wallet: "\ea94";
$ts-icon-money-strongbox: "\ea95";
$ts-icon-money-receipt-text: "\ea96";
$ts-icon-money-money-time: "\ea97";
$ts-icon-money-wallet-remove: "\ea98";
$ts-icon-money-receipt3: "\ea99";
$ts-icon-money-money-change: "\ea9a";
$ts-icon-money-receipt-2: "\ea9b";
$ts-icon-money-card-tick: "\ea9c";
$ts-icon-money-wallet-3: "\ea9d";
$ts-icon-money-money-forbidden: "\ea9e";
$ts-icon-money-cards: "\ea9f";
$ts-icon-money-card-remove: "\eaa0";
$ts-icon-money-wallet-money: "\eaa1";
$ts-icon-money-money-remove: "\eaa2";
$ts-icon-money-wallet-2: "\eaa3";
$ts-icon-money-discount-circle: "\eaa4";
$ts-icon-money-group2: "\eaa5";
$ts-icon-money-money-add: "\eaa6";
$ts-icon-money-receipt-search: "\eaa7";
$ts-icon-money-discount-shape: "\eaa8";
$ts-icon-money-money-tick: "\eaa9";
$ts-icon-money-receipt-edit: "\eaaa";
$ts-icon-money-coin: "\eaab";
$ts-icon-money-moneys: "\eaac";
$ts-icon-money-chart-square: "\eaad";
$ts-icon-money-group: "\eaae";
$ts-icon-money-money-4: "\eaaf";
$ts-icon-money-percentage-square: "\eab0";
$ts-icon-money-money-3: "\eab1";
$ts-icon-money-empty-wallet-change: "\eab2";
$ts-icon-money-money-2: "\eab3";
$ts-icon-money-transaction-minus: "\eab4";
$ts-icon-money-empty-wallet-time: "\eab5";
$ts-icon-money-money: "\eab6";
$ts-icon-money-receipt-add: "\eab7";
$ts-icon-money-empty-wallet-tick: "\eab8";
$ts-icon-money-strongbox-2: "\eab9";
$ts-icon-money-wallet-search: "\eaba";
$ts-icon-money-receipt-minus: "\eabb";
$ts-icon-money-empty-wallet-add: "\eabc";
$ts-icon-money-empty-wallet-remove: "\eabd";
$ts-icon-money-receipt-discount: "\eabe";
$ts-icon-money-receipt-item: "\eabf";
$ts-icon-money-wallet-check: "\eac0";
$ts-icon-money-empty-wallet: "\eac1";
$ts-icon-money-wallet-add: "\eac2";
$ts-icon-shop-Gift: "\eac3";
$ts-icon-shop-Basket_alt_3: "\eac4";
$ts-icon-shop-Bag_alt: "\eac5";
$ts-icon-shop-bag: "\eac6";
$ts-icon-shop-shop: "\eac7";
$ts-icon-shop-Home: "\eac8";
$ts-icon-shop-bag-tick: "\eac9";
$ts-icon-shop-bag-cross2: "\eaca";
$ts-icon-shop-bag-tick-2: "\eacb";
$ts-icon-shop-bag-happy: "\eacc";
$ts-icon-shop-bag-2: "\eacd";
$ts-icon-shop-bag-timer: "\eace";
$ts-icon-shop-bag-cross: "\eacf";
$ts-icon-shop-barcode: "\ead0";
$ts-icon-shop-shop-remove: "\ead1";
$ts-icon-shop-shop-add: "\ead2";
$ts-icon-shop-shopping-bag: "\ead3";
$ts-icon-shop-shopping-cart: "\ead4";
$ts-icon-social-Telegram: "\ead5";
$ts-icon-currency-Usd: "\ead6";
$ts-icon-currency-Euro: "\ead7";
$ts-icon-currency-Ruble: "\ead8";
$ts-icon-security-Qr: "\ead9";
$ts-icon-security-password-check: "\eada";
$ts-icon-security-shield-search: "\eadb";
$ts-icon-security-shield-slash: "\eadc";
$ts-icon-security-shield-tick: "\eadd";
$ts-icon-security-shield-security: "\eade";
$ts-icon-security-eye-slash: "\eadf";
$ts-icon-security-shield-cross: "\eae0";
$ts-icon-security-scan: "\eae1";
$ts-icon-security-shield: "\eae2";
$ts-icon-security-scanning: "\eae3";
$ts-icon-security-scan-barcode: "\eae4";
$ts-icon-security-eye: "\eae5";
$ts-icon-security-lock-slash: "\eae6";
$ts-icon-security-unlock: "\eae7";
$ts-icon-security-lock: "\eae8";
$ts-icon-security-check: "\eae9";
$ts-icon-security-key-square: "\eaea";
$ts-icon-security-key: "\eaeb";
$ts-icon-security-security: "\eaec";
$ts-icon-security-security-safe: "\eaed";
$ts-icon-security-radar: "\eaee";
$ts-icon-security-alarm: "\eaef";
$ts-icon-security-lock-circle: "\eaf0";
$ts-icon-security-lock2: "\eaf1";
$ts-icon-security-security-user: "\eaf2";
$ts-icon-security-scanner: "\eaf3";
$ts-icon-security-finger-cricle: "\eaf4";
$ts-icon-security-finger-scan: "\eaf5";
$ts-icon-time-Auto: "\eaf6";
$ts-icon-time-calendar2: "\eaf7";
$ts-icon-time-timer-start: "\eaf8";
$ts-icon-time-timer-pause: "\eaf9";
$ts-icon-time-timer2: "\eafa";
$ts-icon-time-clock: "\eafb";
$ts-icon-time-timer: "\eafc";
$ts-icon-time-calendar-circle: "\eafd";
$ts-icon-time-calendar-2: "\eafe";
$ts-icon-time-calendar-edit: "\eaff";
$ts-icon-time-calendar-add: "\eb00";
$ts-icon-time-calendar-remove: "\eb01";
$ts-icon-time-calendar-search: "\eb02";
$ts-icon-time-calendar-tick: "\eb03";
$ts-icon-time-calendar: "\eb04";
$ts-icon-time-security-time: "\eb05";
$ts-icon-document-note-add: "\eb06";
$ts-icon-document-note2: "\eb07";
$ts-icon-document-stickynote: "\eb08";
$ts-icon-document-note-remove: "\eb09";
$ts-icon-document-task: "\eb0a";
$ts-icon-document-clipboard-import: "\eb0b";
$ts-icon-document-clipboard-export: "\eb0c";
$ts-icon-document-clipboard-close: "\eb0d";
$ts-icon-document-clipboard-tick: "\eb0e";
$ts-icon-document-clipboard-text: "\eb0f";
$ts-icon-document-document-upload: "\eb10";
$ts-icon-document-task-square: "\eb11";
$ts-icon-document-document-previous: "\eb12";
$ts-icon-document-document-favorite: "\eb13";
$ts-icon-document-edit: "\eb14";
$ts-icon-document-document-forward: "\eb15";
$ts-icon-document-document: "\eb16";
$ts-icon-document-document-download: "\eb17";
$ts-icon-document-document-text: "\eb18";
$ts-icon-document-menu-board: "\eb19";
$ts-icon-document-note-favorite: "\eb1a";
$ts-icon-document-note-text: "\eb1b";
$ts-icon-document-archive-book: "\eb1c";
$ts-icon-document-document-filter: "\eb1d";
$ts-icon-document-bill: "\eb1e";
$ts-icon-document-creative-commons: "\eb1f";
$ts-icon-document-copyright: "\eb20";
$ts-icon-document-document-like: "\eb21";
$ts-icon-document-document-text2: "\eb22";
$ts-icon-document-note: "\eb23";
$ts-icon-document-document-cloud: "\eb24";
$ts-icon-document-document-normal: "\eb25";
$ts-icon-document-document-sketch2: "\eb26";
$ts-icon-files-Load_list_alt: "\eb27";
$ts-icon-files-Load_list: "\eb28";
$ts-icon-files-File_dock_search: "\eb29";
$ts-icon-files-Blank_alt: "\eb2a";
$ts-icon-files-Blank: "\eb2b";
$ts-icon-files-File_dock_add: "\eb2c";
$ts-icon-files-File_dock: "\eb2d";
$ts-icon-files-File: "\eb2e";
$ts-icon-files-folder-cloud: "\eb2f";
$ts-icon-files-folder-open: "\eb30";
$ts-icon-files-folder-2: "\eb31";
$ts-icon-files-folder-favorite: "\eb32";
$ts-icon-files-folder-minus: "\eb33";
$ts-icon-files-folder-add: "\eb34";
$ts-icon-files-folder-cross: "\eb35";
$ts-icon-files-folder: "\eb36";
$ts-icon-grid-maximize-4: "\eb37";
$ts-icon-grid-maximize-3: "\eb38";
$ts-icon-grid-maximize-2: "\eb39";
$ts-icon-grid-maximize: "\eb3a";
$ts-icon-grid-align-bottom: "\eb3b";
$ts-icon-grid-convert-shape-2: "\eb3c";
$ts-icon-grid-align-top: "\eb3d";
$ts-icon-grid-align-right: "\eb3e";
$ts-icon-grid-maximize-21: "\eb3f";
$ts-icon-grid-align-left: "\eb40";
$ts-icon-grid-align-horizontally: "\eb41";
$ts-icon-grid-element-equal: "\eb42";
$ts-icon-grid-element-plus: "\eb43";
$ts-icon-grid-element-4: "\eb44";
$ts-icon-grid-element-3: "\eb45";
$ts-icon-grid-element-2: "\eb46";
$ts-icon-grid-element-1: "\eb47";
$ts-icon-grid-grid-1: "\eb48";
$ts-icon-grid-grid-8: "\eb49";
$ts-icon-grid-grid-5: "\eb4a";
$ts-icon-grid-format-circle: "\eb4b";
$ts-icon-grid-kanban: "\eb4c";
$ts-icon-grid-grid-9: "\eb4d";
$ts-icon-grid-slider-horizontal-2: "\eb4e";
$ts-icon-grid-align-vertically: "\eb4f";
$ts-icon-grid-slider-vertical-2: "\eb50";
$ts-icon-grid-grid-2: "\eb51";
$ts-icon-grid-convert-shape: "\eb52";
$ts-icon-grid-3square: "\eb53";
$ts-icon-grid-slider-vertical: "\eb54";
$ts-icon-grid-grid-6: "\eb55";
$ts-icon-grid-crop: "\eb56";
$ts-icon-grid-slider-horizontal: "\eb57";
$ts-icon-grid-grid-3: "\eb58";
$ts-icon-grid-rotate-left: "\eb59";
$ts-icon-grid-row-vertical: "\eb5a";
$ts-icon-grid-grid-7: "\eb5b";
$ts-icon-grid-format-square: "\eb5c";
$ts-icon-grid-fatrows: "\eb5d";
$ts-icon-grid-grid-4: "\eb5e";
$ts-icon-grid-rotate-right: "\eb5f";
$ts-icon-grid-row-horizontal: "\eb60";
$ts-icon-grid-grid-eraser: "\eb61";
$ts-icon-grid-grid-edit: "\eb62";
$ts-icon-grid-grid-lock3: "\eb63";
$ts-icon-search-search-normal_alt: "\eb64";
$ts-icon-search-search-status: "\eb65";
$ts-icon-search-search-favorite_alt: "\eb66";
$ts-icon-search-search-zoom-out_alt: "\eb67";
$ts-icon-search-search-status_alt: "\eb68";
$ts-icon-search-search-zoom-in: "\eb69";
$ts-icon-search-search-normal: "\eb6a";
$ts-icon-search-search-favorite: "\eb6b";
$ts-icon-search-search-zoom-in_alt: "\eb6c";
$ts-icon-search-search-zoom-out: "\eb6d";
$ts-icon-menu-Meatballs_menu: "\eb6e";
$ts-icon-menu-Setting_vert: "\eb6f";
$ts-icon-menu-Move: "\eb70";
$ts-icon-menu-Menu3: "\eb71";
$ts-icon-menu-Status_list: "\eb72";
$ts-icon-menu-Status: "\eb73";
$ts-icon-menu-Menu2: "\eb74";
$ts-icon-menu-Widget_alt: "\eb75";
$ts-icon-menu-Widget: "\eb76";
$ts-icon-menu-Stat: "\eb77";
$ts-icon-menu-darhboard_alt: "\eb78";
$ts-icon-menu-darhboard: "\eb79";
$ts-icon-menu-Sort: "\eb7a";
$ts-icon-settings-category-2: "\eb7b";
$ts-icon-settings-toggle-on: "\eb7c";
$ts-icon-settings-toggle-off: "\eb7d";
$ts-icon-settings-more-2: "\eb7e";
$ts-icon-settings-setting-5: "\eb7f";
$ts-icon-settings-setting-3: "\eb80";
$ts-icon-settings-setting-2: "\eb81";
$ts-icon-settings-more: "\eb82";
$ts-icon-settings-setting: "\eb83";
$ts-icon-settings-setting-4: "\eb84";
$ts-icon-settings-category: "\eb85";
$ts-icon-settings-settings: "\eb86";
$ts-icon-settings-candle: "\eb87";
$ts-icon-settings-candle-2: "\eb88";
$ts-icon-settings-menu: "\eb89";
$ts-icon-notifications-Alarm_noty: "\eb8a";
$ts-icon-notifications-Alarm: "\eb8b";
$ts-icon-notifications-lamp-charge: "\eb8c";
$ts-icon-notifications-lamp-on: "\eb8d";
$ts-icon-notifications-lamp-slash: "\eb8e";
$ts-icon-notifications-notification-circle: "\eb8f";
$ts-icon-notifications-notification-favorite: "\eb90";
$ts-icon-notifications-notification: "\eb91";
$ts-icon-notifications-notification-status: "\eb92";
$ts-icon-actions-minus-square: "\eb93";
$ts-icon-actions-Done_all_alt_round: "\eb94";
$ts-icon-actions-warning: "\eb95";
$ts-icon-actions-Done: "\eb96";
$ts-icon-actions-minus_cicrcle: "\eb97";
$ts-icon-actions-info-circle: "\eb98";
$ts-icon-actions-Add_square: "\eb99";
$ts-icon-actions-Done_ring_round: "\eb9a";
$ts-icon-actions-group: "\eb9b";
$ts-icon-actions-Close_ring: "\eb9c";
$ts-icon-actions-Add_ring: "\eb9d";
$ts-icon-actions-more-circle: "\eb9e";
$ts-icon-actions-Check_ring: "\eb9f";
$ts-icon-actions-minus: "\eba0";
$ts-icon-actions-Close: "\eba1";
$ts-icon-actions-Add: "\eba2";
$ts-icon-actions-hammer: "\eba3";
$ts-icon-actions-save: "\eba4";
$ts-icon-actions-filter-square: "\eba5";
$ts-icon-actions-filter: "\eba6";
$ts-icon-actions-filter-tick: "\eba7";
$ts-icon-actions-filter-edit: "\eba8";
$ts-icon-actions-filter-search: "\eba9";
$ts-icon-actions-filter-add: "\ebaa";
$ts-icon-actions-filter-remove: "\ebab";
$ts-icon-actions-Edit: "\ebac";
$ts-icon-actions-clip: "\ebad";
$ts-icon-actions-Info: "\ebae";
$ts-icon-actions-Question: "\ebaf";
$ts-icon-actions-Flag_alt: "\ebb0";
$ts-icon-actions-Flag: "\ebb1";
$ts-icon-actions-Bookmark: "\ebb2";
$ts-icon-actions-Star: "\ebb3";
$ts-icon-actions-Favorite: "\ebb4";
$ts-icon-actions-Flag_finish: "\ebb5";
$ts-icon-actions-Pined: "\ebb6";
$ts-icon-actions-Pin: "\ebb7";
$ts-icon-actions-Progress: "\ebb8";
$ts-icon-actions-archive-tick: "\ebb9";
$ts-icon-actions-archive-slash: "\ebba";
$ts-icon-actions-archive-minus: "\ebbb";
$ts-icon-actions-archive-add: "\ebbc";
$ts-icon-actions-frame: "\ebbd";
$ts-icon-actions-book-saved: "\ebbe";
$ts-icon-actions-save-remove: "\ebbf";
$ts-icon-actions-save-minus: "\ebc0";
$ts-icon-actions-save-add: "\ebc1";
$ts-icon-actions-save-2: "\ebc2";
$ts-icon-actions-book-square: "\ebc3";
$ts-icon-actions-receipt-square: "\ebc4";
$ts-icon-actions-Copy_alt: "\ebc5";
$ts-icon-actions-Copy: "\ebc6";
$ts-icon-actions-Trash: "\ebc7";
$ts-icon-arrow-arrow-minus: "\ebc8";
$ts-icon-arrow-arrow-plus: "\ebc9";
$ts-icon-arrow-redo-2: "\ebca";
$ts-icon-arrow-Icon92: "\ebcb";
$ts-icon-arrow-Expand_down_stop: "\ebcc";
$ts-icon-arrow-Expand_top_stop: "\ebcd";
$ts-icon-arrow-Expand_right_stop: "\ebce";
$ts-icon-arrow-Expand_left_stop: "\ebcf";
$ts-icon-arrow-Expand_down_double: "\ebd0";
$ts-icon-arrow-Expand_up_double: "\ebd1";
$ts-icon-arrow-Expand_right_double: "\ebd2";
$ts-icon-arrow-Expand_left_double: "\ebd3";
$ts-icon-arrow-Download_cloud: "\ebd4";
$ts-icon-arrow-Upload_cloud: "\ebd5";
$ts-icon-arrow-Export: "\ebd6";
$ts-icon-arrow-Import: "\ebd7";
$ts-icon-arrow-Download_circle: "\ebd8";
$ts-icon-arrow-Load_circle: "\ebd9";
$ts-icon-arrow-Move_2: "\ebda";
$ts-icon-arrow-Upload: "\ebdb";
$ts-icon-arrow-Download: "\ebdc";
$ts-icon-arrow-Fluid: "\ebdd";
$ts-icon-arrow-Collapse: "\ebde";
$ts-icon-arrow-Vertical_switch_long: "\ebdf";
$ts-icon-arrow-Vertical_switch_alt: "\ebe0";
$ts-icon-arrow-Vertical_switch: "\ebe1";
$ts-icon-arrow-Expand_down: "\ebe2";
$ts-icon-arrow-Expand_up: "\ebe3";
$ts-icon-arrow-Expand_right: "\ebe4";
$ts-icon-arrow-Expand_left: "\ebe5";
$ts-icon-arrow-Move_alt: "\ebe6";
$ts-icon-arrow-Move: "\ebe7";
$ts-icon-arrow-Full_Screen_Corner: "\ebe8";
$ts-icon-arrow-Full_alt: "\ebe9";
$ts-icon-arrow-Reduce: "\ebea";
$ts-icon-arrow-Full: "\ebeb";
$ts-icon-arrow-Arrow_left_stop: "\ebec";
$ts-icon-arrow-Arrow_right_stop: "\ebed";
$ts-icon-arrow-export-3: "\ebee";
$ts-icon-arrow-refresh-2: "\ebef";
$ts-icon-arrow-refresh-3: "\ebf0";
$ts-icon-arrow-arrow-right-2: "\ebf1";
$ts-icon-arrow-import-3: "\ebf2";
$ts-icon-arrow-logout: "\ebf3";
$ts-icon-arrow-export-4: "\ebf4";
$ts-icon-arrow-import-2: "\ebf5";
$ts-icon-arrow-login: "\ebf6";
$ts-icon-arrow-arrow-bottom-2: "\ebf7";
$ts-icon-arrow-logout-2: "\ebf8";
$ts-icon-arrow-vuesax: "\ebf9";
$ts-icon-arrow-login-2: "\ebfa";
$ts-icon-arrow-arrow-up-2: "\ebfb";
$ts-icon-arrow-convert: "\ebfc";
$ts-icon-arrow-rotate-right: "\ebfd";
$ts-icon-arrow-rotate-left: "\ebfe";
$ts-icon-arrow-arrow-down-3: "\ebff";
$ts-icon-arrow-arrow-right-3: "\ec00";
$ts-icon-arrow-arrow-left-3: "\ec01";
$ts-icon-arrow-redo: "\ec02";
$ts-icon-arrow-arrow-swap-horizontal: "\ec03";
$ts-icon-arrow-undo: "\ec04";
$ts-icon-arrow-arrow-up-3: "\ec05";
$ts-icon-arrow-arrange: "\ec06";
$ts-icon-arrow-arrow-down: "\ec07";
$ts-icon-arrow-arrow-circle-up: "\ec08";
$ts-icon-arrow-arrow-circle-down: "\ec09";
$ts-icon-arrow-arrow-circle-right: "\ec0a";
$ts-icon-arrow-arrow-circle-left: "\ec0b";
$ts-icon-arrow-arrow-up: "\ec0c";
$ts-icon-arrow-arrow-right: "\ec0d";
$ts-icon-arrow-arrow-square-right: "\ec0e";
$ts-icon-arrow-arrow-square-left: "\ec0f";
$ts-icon-arrow-arrow-square-up: "\ec10";
$ts-icon-arrow-arrow-left: "\ec11";
$ts-icon-arrow-arrow-square-down: "\ec12";
$ts-icon-arrow-refresh-circle: "\ec13";
$ts-icon-arrow-repeat-circle: "\ec14";
$ts-icon-arrow-repeat: "\ec15";
$ts-icon-arrow-arrange-circle-2: "\ec16";
$ts-icon-arrow-arrange-circle: "\ec17";
$ts-icon-arrow-receive-square-2: "\ec18";
$ts-icon-arrow-send-sqaure-2: "\ec19";
$ts-icon-arrow-send-square: "\ec1a";
$ts-icon-arrow-refresh-square-2: "\ec1b";
$ts-icon-arrow-refresh-left-square: "\ec1c";
$ts-icon-arrow-refresh-right-square: "\ec1d";
$ts-icon-arrow-forward-square: "\ec1e";
$ts-icon-arrow-receive-square: "\ec1f";
$ts-icon-arrow-back-square: "\ec20";
$ts-icon-arrow-arrange-square-2: "\ec21";
$ts-icon-arrow-arrange-square: "\ec22";
$ts-icon-arrow-arrow-3: "\ec23";
$ts-icon-arrow-arrow-2: "\ec24";
$ts-icon-arrow-send: "\ec25";
$ts-icon-arrow-received: "\ec26";

