@import "core/colors/styles";
@import "core/typography/styles";

.charts-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
}


.dashboard-total-container {
  background-color: $surface-secondary-color;
  border-radius: 6px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;

  &__period {
    @include ts-subtitle-small;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;

  }

  & .negative {
    color: $tag-red-color;

    & i {
      transform: rotate(180deg);
    }
  }

  & .positive {
    color: $tag-green-color;
  }

  & .neutral {
    color: $text-tertiary-color;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration: line-through;
  }
}


.dashboard-total-card {
  background-color: $surface-primary-color;
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 200px;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
