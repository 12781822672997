// Overrides user components-variables
@import 'bootstrap/scss/functions';

@import '../variables/variables';
@import 'bootstrap/scss/variables';
@import 'dark-palette';
@import 'light-palette';
$white-color: (
  'base': #ffffff,
);
$black-color: (
  'base': #000000,
);
$primary-color: (
  'lighten-4': #59eac1,
  'lighten-3': #59eac1,
  'lighten-2': #59eac1,
  'lighten-1': #59eac1,
  'base': #48b798,
  'darken-1': #3f9d83,
  'darken-2': #3f9d83,
  'darken-3': #3f9d83,
  'darken-4': #3f9d83,
);

$secondary-color: (
  'lighten-4': #eaf0f9,
  'lighten-3': #d6e1f3,
  'lighten-2': #b5c3db,
  'lighten-1': #8f9db8,
  'base': #616d89,
  'darken-1': #465375,
  'darken-2': #303c62,
  'darken-3': #1e294f,
  'darken-4': #121a41,
);
$success-color: (
  'lighten-4': #e0fbda,
  'lighten-3': #bdf8b6,
  'lighten-2': #8eec8d,
  'lighten-1': #6cd975,
  'base': #40c057,
  'darken-1': #2ea54f,
  'darken-2': #208a47,
  'darken-3': #146f3e,
  'darken-4': #0c5c38,
);
$danger-color: (
  'lighten-4': #fee8dc,
  'lighten-3': #fecbba,
  'lighten-2': #fca897,
  'lighten-1': #f9877c,
  'base': #f55252,
  'darken-1': #d23b48,
  'darken-2': #b02940,
  'darken-3': #8e1a38,
  'darken-4': #750f33,
);
$warning-color: (
  'lighten-4': #feefd0,
  'lighten-3': #fedba1,
  'lighten-2': #fcc173,
  'lighten-1': #faa750,
  'base': #f77e17,
  'darken-1': #d46010,
  'darken-2': #b1460b,
  'darken-3': #8f2f07,
  'darken-4': #762004,
);
$info-color: (
  'lighten-4': #59eac1,
  'lighten-3': #59eac1,
  'lighten-2': #59eac1,
  'lighten-1': #59eac1,
  'base': #48b798,
  'darken-1': #3f9d83,
  'darken-2': #3f9d83,
  'darken-3': #3f9d83,
  'darken-4': #3f9d83,
);
$light: (
  'base': #dbdee5,
);
$dark: (
  'base': #1a051d,
);

$grey-color: (
  'lighten-4': #f5f5f5,
  'lighten-3': #eee,
  'lighten-2': #e0e0e0,
  'lighten-1': #bdbdbd,
  'base': #9e9e9e,
  'darken-1': #757575,
  'darken-2': #616161,
  'darken-3': #424242,
  'darken-4': #212121,
  'darken-6': #232323,
);

$shades: (
  'black': #000000,
  'white': #ffffff,
  'transparent': transparent,
);

$colors: (
  'white': $white-color,
  'black': $black-color,
  'primary': $primary-color,
  'secondary': $secondary-color,
  'success': $success-color,
  'danger': $danger-color,
  'warning': $warning-color,
  'info': $info-color,
  'light': $light,
  'dark': $dark,
  'grey': $grey-color,
);

$green: #48b798;
$green-light: #59eac1;
$green-dark: #3f9d83;
$green-emerald: #45b193;
$green-1: #27ae60;

$grey: #f4f6fa;
$grey-dark: #6e84a3;
$grey-dark-l: #334155;
$grey-light: #e3ebf6;
$grey-light-s: #efefef;
$grey-davys: #555555;
$grey-light-m: #a5aec0;

$border-grey: #dce1ec;

$black-light: #373737;

$ghost-white-light: #f7f9fd;
$ghost-white-dark: #cbcbcb;

$red: #e66b6b;
$red-light: #ff8585;
$red-dark: #cf5050;
$red-imperial: #de4646;


$orange: #ed9e56;
$orange-light: #ffb571;
$orange-dark: #e48833;
