@import 'core/colors/styles';
@import 'core/mediaQueries/styles';

ts-hub,
ts-receive,
ts-revert,
ts-recall,
ts-demand {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

ts-hub {
  @include breakpoint-md {
    height: calc(100dvh - var(--ts-app-nav-height, 2rem));
  }
}

.section-title {
  font-weight: 600;
}

.settings {
  height: 42px;
  width: 42px;
  border-radius: 999px;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #dce1ec;
  background-color: #fff;
  color: #334155;

  & use {
    stroke: #6e84a3;
  }
}

.custom-status {
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 12px;
  border: 2px solid var(--custom-btn-color);
  color: var(--custom-btn-color);
  border-radius: 5px;
  font-weight: 500;
}
