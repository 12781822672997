mat-form-field {
  &.mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 30px;
    padding: 8px 20px;
    background-color: #f4f6fa;
  }
  &.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
    width: 100%;
  }

  //outline
  &.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 17px 0 0 17px;
    border-right-style: none;
    // width: 20px !important;
  }
  &.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 17px 17px 0;
    border-left-style: none;
    flex-grow: 1;
  }
  // &.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  //  width: 100px !important;
  // }
  &.mat-form-field-appearance-outline .mat-form-field-outline {
    color: #e3ebf6;
  }

  //HIDE UNDERLINE!
  //Dont work in component styles, so it's global because ::ng-deep depricated.
  //If u find way to deal with this in component scope write to me (Rinat)
  // and add styles to wb-ads-statistic component (datepicker)
  // &.mat-form-field-appearance-fill .mat-form-field-underline::before {
  //   background-color: transparent;
  // }
  // .mat-form-field-underline {
  //   display: none;
  // }

  // &.mat-form-field {
  //   font-size: inherit;
  //   font-weight: 400;
  //   line-height: 1.125;
  //   font-family: "Inter", sans-serif;
  //   letter-spacing: normal;
  // }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0 0;
}
