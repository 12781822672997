$surface-primary-color:var(--ts-surface-primary-color);
$surface-secondary-color:var(--ts-surface-secondary-color);
$surface-accent-primary-color:var(--ts-surface-accent-primary-color);
$surface-accent-secondary-color:var(--ts-surface-accent-secondary-color);

$surface-error-color:var(--ts-surface-error-color);
$surface-success-color:var(--ts-surface-success-color);
$surface-warning-color:var(--ts-surface-warning-color);
$surface-info-color:var(--ts-surface-info-color);

$surface-tertiary-color:var(--ts-surface-tertiary-color);

$surface-inverse-color:var(--ts-surface-inverse-color);
$surface-disable-color:var(--ts-surface-disable-color);
$surface-light-color:var(--ts-surface-light-color);
