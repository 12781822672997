@import '/src/assets/sass/core/colors/_palette-variables.scss';

.upload-titles,
.product-upload__titles {
  margin-top: 10px;
  display: flex;
  gap: 30px;

  .upload-images {
    display: flex;
    flex-direction: column;

    label {
      input {
        display: none;
      }
    }

    .add-image {
      cursor: pointer;
      margin-top: 12px;
      display: flex;
      align-items: center;
      gap: 4px;
      color: $green-emerald;
    }

    .images {
      display: grid;
      gap: 10px;
      &.state-1 {
        label {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 4;
          }
        }
      }

      &.state-2 {
        grid-template-columns: 210px 100px;
        grid-auto-rows: 100px;

        label {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
          }

          &:nth-child(2) {
            width: 100px;
            height: 100px;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
          }
        }
      }

      &.state-3 {
        grid-template-columns: 210px 100px;
        grid-auto-rows: 100px;

        label {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
          }

          &:nth-child(2) {
            width: 100px;
            height: 100px;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
          }

          &:nth-child(3) {
            width: 100px;
            height: 100px;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;
          }
        }
      }

      &.state-4,
      &.state-5 {
        grid-template-columns: 210px 100px 100px;
        grid-auto-rows: 100px;

        label {
          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
          }

          &:nth-child(2) {
            width: 100px;
            height: 100px;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
          }

          &:nth-child(3) {
            width: 100px;
            height: 100px;
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;
          }

          &:nth-child(4) {
            width: 100px;
            height: 100px;
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
          }

          &:nth-child(5) {
            width: 100px;
            height: 100px;
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 3;
          }
        }
      }

      label {
        cursor: pointer;
        flex: none;
        width: 210px;
        height: 210px;
        border-radius: 15px;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $border-grey;

        img {
          width: 100%;
          height: 100%;
        }

        .image {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;

          > span {
            width: 108px;
            text-align: center;
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;

            color: $green-emerald;
          }
        }
      }
    }
  }

  .content-inputs {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
